@import url('https://fonts.googleapis.com/css?family=Oldenburg');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    /* margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    outline: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box; */
    box-sizing: border-box;
}

html {
    height: 101%;
}

/* body {
    background: #dde9f0 url('https://i.imgur.com/p6YhOiv.png');
    font-family: Arial, Tahoma, sans-serif;
    font-size: 62.5%;
    line-height: 1;
    padding-bottom: 65px;
} */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

strong {
    font-weight: bold;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* img {
    border: 0;
    max-width: 100%;
} */

/* h1 {
    display: block;
    font-size: 2.75em;
    line-height: 2.35em;
    color: #7291a5;
    margin-bottom: 12px;
    font-weight: normal;
    font-family: 'Oldenburg', 'Trebuchet MS', Arial, sans-serif;
    text-shadow: 0px 1px 1px #fff;
    text-align: center;
} */

p {
    font-size: 1.2em;
    font-weight: normal;
    line-height: 1.35em;
    color: #343434;
    margin-bottom: 12px;
}

/* page container */
#wrap {
    display: block;
    width: 850px;
    margin: 0 auto;
    padding-top: 35px;
}


/* user menu settings */
#dropdown {
    display: block;
    padding: 13px 16px;
    width: 266px;
    margin: 0 auto;
    position: relative;
    cursor: pointer;
    border: 1px solid #898989;
    background: #fff;
    font-size: 1.55em;
    color: #656565;
    font-weight: normal;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    -ms-transition: all 0.15s linear;
    -o-transition: all 0.15s linear;
    transition: all 0.15s linear;
}

#dropdown:hover {
    color: #898989;
}

#dropdown.open {
    background: #0E2537;
    color: #fff;
    border-left-color: #6c6d70;
}

#dropdown ul {
    position: absolute;
    top: 100%;
    left: -1px;
    /* move content -4px because of container left border */
    width: 266px;
    padding: 5px 0px;
    display: block;
    border: 1px solid #898989;
    background: #fff;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
    max-height: 300px;
    overflow-y: auto;
}

#dropdown ul li {
    font-size: 0.9em;
}

#dropdown ul li a {
    text-decoration: none;
    display: block;
    color: #0E2537;
    padding: 7px 15px;
}

#dropdown ul li a:hover {
    color: #0E2537;
    background: #e7f0f7;
}