.modal-delete {
    display: block;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 50%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content */
.modal-content-delete {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 35%;
}

/* The Close Button */
.close-delete {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close-delete:hover,
.close-delete:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.delButtonGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 53px;
}

.delButton {
    background: #0e2537 !important;
    color: #fff !important;
}

@media(max-width: 991px) {
    .modal-content-delete {
        width: 50%
    }
}

@media(max-width:767px) {
    .modal-content-delete {
        width: 80%
    }
}