.documentManagerslider_sec {
    width: 100%;
    height: auto;
    padding: 20px 0 40px;
}

.documentManagerslider_sec .headbar {
    width: 100%;
    height: auto;
    padding: 5px 10px;
    background-color: #97cdf7;
    margin-bottom: 20px;
}

.documentManagerslider_sec .headbar h4 {
    font-size: 24px;
    line-height: normal;
    text-align: left;
    font-weight: 500;
    color: #0d2537;
    padding: 0;
    margin: 0;
}

.documentManagerslider_sec .subheadbar {
    width: 100%;
    height: auto;
    background-color: #a6b3bd;
    padding: 2px;
    margin-bottom: 20px;
}

.documentManagerslider_sec .subheadbar h6 {
    font-size: 16px;
    line-height: normal;
    font-weight: 400;
    text-align: center;
    color: #3f3e3e;
    padding: 0;
    margin: 0;
}

.documentManagerslider_sec form {
    width: 100%;
    max-width: 600px;
    height: auto;
    margin: 0 auto;
}

.documentManagerslider_sec .form-group {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.documentManagerslider_sec .form-group label {
    margin-right: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #0d2537;
}

.documentManagerslider_sec .form-group .form-control {
    width: 100%;
    border: 1px solid #0d253769 !important;
}

.documentManagerslider_sec .allbtn_wrap {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.importdocument_btn {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    cursor: pointer;
    background-color: #0d2537;
    color: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    padding: 8px 15px;
    border-radius: 6px;
}

.downloadtemplate_btn {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    cursor: pointer;
    background-color: #4e5052;
    color: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    padding: 8px 15px;
    border-radius: 6px;
}




/***DownloadTemplate_Modal****/
.DownloadTemplate_Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff93;
    z-index: 999;
}

/* .DownloadTemplateModal_body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 500px;
    height: auto;
    border: 1px solid #0d2537;
    z-index: 2;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
} */

.DownloadTemplateModal_body .closebtn_btn {
    position: absolute;
    right: 10px;
    top: 0px;
    z-index: 4;
    box-shadow: none;
    outline: 0;
    text-decoration: none;
    background-color: transparent;
    border: none;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    font-weight: 500;
    cursor: pointer;
}

.DownloadTemplateModal_body .list li {
    cursor: pointer;
}

.DownloadTemplateModal_body .list li:hover {
    font-weight: 600;
}


.DocumentManager_mainwrap .slick-prev {
    left: 50px;
    z-index: 5;
}

.DocumentManager_mainwrap .slick-next {
    right: 50px;
    z-index: 5;
}

.DocumentManager_mainwrap .slick-prev:before,
.DocumentManager_mainwrap .slick-next:before {
    font-size: 28px;
    color: #0d2537;
}


.allDocument_Vault {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    position: relative;
}

.documentVault_box {
    width: 100%;
    max-width: 23%;
    height: auto;
}

.documentVault_box .toparea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 10px;
}

.documentVault_box .box {
    padding: 8px 20;
    border: 1px solid #777676;
    border-radius: 5px 5px 0 0;
    width: 100%;
}

.documentVault_box .box p {
    font-size: 12px;
    line-height: normal;
    text-align: center;
    font-weight: 400;
    color: #777676;
    padding: 0;
    margin: 0;
}

.documentVault_box .box .form-control {
    padding: 4px;
    border: none;
    background-color: transparent;
    font-size: 12px;
    line-height: normal;
    text-align: center;
    font-weight: 400;
    color: #777676;
    height: 22px;
    margin: 0;
}

.documentVault_box .box .form-control::placeholder {
    font-size: 12px;
    line-height: normal;
    text-align: center;
    font-weight: 400;
    color: #777676;
}

.documentVault_box .bottomarea {
    padding: 5px;
    border: 1px solid #777676;
    width: 100%;
    border-radius: 0 0 4px 4px;
    border-top: none;
}

.documentVault_box .bottomarea p {
    font-size: 14px;
    line-height: normal;
    text-align: center;
    font-weight: 500;
    color: #000;
    padding: 0;
    margin-bottom: 8px;
}

.documentVault_box .bottomarea span {
    font-size: 12px;
    line-height: normal;
    text-align: center;
    font-weight: 500;
    color: #036aaf;
    padding: 0;
    margin: 0;
}

.documentVault_box .pointarea {
    text-align: center;
}

.invoicedocModalTotal a {
    cursor: pointer;
    color: #036aaf;
    text-decoration: underline;
}

.DocVaultCalender {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffffdb;
    z-index: 999;
}

.DocVaultCalender_body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 332px;
    height: auto;
    background-color: #fff;
    border-radius: 4px;
    z-index: 2;
    box-shadow: 0px 0px 5px 0px #a3a2a2;
}

.DocVaultCalender_body .closebtn {
    position: absolute;
    top: -7px;
    right: -20px;
    background-color: transparent;
    border: none;
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    font-size: 20px;
    font-weight: 600;
    color: #0361a5;
    padding: 0;
}



/****Responsive******/

@media (max-width: 991px) {
    .documentVault_box {
        max-width: 31%;
    }
}

@media (max-width: 768px) {
    .documentVault_box {
        max-width: 48%;
    }

    .DocumentManager_mainwrap .slick-prev {
        left: 10px;
    }

    .DocumentManager_mainwrap .slick-next {
        right: 10px;
    }
}

@media (max-width: 576px) {
    .documentVault_box {
        max-width: 100%;
    }

    .documentManagerslider_sec .headbar h4 {
        font-size: 18px;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .DocVaultCalender_body .closebtn {
        top: -30px;
        right: 5px;
    }
}