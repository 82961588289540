#home-sidebar {
    display: none;
}

.tglebtn {
    display: none;
}

#home-sidebar {
    /* display: block; */
    display: none;
    width: 100%;
    height: 100vh;
    background: linear-gradient(90deg, rgba(25, 71, 95, 1) 0%, rgba(15, 39, 57, 1) 35%);
    position: fixed;
    z-index: 10000;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 20px;
}

.tglebtn span {
    width: 35px;
    height: 5px;
    background: #000;
    display: block;
    margin-bottom: 5px;
}

.lastspan {
    margin-bottom: 0 !important;
}

#home-sidebar .cancel {
    font-size: 24px;
    border: none;
    background: transparent;
    color: #fff;
    padding: 30px 0;
    position: absolute;
    top: 40px;
    right: 21px;
}

#home-sidebar .home-top_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#home-sidebar .home-top_wrap .left p {
    color: #fff;
    font-size: 18px;
    margin: 0;
    text-transform: uppercase;
}

#home-sidebar .home-top_wrap .rgt {
    display: flex;
    align-items: center;
    gap: 20px;
}

#home-sidebar .home-top_wrap .rgt .ico {
    color: #fff;
    font-size: 18px;
}

#home-sidebar .home-top_wrap .rgt .perc {
    color: #fff;
    font-size: 18px;
}

#home-sidebar .home-side_list ul {
    list-style: none;
    text-align: center;
}

#home-sidebar .home-side_list ul li a {
    color: #fff;
    font-size: 25px;
    text-decoration: none;
}

#home-sidebar .home-side_list ul li select {
    color: #fff;
    font-size: 25px;
    text-decoration: none;
    width: auto;
}

#home-sidebar .home-side_list ul li select option {
    color: #0E2537;
}

#home-sidebar .home-side_list ul li a:hover {
    text-decoration: underline;
}

#home-sidebar .home-side_list ul li {
    padding: 25px 0;
}

#home-sidebar .home-side_list {
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    justify-content: space-between;
}

#home-sidebar .home-side_list .side-list_btn {
    border: 4px solid #fff;
    font-size: 26px;
    color: #fff;
    background: transparent;
    padding: 10px 20px;
    border-radius: 25% / 48%;
}

#home-sidebar .sidebar-logo img {
    width: 200px;
}

@media only screen and (max-width: 992px) {
    .tglebtn {
        display: block;
    }

    #home-sidebar {
        /* display: block; */
        display: none;
        width: 100%;
        height: 100vh;
        background: linear-gradient(90deg, rgba(25, 71, 95, 1) 0%, rgba(15, 39, 57, 1) 35%);
        position: fixed;
        z-index: 10000;
        right: 0;
        top: 0;
        left: 0;
        bottom: 0;
        padding: 20px;
    }

    .tglebtn span {
        width: 35px;
        height: 5px;
        background: #000;
        display: block;
        margin-bottom: 5px;
    }

    .lastspan {
        margin-bottom: 0 !important;
    }

    #home-sidebar .cancel {
        font-size: 24px;
        border: none;
        background: transparent;
        color: #fff;
        padding: 0px 0;
        position: absolute;
        top: 14px;
        right: 21px;
    }

    #home-sidebar .home-top_wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    #home-sidebar .home-top_wrap .left p {
        color: #fff;
        font-size: 18px;
        margin: 0;
        text-transform: uppercase;
    }

    #home-sidebar .home-top_wrap .rgt {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    #home-sidebar .home-top_wrap .rgt .ico {
        color: #fff;
        font-size: 18px;
    }

    #home-sidebar .home-top_wrap .rgt .perc {
        color: #fff;
        font-size: 18px;
    }

    #home-sidebar .home-side_list ul {
        list-style: none;
        text-align: center;
    }

    #home-sidebar .home-side_list ul li a {
        color: #fff;
        font-size: 25px;
        text-decoration: none;
    }

    #home-sidebar .home-side_list ul li a:hover {
        text-decoration: underline;
    }

    #home-sidebar .home-side_list ul li {
        padding: 6px 0;
    }

    #home-sidebar .home-side_list {
        height: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
        justify-content: space-between;
    }

    #home-sidebar .home-side_list .side-list_btn {
        border: 4px solid #fff;
        font-size: 26px;
        color: #fff;
        background: transparent;
        padding: 10px 20px;
        border-radius: 25% / 48%;
    }

    #home-sidebar .sidebar-logo img {
        width: 200px;
    }
}