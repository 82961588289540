.buildReprtTop {
    padding-bottom: 20px;
}

.buildReprtTop ul {
    margin: 0;
    padding: 0;
}

.buildReprtTop ul li {
    margin-bottom: 3px;
    font-size: 15px;
    color: #000;
}

.buildtopSpn {
    font-weight: 600;
}

.strtBuildInp input {
    padding: 5px 8px !important;
    text-align: center;
    font-size: 18px;
    border-radius: 5px !important;
    margin: 0 !important;
    background: linear-gradient(to right, #194860, #0f2739);
    color: #fff;
    border: 0;
    font-weight: 600;
}

.strtBuildInp input:focus {
    color: #fff;
}

.endBuildInp input {
    padding: 5px 8px !important;
    text-align: center;
    font-size: 18px;
    border-radius: 5px !important;
    margin: 0 !important;
    background: linear-gradient(to right, #194860, #0f2739);
    color: #fff;
    border: 0;
    font-weight: 600;
}

.endBuildInp input:focus {
    color: #fff;
}

.typesBuildSelect {
    padding: 6px 8px !important;
    text-align: center;
    font-size: 18px;
    border-radius: 5px !important;
    border: 0 !important;
    background: linear-gradient(to right, #194860, #0f2739);
    color: #fff;
    font-weight: 600;
}

.typesBuildSelect option {
    color: #000000;
}

.dwldBuildBtn {
    width: 100%;
    background: linear-gradient(to right, #194860, #0f2739);
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    border: 0;
    border-radius: 5px;
    padding: 6px 8px;
}

.buildRepoSection {
    margin-bottom: 50px;
}

.buildIncmStTable {
    width: 100%;
}

.buildIncmStTh {
    width: 20%;
    text-align: center;
    position: relative;
}

.buildIncmStTh12 {
    text-align: center;
    position: relative;
}

.buildIncmStThFirst {
    width: 30%;
}

.buildIncmStTh12First {
    width: 28%;
}

.buildIncmStTh12First span {
    font-weight: 700;
    font-style: italic;
}

.buildIncmStTh span {
    font-size: 18px;
}

.buildIncmStTh12 span {
    font-size: 14px;
    white-space: nowrap;
}

.buildIncmSttdPer {
    width: 15%;
}

.buildIncmStTableDiv {
    margin-top: 20px !important;
    overflow-x: auto;
}

.buildIncmStTableDiv::-webkit-scrollbar {
    display: none;
}

.buildIncmStTd {
    margin-right: 15px;
}

.buildIncmStTd:last-child {
    margin-right: 0px;
}

.buildIncmStTd input {
    padding: 5px 5px !important;
    font-size: 14px;
}

.buildIncmStTd input::-webkit-outer-spin-button,
.buildIncmStTd input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.buildIncmStTd span {
    font-size: 16px;
    font-weight: 500;
}

.buildAddBtn {
    background-color: #0f2739;
    color: #fff;
    border: 0;
    padding: 3px 8px;
    font-size: 13px;
    border-radius: 4px;
    position: absolute;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
}

.delBuildRowBtn {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    background-color: transparent;
    border: 0;
    font-size: 12px;
    color: rgb(42, 96, 153);
    text-decoration: underline;
    font-weight: 500;
    margin-right: 5px;
}

.buildRemoveBtn {
    font-size: 8px;
    background-color: rgb(255, 0, 0);
    color: #fff;
    border: 0;
    border-radius: 50%;
    padding: 0 3px;
    position: relative;
    top: -2px;
    margin-right: 5px;
}

.buildCapMidlink {
    color: #2a6099 !important;
    /* text-decoration: underline !important; */
    width: 100%;
    display: block;
    white-space: pre-wrap;
    font-size: 15px;
    font-weight: 500;
    /* max-width: 85%; */
}

.buildCapTblBtn {
    background: #444444;
    color: #fff;
    border: none;
    outline: 0;
    width: 95px;
    height: 26px;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 6px;
}

.buildRemoveBtn i {
    margin-top: 3px;
}

.tblRmvBtn {
    border: none;
    background: transparent;
    border: none;
    outline: 0;
    color: #2a6099;
    text-decoration: underline;
    font-size: 14px;
    margin-left: 10px;
}

.userBuildInp {
    width: 95% !important;
    font-size: 12px !important;
    font-weight: 700;
}

.buildTotDivider {
    width: 100%;
    height: 3px;
    /* background-color: rgb(51 51 51 / 64%); */
    border: 2px dashed rgb(51 51 51 / 64%);
}

.CurrentPeriod_drowp {
    width: 100%;
    max-width: 550px;
    height: auto;
    margin-bottom: 20px;
}

.CurrentPeriod_drowp .linewrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
}

.CurrentPeriod_drowp .linewrap span {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
}

.CurrentPeriod_drowp .linewrap input {
    width: auto;
    height: auto;
    padding: 2px 10px;
    text-align: center;
    color: rgb(247, 3, 3);
}

.CurrentPeriod_drowp .linewrap select {
    width: auto;
}

.buildTotSpan {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 16px !important;
    font-weight: bolder !important;
    font-style: italic;
}

.buildProSpan {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 16px !important;
    font-weight: bolder !important;
}

/* Balance Sheet Part */
.buildBalLeftThInp {
    position: relative;
    text-align: center;
}

.buildBalLeftTdInp {
    width: 40%;
    position: relative;
    padding-top: 10px;
}

.buildBalLeftTdInp input {
    width: 85%;
    text-align: center;
    padding: 6px 10px !important;
    font-size: 16px;
    margin: 0 !important;
}

.buildBalRightTdInp {
    width: 60%;
    padding-top: 10px;
    position: relative;
    padding-left: 10px;
}

.buildBalRightTdInp input {
    padding: 6px 10px !important;
    font-size: 17px;
    margin: 0 !important;
    text-align: center;
}

.buildBalRightTdInp input::-webkit-outer-spin-button,
.buildBalRightTdInp input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.buildBalanceHead {
    font-size: 19px;
}

.buildBalLeftTdSpan {
    font-size: 17px;
    font-weight: 500;
}

.buildBalTable {
    width: 100%;
}

.buildBalLeftTdFoot {
    text-align: center;
    padding-top: 20px;
}

.buildBalLeftTdFoot span {
    font-style: italic;
    font-weight: bolder;
    font-size: 20px;
}

.buildBalRightTdFoot {
    padding-top: 20px;
    padding-left: 10px;
}

/* Trial Balance Part */
.buildTrialTable {
    width: 100%;
}

.bulidTriLeftTh {
    width: 30%;
    position: relative;
    text-align: center;
}

.bulidTriRightTh {
    width: 35%;
    text-align: center;
}

.buildTrialRightTd {
    text-align: center;
    padding-top: 6px;
}

.buildTrialLeftTd {
    padding-top: 6px;
}

.buildTrialLeftFootTd {
    text-align: center;
}

.buildTrialLeftFootTd span {
    font-size: 20px;
    font-weight: bolder;
    font-style: italic;
}

.buildTrialLeftTd input {
    width: 85% !important;
    padding: 6px 10px !important;
    text-align: center;
    font-size: 16px;
}

.buildTrialRightTd input {
    width: 85%;
    text-align: center;
    font-size: 16px;
    padding: 6px 10px;
}

.buildTrialRightTd input::-webkit-outer-spin-button,
.buildTrialRightTd input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.buildTriLeftTdSpan {
    font-size: 20px;
    font-weight: 500;
}

.buildTriRemoveBtn {
    font-size: 8px;
    background-color: rgb(255, 0, 0);
    color: #fff;
    border: 0;
    border-radius: 50%;
    padding: 0 3px;
    position: relative;
    top: -4px;
    margin-right: 5px;
}

/* Capitilization Table Part */

.outerTblDiv {
    width: 100%;
    /* height: auto; */
    min-height: 350px;
    /* overflow-x: auto; */
}

.outerTblDiv::-webkit-scrollbar {
    height: 8px;
}

.outerTblDiv::-webkit-scrollbar-track {
    background-color: #ccc;
}

.outerTblDiv::-webkit-scrollbar-thumb {
    background-color: #444444;
    border-radius: 6px;
}

.buildCapitalTable {
    width: 100%;
    position: relative;
}

.buildCapLeftTh {
    width: 30%;
    text-align: center;
    position: relative;
}

#buildCapFirsthead {
    /* display: flex;
    align-items: center;
    gap: 10px; */
    position: relative;
}

.buildCapMiddleTh {
    /* width: 15%; */
    width: auto;
    white-space: nowrap;
    text-align: center;
    padding: 5px;
    /* border: 1px solid grey;*/
}

.buildCapRightTh {
    width: 10%;
    text-align: center;
}

.buildCapLeftThSpan {
    font-size: 17px;
}

.buildCapLeftTd {
    display: flex;
    align-items: center;
}

.buildCapLeftTd>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}

.buildCapLeftTd input {
    width: 49% !important;
    padding: 6px 10px !important;
    margin: 0 !important;
    font-size: 16px;
    text-align: center;
}

.buildCapMiddleTd {
    font-size: 16px;
    text-align: center;
    /* padding-top: 10px; */
    /* width: 15%; */
    width: auto;
    padding: 8px 0px 5px;
    /* border: 1px solid grey; */
}

.buildCapMiddleTd input {

    width: 74% !important;
    padding: 6px 10px !important;
    margin: 0 !important;
    font-size: 16px;
    text-align: center;
    border: 1px solid #000000;
    border-radius: 4px;
}

.buildCapMiddleTd input::-webkit-outer-spin-button,
.buildCapMiddleTd input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.buildCapRightTd input {
    width: 90% !important;
    padding: 6px 10px !important;
    margin: 0 !important;
    font-size: 16px;
    text-align: center;
}

.buildCapRightTd input::-webkit-outer-spin-button,
.buildCapRightTd input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.buildCapRightTd {
    text-align: center;
    padding-top: 10px;
    width: 10%;
}

.buildCapiRemoveBtn {
    font-size: 8px;
    background-color: rgb(255, 0, 0);
    color: #fff;
    border: 0;
    border-radius: 50%;
    padding: 0 3px;
    position: relative;
    top: 5px;
    margin-right: 5px;
}

.buildCapLeftFootTd {
    padding-top: 12px;
    padding-right: 5px;
    text-align: right;
}

.buildCapLeftFootTd span {
    font-size: 18px;
    font-weight: bolder;
}

.buildCapMiddleFootTd {
    text-align: center;
    padding-top: 10px;
}

.buildCapMiddleFootTd input {
    width: 90% !important;
    padding: 6px 10px !important;
    margin: 0 !important;
    font-size: 16px;
    text-align: center;
    border: 1px solid #000000;
    border-radius: 4px;
    font-weight: bolder;
}

.cashFlwTableSec {
    padding: 20px 0px;
}

.cashFlwTblInn {
    display: flex;
    gap: 8px;
    margin-bottom: 15px;
}

.cashFlwAddBtn {
    height: 25px;
    background-color: #0f2739;
    color: #fff;
    border: 0;
    padding: 3px 8px;
    font-size: 13px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cashFlwAddBtn i {
    color: #fff;
}

.cashFlwRest {
    width: 100%;
}

.cashFlwTblInn:first-child .buildCshFlwhead {
    background-color: #77933c;
}

.buildCshFlwhead {
    width: 100%;
    display: block;
    font-size: 15px;
    font-weight: 600;
    padding: 5px 10px;
    background-color: #e46c0a;
    color: #fff;
    margin-bottom: 0;
}

.cashFlwTblInn table {
    width: 100%;
    margin-top: 10px;
}

.cashFlwTblInn table th {
    color: #000;
    text-align: end;
    font-size: 15px;
    font-weight: 600;
    padding: 3px;
}

.cashFlwTblInn table tbody tr {
    position: relative;
}

.cashFlwTblInn table tr td {
    width: 20%;
    color: #727272;
    padding: 2px 0px;
    font-size: 15px;
    font-weight: 500;
    text-align: end;
}

.cashFlwTblInn table tr td:first-child {
    width: 35%;
    color: #000;
    text-align: left;
    border: none;
}

.cshFlwRemoveBtn {
    position: absolute;
    left: -30px;
    font-size: 8px;
    background-color: rgb(255, 0, 0);
    color: #fff;
    border: 0;
    border-radius: 50%;
    padding: 0 3px;
    top: 8px;
}

.cfBigtxt {
    text-align: end !important;
    color: #000;
    font-size: 15px;
    font-weight: 700 !important;
    padding-right: 15px;
}

.cfSmallTxt {
    display: block;
    color: #000;
    font-weight: 500;
    font-size: 14px;
    margin: 0;
}

.cashFlwTblInn table input {
    display: inline-block;
    width: 170px;
    margin: 0;
    padding: 3px;
    /* width: auto; */
    height: auto;
    border: 1px solid #727272;
    border-radius: 0;
    text-align: center;
    color: #727272;
    padding: 2px 0px;
    font-size: 15px;
    font-weight: 500;
}

/* new */

/* .cashFlwTableSec {
    width: 100%;
    overflow-x: auto;
}

table {
    width: 100%;
}

.cfHdng {
    width: 100%;
    position: relative;
    gap: 8px;
    margin: 15px 0px;
}

.cfHdng td {
    background-color: orange;
    width: 100%;
    position: absolute;
    left: 35px;
}

.cashFlwAddBtn {
    height: 25px;
    background-color: #0f2739;
    color: #fff;
    border: 0;
    padding: 3px 8px;
    font-size: 13px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    position: absolute;
    left: 0;
}


th,
td {
    white-space: nowrap;
} */

/* responsive */

@media (max-width: 1399px) {
    .dwldBuildBtn {
        font-size: 15px;
    }

    .typesBuildSelect {
        font-size: 15px;
    }

    .endBuildInp input {
        font-size: 15px;
    }

    .strtBuildInp input {
        font-size: 15px;
    }
}

@media (max-width: 1199px) {

    /* Trial Balance Part */
    .bulidTriLeftTh {
        width: 40%;
    }

    .bulidTriRightTh {
        width: 30%;
    }

    .buildTrialRightTd input {
        width: 90%;
    }

    /* Capitalization Part */
    .buildCapLeftThSpan {
        font-size: 15px;
    }

    .buildCapLeftTh {
        text-align: left;
        padding-left: 66px;
    }

    .buildCapMiddleTd {
        font-size: 14px;
        padding: 4px 0px;
    }

    .buildCapMiddleTd input {
        font-size: 14px;
        padding: 5px;
    }

    .buildCapTblBtn {
        width: 75px;
        height: 24px;
        font-size: 13px;
    }

    .buildAddBtn {
        padding: 0px 6px;
        font-size: 12px;
    }

    /* cash flow */

    .cashFlwTblInn table tr td {
        width: 16%;
    }

    .cashFlwTblInn table input {
        width: 130px;
    }

    .cfBigtxt {
        font-size: 14px !important;
    }

}

@media (max-width: 991px) {
    .buildIncmStTable {
        min-width: 992px;
    }

    /* Trial Balance Part */
    .bulidTriLeftTh {
        text-align: left;
        padding-left: 44px;
    }

    .buildBalanceHead {
        font-size: 21px;
    }

    /* Capitalization Part */
    .buildCapitalTable {
        min-width: 992px;
        /* display: flex;
        gap: 10px; */
    }

    .buildCapitalTableDiv {
        overflow-x: auto;
    }

    .buildCapitalTableDiv::-webkit-scrollbar {
        display: none;
    }

    .BookLinkHading {
        font-size: 18px;
    }

    /* cash flow */

    .cashFlwTblInn table input {
        width: 70px;
        font-size: 13px;
    }

    .cashFlwTblInn table th {
        text-align: center;
        font-size: 13px;
    }

    .cashFlwTblInn table tr td {
        text-align: center;
        font-size: 13px;
    }

    .cfBigtxt {
        font-size: 12px !important;
    }

    .buildCshFlwhead {
        font-size: 14px;
    }



}

@media (max-width: 767px) {

    /* Balance Sheet Part */
    .buildBalLeftTdSpan {
        font-size: 13px;
    }

    .buildBalRightTdInp input {
        padding: 3px 10px !important;
        font-size: 14px;
        margin: 0 !important;
    }

    .buildBalLeftTdInp input {
        padding: 3px 10px !important;
        font-size: 14px;
    }

    /* Trial Balance Part */
    .buildTrialTable {
        min-width: 768px;
    }

    .buildTrialTableDiv {
        overflow-x: auto;
    }

    .buildTrialTableDiv::-webkit-scrollbar {
        display: none;
    }

    .bulidTriLeftTh {
        padding-left: 60px;
    }
}

@media (max-width: 575px) {
    .buildIncmStTd input {
        padding: 4px 5px !important;
    }

    .buildIncmStTh span {
        font-size: 15px;
    }

    .buildIncmStTh12 span {
        font-size: 12px;
    }

    .delBuildRowBtn {
        font-size: 13px;
    }

    .buildAddBtn {
        padding: 0 6px;
        font-size: 10px;
    }

    .buildIncmStTable {
        min-width: 700px;
    }

    .buildIncmStTd span {
        font-size: 12px;
    }

    .buildTotSpan {
        font-size: 15px !important;
    }

    .buildProSpan {
        font-size: 15px !important;
    }

    .buildRemoveBtn {
        top: -1px;
    }

    /* Balance Sheet Part */
    .buildBalLeftTdFoot span {
        font-size: 15px;
    }

    .buildBalanceHead {
        font-size: 18px;
    }

    /* Trial Balance Part */
    .buildTriLeftTdSpan {
        font-size: 18px;
    }

    .buildTrialLeftFootTd span {
        font-size: 18px;
    }

    /* Capitalization Part */

    .BookLinkHading {
        font-size: 16px;
    }

    .buildCapLeftFootTd span {
        font-size: 16px;
    }

    /* cash flow */

    .cashFlwTblInn table tr td {
        width: 9%;
    }

    .cashFlwTblInn table input {
        width: 50px;
        font-size: 12px;
    }
}

@media (max-width: 479px) {

    /* Trial Balance Part */
    .buildTrialTable {
        min-width: 550px;
    }

    .bulidTriLeftTh {
        padding-left: 36px;
    }

    .buildBalanceHead {
        font-size: 15px;
    }

    .buildTriLeftTdSpan {
        font-size: 15px;
    }

    .buildTriRemoveBtn {
        padding: 0 2px;
        top: -3px;
    }

    .buildTrialRightTd input {
        font-size: 14px;
        padding: 4px 10px;
    }

    .buildTrialLeftTd input {
        padding: 4px 10px !important;
        font-size: 14px;
    }

    .buildTrialLeftFootTd span {
        font-size: 15px;
    }

    /* Capitalization Part */
    .buildCapLeftThSpan {
        font-size: 16px;
    }

    .buildCapLeftTd input {
        padding: 4px 10px !important;
        font-size: 14px;
    }

    .buildCapMiddleTd input {
        padding: 4px 10px !important;
        font-size: 14px;
    }

    .buildCapRightTd input {
        padding: 4px 10px !important;
        font-size: 14px;
    }

    .buildCapMiddleFootTd input {
        padding: 4px 10px !important;
        font-size: 14px;
    }

    /* cash flow */

    .cfBigtxt {
        text-align: left !important;
    }

    .cashFlwAddBtn {
        height: 20px;
        width: 20px;
        font-size: 10px;
    }

    .cashFlwTblInn table tr td {
        font-size: 11px;
    }

    .cashFlwTblInn table th {
        font-size: 11px;
    }

    .cashFlwTblInn {
        gap: 4px;
    }

    .cshFlwRemoveBtn {
        left: -22px;
    }

    .cfBigtxt {
        font-size: 11px !important;
        padding-right: 0;
    }

    .cashFlwTblInn table input {
        width: 45px;
    }

    .buildCshFlwhead {
        font-size: 12px;
    }
}

@media (max-width: 399px) {

    /* Balance Sheet Part */
    .buildBalTable {
        min-width: 400px;
    }

    .buldBalTabInnerDiv {
        overflow-x: auto;
    }

    .buldBalTabInnerDiv::-webkit-scrollbar {
        display: none;
    }
}

.sched-drop a {
    color: #0d2537;
    font-size: 14px;
    cursor: pointer;
}

.sched-drop a:hover {
    font-weight: 600;
}