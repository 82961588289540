ul {
  padding: 0;
  margin: 0;
}

.dropdown-toggle::after {
  border: none !important;
}


/* Navigation */
.main-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

.mainheader {
  width: 100%;
  margin: 0 auto;
  /* position: fixed; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.mainheader.is-sticky {
  background: #fff;
  z-index: 999;
  width: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  height: 80px;
  left: 0;
  right: 0;
}

#header-section .navbar-brand {
  width: 150px;
  height: auto;
}

#header-section .navbar-nav .nav-link {
  /* font-family: 'Times New Roman', Times, serif; */
  color: #0e2537;
  font-size: 18px;
  padding: 0 14px;
  position: relative;
  text-decoration: none;
}

#header-section .navbar-nav .nav-link::after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #0e2537;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

#header-section .navbar-nav .nav-link:hover::after {

  width: 100%;
  left: 0;
}

#header-section .navbar-nav .nav-item {
  margin: 0 5px;
}

#header-section .navbar-nav .nav-item .arrow_down {
  position: absolute;
  top: 60%;
  right: -5px;
  transform: translate(0%, -50%);
  font-size: 14px;
}

#header-section .navbar-nav .dropdown-menu {
  box-shadow: 0px 0px 5px 2px #0717213b;
  border-radius: 12px;
  height: 516px;
  overflow-y: auto;
}

#header-section .navbar-nav .dropdown-menu::-webkit-scrollbar {
  display: none;
}

#header-section .navbar-nav .dropdown-item:active {
  background-color: transparent;
  font-weight: 700;
  color: #0e2537;
}

#header-section .navbar-nav .dropdown-item:hover {
  background-color: transparent;
  font-weight: 600;
  color: #0e2537;
  font-size: 17px;
}


.hover-effect {
  width: 100%;
  left: 0;
  border-bottom: 3px solid black;
}

#header-section .login-btn {
  width: 100%;
  /* max-width: 200px; */
  max-width: 140px;
  height: 50px;
  padding: 5px 10px;
  margin: 0 0 0 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background-color: transparent;
  /* border: 2px solid #3c3c3b; */
  border: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  color: #3c3c3b;
  transition: 0.8s;
}

/* #header-section .login-btn:hover {
  background-color: #102a3d;
  color: #fff;
  border: 2px solid #102a3d;
} */


#header-section .translate {
  width: 100%;
  max-width: 200px;
  height: 50px;
  padding: 2px;
  margin: 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background-color: transparent;
  border: none !important;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  color: #3c3c3b;
  transition: 0.8s;
}

#header-section .borderright {
  position: absolute;
  width: 2px;
  height: 35px;
  right: -20px;
  top: 50%;
  transform: translate(0%, -50%);
  background-color: #3c3c3b;
}

/* Footer */

#footer-section {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #0e2537;
}

#footer-section .footer-content {
  padding: 50px 0;
}

#footer-section .footer-content .footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#footer-section .footer-content .footer-top p {
  font-family: 'Times New Roman', Times, serif;
  font-size: 12px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  margin: 0;
  padding-top: 5px;
}

#footer-section .footer-content .footer-logo {
  max-width: 170px;
  width: 100%;
  height: auto;
  overflow: hidden;
}

#footer-section .footer-content .footer-logo img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

#footer-section .footer-content .social-links {
  position: relative;
}

#footer-section .footer-content .social-links::before {
  content: " ";
  /* position: absolute; */
  border-left: 1px solid #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

#footer-section .footer-content .social-links a {
  text-decoration: none;
  display: inline-block;
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 50%;
  /* padding: 10px 10px; */
  color: #0e2537;
  text-align: center;
  line-height: 38px;
  font-size: 18px;
  margin-left: 10px;
  transition: 0.8s;
}

#footer-section .footer-content .social-links a:hover {
  background-color: #33aaa4;
  color: #fff;
}

#footer-section .footer-content .footer-mid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 22px;
}


#footer-section .footer-content .footer-link ul {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
}

#footer-section .footer-content .footer-link ul li {
  list-style: none;
}

#footer-section .footer-content .footer-link ul li a {
  text-decoration: none;
  padding-right: 35px;
  color: #33aaa4;
  transition: 0.8s;
}

#footer-section .footer-content .footer-link ul li a:hover {
  color: #fff;
}

#footer-section .footer-content .footer-mail a {
  text-decoration: none;
  color: #fff;
  transition: 0.8s;
}

#footer-section .footer-content .footer-mail a:hover {
  color: #33aaa4;
}

#footer-section .footer-content .footer-login {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

#footer-section .footer-content .footer-login a {
  text-decoration: none;
  /* padding-right: 35px; */
  color: #33aaa4;
  padding-top: 20px;
}


#footer-section .footer-content .footer-bottom a {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
}


#footer-section .footer-content .footer-bottom p {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

/*----Log In----------*/

#login_banner_area {
  width: 100%;
  height: auto;
  padding: 100px 0 120px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#login_banner_area .banner-top-heading {
  display: flex;
  justify-content: center;
  align-items: center;
}

#login_banner_area .banner-top-heading h4 {
  font-size: 24px;
  color: #868686;
  letter-spacing: 5px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

#login_banner_area .banner-second-heading {
  display: flex;
  justify-content: center;
  align-items: center;
}


#login_banner_area .banner-second-heading h2 {
  font-size: 45px;
  color: #081722;
  /* letter-spacing: 5px; */
  font-weight: 400;
  margin: 0;
  padding-top: 100px;
}

#login_banner_area .banner-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
}

#login_banner_area .banner-btn .negocius-btn {
  width: 100%;
  max-width: 200px;
  height: 50px;
  padding: 5px 10px;
  margin: 0 20px 0 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background-color: transparent;
  border: 2px solid #3c3c3b;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 500;
  color: #3c3c3b;
  transition: 0.8s;
}

#login_banner_area .banner-btn .negocius-btn:hover {
  background-color: #102a3d;
  color: #fff;
  border: 2px solid #102a3d;
}


#login_banner_area .banner-btn .personal-btn {
  width: 100%;
  max-width: 200px;
  height: 50px;
  padding: 5px 10px;
  margin: 0 20px 0 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background: linear-gradient(to bottom, #18465e 1%, #14384d 51%, #0f273a 100%);
  border: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 500;
  color: #fff;
  transition: 0.8s;
}

#login_banner_area .banner-btn .personal-btn:hover {
  border: 2px solid #102a3d;
  background: transparent;
  color: #102a3d;
}

#login-form {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 0;
  /* background-color: #fff; */
}

#login-form label {
  font-size: 18px;
  font-weight: 400;
  color: #868686;
  display: inline-block;
  margin-bottom: 15px;
}

#login-form input {
  width: 100%;
  height: 50px;
  border: 2px solid #868686;
  color: #0e2537;
  font-size: 20px;
  font-weight: 400;
  border-radius: 8px;
  box-shadow: none;
  outline: 0;
}

#login-form input:focus {
  box-shadow: none;
  outline: 0;
  border: 2px solid #868686;
}

#login-form .form-field {
  position: relative;
  border: 2px solid #868686;
  border-radius: 8px;
}

#login-form .extra-input {
  position: relative;
}

#login-form .form-field .form-group {
  width: 85%;
  margin-bottom: 0;
}

#login-form .form-field input {
  border: none;
  width: 100%;
  height: 50px;
}

#login-form .lock-icon {
  position: absolute;
  top: 20px;
  /* left: 0; */
  right: 15px;
  bottom: 0;
  width: 18px;
  height: 18px;
}

#login-form p {
  color: #868686;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 5px 0;
}

#login-form a {
  text-decoration: none;
  color: #3c3c3b;
  font-size: 16px;
  font-weight: 400;
}

#login-form .form-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

#login-form .login-btn {
  width: 100%;
  max-width: 200px;
  height: 50px;
  padding: 5px 10px;
  /* margin: 0 20px 0 0; */
  margin: 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background: linear-gradient(to bottom, #18465e 1%, #14384d 51%, #0f273a 100%);
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  transition: 0.8s;
}

#resgister-section {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

#resgister-section .register_content {
  display: flex;
  justify-content: center;
  align-items: center;
}

#resgister-section .register_content .register-me {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #868686;
  font-size: 16px;
  font-weight: 400;
}

#resgister-section .register_content h3 {
  padding-top: 15px;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 5px;
}

#social-section {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-top: 120px;
  padding-bottom: 120px;
}

#social-section .social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

#social-section .social-icon p {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #868686;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 25px;
}

#social-section .social-icon ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

#social-section .social-icon ul li {
  list-style: none;
}

#social-section .social-icon ul li a {
  text-decoration: none;
  margin: 0 10px;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 25px;
  background: linear-gradient(to bottom, #18465e 1%, #14384d 51%, #0f273a 100%);
}


/* Password Conformation */

#new-pass {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 120px 0;
}

#new-pass .pass-heading h2 {
  font-size: 30px;
  font-weight: 400;
  color: #081722;
  margin: 0;
  padding-bottom: 50px;
}

#new-pass .pass-form {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

#new-pass .pass-form input {
  width: 100%;
  height: 60px;
  border: 1px solid #868686;
  color: #0e2537;
  font-size: 20px;
  font-weight: 400;
  border-radius: 4px;
  padding-left: 25px;
  box-shadow: none;
  outline: 0;
}

#new-pass .pass-form .new-pass-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}

#new-pass .pass-form .new-pass-btn .pass-btn {
  width: 100%;
  max-width: 200px;
  height: 60px;
  position: relative;
  padding: 5px 10px;
  margin: 0 20px 0 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background: linear-gradient(to bottom, #18465e 1%, #14384d 51%, #0f273a 100%);
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 500;
  color: #fff;
  transition: 0.8s;
}

#new-pass .pass-form .form-group {
  position: relative;
}

/* Blog Banner */

#banner-heading {
  padding-top: 120px;
}

#banner-heading .heading-name {
  width: 61%;
  margin: 0 auto;
}

#banner-heading .heading-name h2 {
  color: #0e2537;
  font-size: 50px;
  font-weight: 800;
  line-height: 62px;
  margin: 0;
  padding: 0;
}

#blog-banner {
  width: 100%;
  height: auto;
  padding: 80px 0;
  /* padding: 170px 0 290px; */
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

#blog-banner .banner-cnt {
  max-width: 400px;
  width: 100%;
  height: auto;
  /* height: 432px; */
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#blog-banner .banner-cnt img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}


/* Blog Slider */

#read-section {
  padding: 80px 0;
}

#read-section .slider-heading {
  display: flex;
  justify-content: center;
  align-items: center;
}

#read-section .slider-heading h2 {
  color: #4d4d4d;
  font-size: 42px;
  text-align: center;
  line-height: 1.2;
  font-weight: 700;
  margin: 0;
  padding-bottom: 80px;
}

#read-section .blog-slider .slide-item {
  border: 1px solid #4d4d4d;
}

#read-section .blog-slider .slide-image {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

#read-section .blog-slider .slide-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

#read-section .blog-slider .slide-content {
  width: 100%;
  height: 200px;
  padding: 20px 20px;
  /* border: 1px solid #4d4d4d; */
}

#read-section .blog-slider .top-content {
  text-align: left;
}

#read-section .blog-slider .top-content h5 {
  color: #000000;
  font-size: 25px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

#read-section .blog-slider .top-content h6 {
  color: #000000;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  padding-top: 15px;
}

#read-section .blog-slider .bottom-content {
  padding-top: 80px;
}

#read-section .blog-slider .bottom-content p {
  color: #0d2536;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  font-weight: 600;
}

#read-section .blog-slider .bottom-content a {
  color: #999999;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

#read-section .blog-slider .slick-prev:before {
  content: "\f053";
  font-family: FontAwesome;
  font-size: 50px;
  line-height: 1;
  opacity: .75;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 6px;
}

#read-section .blog-slider .slick-next:before {
  content: "\f054";
  font-family: FontAwesome;
  font-size: 50px;
  line-height: 1;
  opacity: .75;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Bnanner Image */


#blog-image {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 80px 0;
}

#blog-image .inner-container {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

#blog-image .inner-container .inner-content {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

#blog-image .inner-container .mid-content {
  margin-top: 50px;
}

#blog-image .inner-container .inner-content .left-image {
  width: 500px;
  height: auto;
  overflow: hidden;
}

#blog-image .inner-container .inner-content .left-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

#blog-image .inner-container .inner-content .mid-logo {
  width: 200px;
  height: auto;
  overflow: hidden;
  margin-bottom: 95px;
}


#blog-image .inner-container .inner-content .mid-logo img {
  width: 200px;
  height: auto;
  object-fit: cover;
  object-position: center;
}

#blog-image .inner-container .inner-content .play-store {
  width: 150px;
  height: auto;
  margin: 0 auto;
  margin-bottom: 20px;
  /* overflow: hidden; */
}

#blog-image .inner-container .inner-content .play-store img {
  width: 150px;
  height: auto;
  object-fit: cover;
  object-position: center;
}

#blog-image .inner-container .inner-content .ios {
  width: 150px;
  height: auto;
  margin: 0 auto;
  /* overflow: hidden; */
}

#blog-image .inner-container .inner-content .ios img {
  width: 150px;
  height: auto;
  object-fit: cover;
  object-position: center;
}

#blog-image .inner-container .inner-content .right-image {
  width: 500px;
  height: auto;
  overflow: hidden;
}

#blog-image .inner-container .inner-content .right-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}


/* Nosotros */

#about-us {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 80px 0;
}

#about-us .top-heading {
  width: 100%;
  /* height: 130px; */
  background-color: #0e2537;
  padding: 45px 0;
}

#about-us .top-heading .heading {
  display: flex;
  justify-content: center;
  align-items: center;
}

#about-us .top-heading .heading h2 {
  color: #fff;
  font-size: 60px;
  line-height: 1.2;
  font-weight: 600;
  text-align: center;
}

#about-us .about-image {
  width: 100%;
  height: 500px;
  overflow: hidden;
  margin-top: -125px;
}

#about-us .about-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

#about-us .right-content {
  padding: 50px 0;
}

#about-us .right-content p {
  color: #0e2537;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  padding: 0;
}

#about-us .power-by {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

#about-us .power-by p {
  color: #0e2537;
  font-size: 22px;
  line-height: 30px;
  text-align: left;
  padding: 0;
  margin: 0;
}

/* Fisibility Lite */

#fisibility-lite {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 80px 0;
}

#fisibility-lite .left-logo {
  width: 100%;
  height: auto;
  overflow: hidden;
}

#fisibility-lite .left-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

#fisibility-lite .left-content {
  padding-top: 35px;
}

#fisibility-lite .left-content p {
  color: #0e2537;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  padding: 0;
}

#fisibility-lite .right-image {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  width: 100%;
  height: 600px;
}

#fisibility-lite .right-image img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

/* Conversation */

#conversation {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 50px 0;
}

#conversation .mission {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

#conversation .vission {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 80px;
}

#conversation .convo-left {
  text-align: left;
}

#conversation .convo-right {
  width: 50%;
  text-align: right;
}

#conversation .convo-left h2 {
  color: #0d2537;
  font-size: 60px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  text-align: left;
  margin: 0;
  padding: 0;
}

#conversation .convo-left .left-content {
  width: 61%;
  padding-top: 30px;
}

#conversation .convo-left .left-content p {
  color: #0e2537;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  padding: 0;
}

#conversation .convo-right h2 {
  color: #0d2537;
  font-size: 60px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

#conversation .convo-right .right-content {
  padding-top: 30px;
}

#conversation .convo-right .right-content p {
  color: #0e2537;
  font-size: 20px;
  line-height: 30px;
  padding: 0;
}


#conversation .convo-bg {
  width: 100%;
  height: 420px;
  /* padding: 50px 50px; */
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  margin-top: -80px;
}

/* Financial */

#financial {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 50px 0;
}

#financial .financial-heading {
  margin: 0 auto;
  max-width: 900px;
}

#financial .financial-heading h3 {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #0d2537;
  line-height: 35px;
}


#financial .main-box {
  position: relative;
  /* padding: 20px 20px; */
}

/* #financial .main-box ul {
  position: relative;
} */

#financial .main-box ul li {
  list-style: none;
  width: 70%;
  margin-left: 40px;
  color: #0e2537;
  font-size: 18px;
  line-height: 24px;
  padding: 0;
}

#financial .main-box ul li::before {
  content: "\f00c";
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #071823;
  color: #fff;
  font-size: 30px;
  text-align: center;
  line-height: 50px;
}

/* Restore Password */

#restore {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 120px 0;
}

#restore h3 {
  color: #071721;
  font-size: 35px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

#restore .heading-description {
  width: 100%;
  padding-top: 25px;
}

#restore .heading-description p {
  color: #858585;
  font-size: 25px;
  line-height: 35px;
  text-align: left;
  padding: 0;
  margin: 0;
}

#restore .inner-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 50px 20px 0 20px;
}

#restore .inner-container .form {
  position: relative;
}

#restore .inner-container input {
  width: 100%;
  height: 65px;
  border: 1px solid #858585;
  box-shadow: none;
  outline: 0;
  font-size: 23px;
  color: #0d2537;
  line-height: 20px;
  border-radius: 7px;
  padding-left: 20px;
}

#restore .inner-container p {
  color: #858585;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  padding: 0;
  margin: 0;
}

#restore .inner-container a {
  text-decoration: none;
  color: #3b3b3a;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  padding: 0;
  margin: 0;
}

#restore .inner-container a:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
}

#restore .inner-container .reset-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

#restore .inner-container .password-submit {
  width: 100%;
  max-width: 200px;
  height: 50px;
  padding: 5px 10px;
  margin: 0 20px 0 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background: linear-gradient(to bottom, #18465e 1%, #14384d 51%, #0f273a 100%);
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  transition: 0.8s;
}

/* Creat Your Account */

#create-account {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 120px 0;
}

#create-account .heading h4 {
  font-size: 32px;
  font-weight: 400;
  line-height: 20px;
  color: #858585;
}

#create-account .inner-container {
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 20px;
}

#create-account .inner-container .text-field {
  position: relative;
}

#create-account .inner-container .form-group {
  margin-bottom: 10px;
}

#create-account .inner-container input {
  width: 100%;
  height: 65px;
  border: 1px solid #545453;
  box-shadow: none;
  outline: 0;
  font-size: 23px;
  color: #0d2537;
  line-height: 18px;
  border-radius: 7px;
  padding-left: 20px;
}

#create-account .inner-container .lock-field {
  position: relative;
  width: 100%;
  border: 1px solid #545453;
  border-radius: 7px;
  margin-bottom: 10px;
}

#create-account .inner-container .lock-field input {
  border: none;
  width: 90%;
  height: 45px;
  box-shadow: none;
  outline: 0;
  font-size: 23px;
  color: #0d2537;
  line-height: 18px;
  padding-left: 20px;
  padding-top: 20px;
}

#create-account .inner-container .lock-icon {
  position: absolute;
  top: 18px;
  bottom: 0;
  /* left: 0; */
  right: 20px;
  width: 25px;
  height: 25px;
}

#create-account .inner-container .lock-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

#create-account .inner-container .form-checkbox {
  display: flex;
  padding-top: 10px;
}


#create-account .inner-container .check-field {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  margin-right: 45px;
  cursor: pointer;
  color: #858585;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

#create-account .inner-container .check-field input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

#create-account .inner-container .check-field .checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #858585;
  /* background-color: #eee; */
}

#create-account .inner-container .check-field:hover input~.checkmark {
  border: 1px solid #858585;
  /* color: #0e2537; */
}

#create-account .inner-container .check-field input:checked~.checkmark {
  background-color: #0e2537;
}

#create-account .inner-container .check-field .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

#create-account .inner-container .check-field input:checked~.checkmark:after {
  display: block;
}

/* #create-account .inner-container .check-field .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
} */


#create-account .inner-container .terms-condition {
  padding-top: 10px;
}

#create-account .inner-container .account-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

#create-account .inner-container .account-btn .register-btn {
  width: 100%;
  max-width: 200px;
  height: 50px;
  padding: 5px 10px;
  margin: 0 20px 0 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background: linear-gradient(to bottom, #18465e 1%, #14384d 51%, #0f273a 100%);
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  transition: 0.8s;
}


/* Create Profile */

#create-profile {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 120px 0;
  position: relative;
}

#create-profile h2 {
  font-size: 55px;
  font-weight: 600;
  line-height: 30px;
  color: #0d2537;
  padding-bottom: 22px;
  margin: 0;
}

#create-profile h4 {
  font-size: 30px;
  font-weight: 400;
  line-height: 20px;
  color: #3b3b3a;
  padding-bottom: 45px;
  margin: 0;
}

#create-profile p {
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
  color: #858585;
  padding: 0;
  margin: 0;
}

#create-profile .profile-form {
  padding-top: 20px;
}

#create-profile .profile-form input {
  width: 100%;
  height: 60px;
  border: 1px solid #3c3c3b;
  color: #0d2537;
  font-size: 22px;
  padding-left: 20px;
  line-height: 20px;
  border-radius: 3px;
  margin: 0;
  box-shadow: none;
  outline: 0;
}

/* #create-profile .slect-style{
  position: relative;
  margin-bottom: 16px;
} */

#create-profile select {
  width: 100%;
  height: 60px;
  border: 1px solid #3c3c3b;
  color: #0d2537;
  font-size: 22px;
  padding-left: 20px;
  line-height: 27px;
  border-radius: 3px;
  margin: 0;
  box-shadow: none;
  outline: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;

  background: url('../src/images/dropdown-icon.png');
  background-repeat: no-repeat;
  background-size: 15px 8px;
  background-position: right;
  background-origin: content-box;
}

/* #create-profile select {
	appearance: none;
} */

/* #create-profile .slect-style::after{
  position: absolute;
  content: "\f078";
  font-family: FontAwesome;
  font-size: 20px;
  color: #000;
  top:20px;
  bottom: 0;
  left: 0;
  right: 20px;
} */

#create-profile .profile-image {
  width: 100%;
  height: 450px;
  overflow: hidden;
}

#create-profile .profile-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

#create-profile .profile-bottom input {
  width: 100%;
  height: 60px;
  border: 1px solid #3c3c3b;
  color: #0d2537;
  font-size: 22px;
  padding-left: 20px;
  line-height: 20px;
  border-radius: 3px;
  margin: 0;
  box-shadow: none;
  outline: 0;
}

#create-profile .profile-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

#create-profile .profile-btn .continue-btn {
  width: 100%;
  max-width: 200px;
  height: 50px;
  padding: 5px 10px;
  margin: 0 20px 0 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background: linear-gradient(to bottom, #18465e 1%, #14384d 51%, #0f273a 100%);
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  transition: 0.8s;
}

/* Profile 2 */


#profile2 {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 120px 0;
  /* position: relative;
  z-index: 999; */
}

#profile2 h2 {
  font-size: 55px;
  font-weight: 800;
  line-height: 30px;
  color: #0d2537;
  padding-bottom: 22px;
  margin: 0;
}

#profile2 h4 {
  font-size: 30px;
  font-weight: 400;
  line-height: 20px;
  color: #3b3b3a;
  padding-bottom: 45px;
  margin: 0;
}

#profile2 p {
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
  color: #858585;
  padding: 0;
  margin: 0;
}

#profile2 .profile-form {
  padding-top: 20px;
}

#profile2 .profile-form input {
  width: 100%;
  height: 60px;
  border: 1px solid #3c3c3b;
  color: #0d2537;
  font-size: 22px;
  padding-left: 20px;
  line-height: 20px;
  border-radius: 3px;
  margin: 0;
  box-shadow: none;
  outline: 0;
}

#profile2 .profile-form input::placeholder {
  color: #0d2537;
}

/* #create-profile .slect-style{
  position: relative;
  margin-bottom: 16px;
} */

#profile2 select {
  width: 100%;
  height: 60px;
  border: 1px solid #3c3c3b;
  color: #0d2537;
  font-size: 22px;
  padding-left: 20px;
  line-height: 20px;
  border-radius: 3px;
  margin: 0;
  box-shadow: none;
  outline: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;

  background: url('../src/images/dropdown-icon.png');
  background-repeat: no-repeat;
  background-size: 15px 8px;
  background-position: right;
  background-origin: content-box;
}

/* #create-profile select {
	appearance: none;
} */

/* #create-profile .slect-style::after{
  position: absolute;
  content: "\f078";
  font-family: FontAwesome;
  font-size: 20px;
  color: #000;
  top:20px;
  bottom: 0;
  left: 0;
  right: 20px;
} */

#profile2 .profile-image {
  width: 100%;
  height: 450px;
  overflow: hidden;
}

#profile2 .profile-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

#profile2 .profile-bottom input {
  width: 100%;
  height: 60px;
  border: 1px solid #3c3c3b;
  color: #0d2537;
  font-size: 22px;
  padding-left: 20px;
  line-height: 20px;
  border-radius: 3px;
  margin: 0;
  box-shadow: none;
  outline: 0;
}

#profile2 .profile-bottom input::placeholder {
  color: #0d2537;
}

#profile2 .profile-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

#profile2 .profile-btn .continue-btn {
  width: 100%;
  max-width: 200px;
  height: 60px;
  padding: 5px 10px;
  margin: 0 20px 0 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background: linear-gradient(to bottom, #18465e 1%, #14384d 51%, #0f273a 100%);
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  transition: 0.8s;
}

/* Otp Box */

#otp-box {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 120px 0;
}

#otp-box .form {
  padding-top: 50px;
  position: relative;
  z-index: 1050;
}

#otp-box .otp-heading h4 {
  color: #071721;
  font-size: 28px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding-bottom: 20px;
}

#otp-box .otp-description {
  width: 69%;
}

#otp-box .otp-description p {
  color: #858585;
  font-size: 22px;
  line-height: 35px;
  text-align: left;
  padding: 0;
  margin: 0;
}

#otp-box .inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#otp-box .otpContainer {
  max-width: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#otp-box .otpContainer input {
  width: 60px;
  height: 55px;
  margin-right: 20px;
  border: 1px solid #3c3c3b;
  background-color: #ebeff3;
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
  font-size: 25px;
  color: #0d2537;
  font-weight: 600;
  line-height: 20px;
  padding-left: 22px;
}

#otp-box .otpContainer input:focus {
  background-color: #fff;
}

#otp-box .inner-container .otp-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}

#otp-box .inner-container .otp-btn .hide {
  width: 100%;
  max-width: 200px;
  height: 50px;
  padding: 5px 10px;
  margin: 0 20px 0 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background: linear-gradient(to bottom, #18465e 1%, #14384d 51%, #0f273a 100%);
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  transition: 0.8s;
}

#otp-box .inner-container .link-text {
  text-align: center;
  padding: 5px 0;
}

#otp-box .inner-container .link-text p {
  color: #858585;
  font-size: 16px;
  line-height: 35px;
  text-align: center;
  padding: 0;
  margin: 0;
}

#otp-box .inner-container .link-text a {
  text-decoration: none;
  color: #3b3b3a;
  font-size: 16px;
  line-height: 35px;
  text-align: center;
  padding: 0;
  margin: 0;
}

#otp-box .inner-container .link-text a:hover {
  text-decoration: underline;
}


/* Cipbit Header */

#cipbit-header {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 28px 0;
}

#cipbit-header .header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



#cipbit-header .header-wrap .header-logo {
  width: 200px;
  height: auto;
  overflow: hidden;
}

#cipbit-header .header-wrap .header-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

#cipbit-header .header-wrap .header-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

#cipbit-header .header-wrap .header-right .calender-logo {
  width: 125px;
  height: auto;
  overflow: hidden;
  cursor: pointer;
}

#cipbit-header .header-wrap .header-right .calender-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

#cipbit-header .header-wrap .header-right .bell-icon {
  background-color: transparent;
  border: none;
  font-size: 30px;
  color: #0e2537;
  padding-left: 25px;
  outline: 0;
  box-shadow: none;
  margin: 0;
  position: relative;
}

#cipbit-header .header-wrap .header-right .setting-icon {
  background-color: transparent;
  border: none;
  font-size: 30px;
  color: #0e2537;
  padding-left: 25px;
  outline: 0;
  box-shadow: none;
  margin: 0;
}

#cipbit-header .header-wrap .header-right .hambar {
  background-color: transparent;
  border: none;
  font-size: 30px;
  color: #0e2537;
  padding-left: 25px;
  outline: 0;
  box-shadow: none;
  margin: 0;
}



/* Cipbit Content */

#cipbit-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 15px 0;
}

/* #cipbit-content .top-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.top-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* #cipbit-content .top-btn .calender-btn {
  width: 100%;
  max-width: 278px;
  height: 40px;
  padding: 5px 10px;
  margin: 0 20px 0 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background: #868686;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  transition: 0.8s;
} */


.top-btn .calender-btn {
  width: 100%;
  max-width: 278px;
  height: 40px;
  padding: 5px 10px;
  margin: 0 20px 0 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background: linear-gradient(to right, #18465e 1%, #14384d 51%, #0f273a 100%);
  border: none;
  /* border-radius: 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  color: #fcf6f6;
  transition: 0.8s;
}

/* #cipbit-content .top-btn .calender-btn:hover {
  background: linear-gradient(to right, #18465e 1%, #14384d 51%, #0f273a 100%);
} */

.top-btn .calender-btn:hover {
  background: #8e9eab !important;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #eef2f3, #8e9eab) !important;
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #eef2f3, #8e9eab) !important;
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.timeframe-btn-active {
  background: linear-gradient(to right, #fefefe 10%, #d9d9d8 50%, #c7c7c6 100%) !important;
  color: black !important;
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

#cipbit-content .slide-item {
  padding: 40px 0;
}

#cipbit-content .slide-item .slide-content {
  display: flex;
  /* justify-content: center;
 align-items: center; */
  width: 100%;
}

.managerpagebtn {
  background: #4b6cb7;
  background: -webkit-linear-gradient(to right, #194860, #0f2739);
  background: linear-gradient(to right, #194860, #0f2739);
}

#cipbit-content .slide-item .slide-content .content-left {
  background-color: #ebeff3;
  width: 65%;
  padding: 20px;
}

#cipbit-content .slide-item .slide-content .content-left h4 {
  color: #071721;
  font-weight: 400;
  font-size: 30px;
  margin: 0;
  padding-bottom: 10px;
}

#cipbit-content .slide-item .slide-content .content-left input {
  background-color: #fff;
  width: 100%;
  height: 43px;
  color: #858585;
  font-size: 14px;
  border: none;
  font-weight: 400;
  padding-left: 20px;
  box-shadow: none;
  outline: 0;
}

#cipbit-content .slide-item .slide-content .content-left .post-btn {
  max-width: 115px;
  width: 100%;
  height: 28px;
  background-color: #868686;
  padding: 2px 25px;
  border: none;
  border-radius: 10px;
  color: #ebeef2;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

#cipbit-content .slide-item .slide-content .content-right {
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(to right, #18465e 1%, #14384d 51%, #0f273a 100%);
}

#cipbit-content .slide-item .slide-content .content-right .cash-btn {
  max-width: 140px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 5px 20px; */
  color: #0d2537;
  line-height: 29px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 10px;
  text-align: center;
  border: none;
  box-shadow: none;
  outline: 0;
}

#cipbit-content .slide-item .slide-content .content-right .credit-btn {
  max-width: 140px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 5px 20px; */
  color: #0d2537;
  line-height: 29px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 10px;
  text-align: center;
  border: none;
  box-shadow: none;
  outline: 0;
}

#cipbit-content .bottom-content {
  background-color: #ebeff3;
  margin-top: 30px;
}

#cipbit-content .bottom-content .inner-container {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

#cipbit-content .bottom-content .inner-container h2 {
  color: #071721;
  font-size: 30px;
  text-align: center;
  font-weight: 400;
  line-height: 50px;
  margin: 0;
  padding-bottom: 10px;
}

#cipbit-content .bottom-content .inner-container input {
  width: 100%;
  height: 43px;
  background-color: #fff;
  color: #858585;
  font-size: 14px;
  border: none;
  font-weight: 400;
  padding-left: 28px;
  box-shadow: none;
  outline: 0;
}

#cipbit-content .bottom-content .bottom-btn {
  background: linear-gradient(to right, #18465e 1%, #14384d 51%, #0f273a 100%);
}

#cipbit-content .bottom-content .bottom-btn .inner-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

#cipbit-content .bottom-content .bottom-btn .inner-container .date-btn {
  max-width: 115px;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 5px 20px; */
  color: #0d2537;
  line-height: 29px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 10px;
  text-align: center;
  border: none;
  box-shadow: none;
  outline: 0;
  margin-right: 15px;
}

#cipbit-content .bottom-content .bottom-btn .inner-container .final-btn {
  max-width: 115px;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 5px 20px; */
  color: #0d2537;
  line-height: 29px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 10px;
  text-align: center;
  border: none;
  box-shadow: none;
  outline: 0;
}

#cipbit-content .slick-prev:before {
  content: "\f053";
  font-family: FontAwesome;
  font-size: 50px;
  line-height: 1;
  opacity: .75;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 6px;
}

#cipbit-content .slick-next:before {
  content: "\f054";
  font-family: FontAwesome;
  font-size: 50px;
  line-height: 1;
  opacity: .75;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* White Paper */

#paper-top {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 50px 0;
  background-color: #f6f6f6;
}

#paper-top .top-heading {
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
}

#paper-top .top-heading h4 {
  color: #0e2537;
  font-size: 18px;
  font-weight: 600;
}

#paper-top .main-heading {
  display: flex;
  justify-content: center;
  align-items: center;
}

#paper-top .main-heading h2 {
  color: #0e2537;
  font-size: 55px;
  font-weight: 600;
  letter-spacing: 4px;
  margin: 0;
}

#paper-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 50px 0;
}

#paper-content .heading {
  /* padding: 30px 0; */
  display: flex;
  justify-content: center;
  align-items: center;
}

#paper-content .heading h4 {
  color: #868787;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 15px;
}

#paper-content .line {
  width: 80px;
  height: 2px;
  margin: 0 auto;
  background-color: #868787;
}

#paper-content .box {
  width: 100%;
  height: auto;
  border: 1px solid #d9d9d9;
}

#paper-content .box .buisness-image {
  width: 100%;
  height: 218px;
  overflow: hidden;
}

#paper-content .box .buisness-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

#paper-content .box .box-content {
  padding: 20px;
}

#paper-content .box .box-content h3 {
  color: #0e2537;
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 5px;
  margin: 0;
}

#paper-content .box .box-content p {
  color: #0e2537;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 13px;
  margin: 0;
}

#paper-content .box .box-content .box-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#paper-content .box .box-content .box-link h6 {
  color: #0e2537;
  font-size: 12px;
  font-weight: 800;
  margin: 0;
}

#paper-content .box .box-content .box-link .arrow-link {
  text-decoration: none;
  margin-left: 7px;
  color: #868787;
}

#paper-content .box:hover {
  -webkit-box-shadow: 6px 7px 12px -1px rgba(0, 0, 0, 0.44);
  -moz-box-shadow: 6px 7px 12px -1px rgba(0, 0, 0, 0.44);
  box-shadow: 6px 7px 12px -1px rgba(0, 0, 0, 0.44);
}

#paperheader {
  width: 100%;
  height: 100%;
  height: 70px;
  margin: 0 auto;
  background-color: #0d2537;
}

#paperheader .header-image {
  max-width: 200px;
  width: 100%;
  height: 70px;
  overflow: hidden;
}

#paperheader .header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#paperheader .setting-icon {
  background-color: transparent;
  border: none;
  font-size: 30px;
  color: #fff;
  padding-left: 25px;
  outline: 0;
  box-shadow: none;
  margin: 0;
}

#paperheader .header-image img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Management */

#mng-top {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  height: 80px;
  background-color: #d8e4ec;
  padding: 10px 0;
  /* padding: 50px 0; */
}

#mng-top h2 {
  font-size: 35px;
  color: #081722;
  font-weight: 400;
  line-height: 50px;
  margin: 0;
}

#mng-top .right-box {
  width: 100%;
  height: 100%;
}

#mng-top .right-box .top-search {
  position: relative;
  max-width: 350px;
  width: 100%;
  margin-left: auto;
}

#mng-top .right-box .top-search input {
  width: 100%;
  height: 52px;
  background-color: #fff;
  border: none;
  box-shadow: none;
  padding-left: 50px;
  outline: 0;
}

#mng-top .right-box .top-search i {
  position: absolute;
  top: 23px;
  left: 15px;
  /* right: 0; */
  bottom: 0;
  font-size: 24px;
  color: #3c3c3b;
}

#creation {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 30px 0;
}

#creation .inner-container {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
}

#creation .inner-container .cnt-wrap {
  display: flex;
  width: 100%;
}

#creation .inner-container .cnt-wrap .left-heading {
  width: 43%;
}

#creation .inner-container h2 {
  font-size: 30px;
  color: #081722;
  font-weight: 400;
  /* line-height: 50px; */
  margin: 0;
}

#creation .inner-container .cnt-wrap .creation-btns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 25px;
  width: 100%;
}

.creation-btns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 25px;
  width: 100%;
}

#creation .inner-container .cnt-wrap .month-btn {
  max-width: 200px;
  width: 100%;
  height: 30px;
  background-color: transparent;
  border: 1px solid #4a5b69;
  font-size: 18px;
  color: #081722;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  margin: 0;
  box-shadow: none;
  outline: 0;
}

#creation .inner-container .cnt-wrap .day-btn {
  max-width: 200px;
  width: 100%;
  height: 30px;
  background-color: transparent;
  border: 1px solid #4a5b69;
  font-size: 18px;
  color: #081722;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  margin: 0;
  box-shadow: none;
  outline: 0;
}

#creation .inner-container .cnt-wrap .year-btn {
  max-width: 200px;
  width: 100%;
  height: 30px;
  background-color: transparent;
  border: 1px solid #4a5b69;
  font-size: 18px;
  color: #081722;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  margin: 0;
  box-shadow: none;
  outline: 0;
}

#dropbox {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

#dropbox .inner-container {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0;
}

/* #dropbox .inner-container .form-group{
  background: linear-gradient(to right, #fefefe 10%,#d9d9d8 50%,#c7c7c6 100%);
} */

#dropbox .inner-container select {
  width: 100%;
  height: 50px;
  background: linear-gradient(to right, #fefefe 10%, #d9d9d8 50%, #c7c7c6 100%);
  border: none;
  box-shadow: none;
  outline: 0;
  -webkit-box-shadow: 0px 4px 10px -3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 4px 10px -3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 10px -3px rgba(0, 0, 0, 0.3);

  /* appearance: none; */
  /* background-image: url("/src/images/drop-icon.png");
  background-repeat: no-repeat;
  background-size: 15px 8px;
  background-position: right;
  background-origin: content-box; */
}

#clafication {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

#clafication .table-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#clafication .table-top .top-left {
  width: 100%;
}

#clafication .table-top .top-select {
  max-width: 185px;
  width: 100%;
}

#clafication .table-top select {
  width: 100%;
  height: 30px;
  background-color: transparent;
  border: 2px solid #9d9d9d;
  font-size: 17px;
  color: #081722;
  font-weight: 600;
  padding: 0 0.75rem;
  border-radius: 0;
  appearance: none;
  background: url('../src/images/dropdown-icon.png');
  background-repeat: no-repeat;
  background-size: 15px 8px;
  background-position: right;
  background-origin: content-box;
  box-shadow: none;
  outline: 0;
}

#clafication .table-top .todo-btn {
  text-decoration: underline;
  font-size: 12px;
  color: #081722;
  font-weight: 600;
  white-space: nowrap;
}

#managebtn {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 20px;
}

#managebtn .btn-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#managebtn .btn-wrap .bill-btn {
  max-width: 255px;
  width: 100%;
  height: 45px;
  background: linear-gradient(to right, rgb(31, 70, 94) 1%, rgb(20, 56, 77) 51%, rgb(15, 39, 58) 100%);
  /* background: linear-gradient(to right, #fefefe 10%, #d9d9d8 50%, #c7c7c6 100%); */
  font-size: 20px;
  color: #fff;
  /* color: #3c3c3b; */
  border: none;
  box-shadow: none;
  outline: 0;
  padding: 0;
  margin: 0;
  transition: all 0.8s;
}

#managebtn .btn-wrap .bill-btn:hover {
  background: linear-gradient(to right, #fefefe 10%, #d9d9d8 50%, #c7c7c6 100%);
  -webkit-box-shadow: 5px 5px 35px 3px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 5px 5px 35px 3px rgba(0, 0, 0, 0.23);
  box-shadow: 5px 5px 35px 3px rgba(0, 0, 0, 0.23);
  color: #3c3c3b;

}

.fisibility {

  padding: 20px 0;

}

.fisibility .total {
  margin-top: 10px;
}

.fisibility .total .text {
  border-radius: 5px;
  text-align: center;
  margin-bottom: 20px;
  padding: 5px;
  box-shadow: 10px 10px 2px 0px #1b4158;
  border: 1px solid #1b4158;
}

/* .fisibility .total .text h4 {
  padding-bottom: 10px;
} */
.fisibility .total .text h4 {
  font-size: 16px;
}

.fisibility .total2 {
  margin-top: 10px;
}

.dropbtn {

  color: #0d2537;
  font-size: 16px;
  border: none;
  cursor: pointer;
  background: none;
  font-weight: bold;
  font-size: 34px;
  margin-right: 8px;
}



.dropdown {
  position: relative;
  display: flex;
  align-items: baseline;
}

.dropdown-content {
  position: absolute;
  min-width: 290px;
  top: 42px;
  overflow: hidden;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  margin: 10px auto;
  background-color: #ffff;
  border-radius: 5px;
  z-index: 12;
  padding: 6%;
}

h4.dts {
  font-size: 20px;
}

.btnp {
  border-radius: 0px !important;
  background-color: #877c7c52 !important;
  color: #fff !important;
}

.tda {
  font-size: 20px;
  text-align: left;
}

.ftrbtn .modal-content {
  border-radius: 0 !important;
  box-shadow: 0px 0px 9px 1px rgb(0 0 0 / 17%);
  border: 0;

}

.ftrbtn .footerBtn a {
  background: linear-gradient(to right, rgb(46, 92, 115) 0%, rgb(13, 40, 61) 49%);
  color: #fff;
  padding: 5px 30px;
  border-radius: 18px;
  margin: 0 10px;
  text-decoration: none;
}

.ftrbtn .footerBtn {
  display: flex;
  justify-content: center;
}

.ftrbtn .modal {
  background-color: rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 14;
  width: 100%;
  height: 100%;
}

.ftrbtn label {
  color: #081722;
  font-weight: 600;
}

.ftrbtn input {
  border: 2px solid #8c959d !important;
}

.ftrbtn textarea {
  resize: none;
  background-color: #aecbe538 !important;
}

.dropdown-content a {
  color: #0E2537 !important;
  padding: 4px 16px;
  text-decoration: none;
  display: block;
  font-weight: 600;
  border-bottom: 1px solid;
}


.dropdown-content a:nth-child(12) {
  border-bottom: 0;
}

#bill-cnt {
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
}

#bill-cnt .bill-left h2 {
  font-size: 35px;
  color: #081722;
  font-weight: 400;
  line-height: 50px;
  padding-top: 20px;
  /* padding-bottom: 20px; */
  margin: 0;
}

#bill-cnt .bill-cnt {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  -webkit-box-shadow: -2px 23px 35px -11px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: -2px 23px 35px -11px rgba(0, 0, 0, 0.26);
  box-shadow: -2px 23px 35px -11px rgba(0, 0, 0, 0.26);
  padding: 20px 30px;
}

#bill-cnt .bill-cnt .top-cnt input {
  width: 100%;
  height: 50px;
  border: 1px solid #484847;
  color: #868686;
  font-size: 18px;
  padding-left: 20px;
  box-shadow: none;
  outline: 0;
}

#bill-cnt .bill-cnt .top-cnt .top-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#bill-cnt .bill-cnt .txr-btn {
  max-width: 140px;
  width: 100%;
  height: 45px;
  background: linear-gradient(to right, rgb(31, 70, 94) 1%, rgb(20, 56, 77) 51%, rgb(15, 39, 58) 100%);
  border-radius: 10px;
  font-size: 19px;
  color: #fff;
  border: none;
  box-shadow: none;
  outline: 0;
  padding: 0;
  margin: 0;
}

#bill-cnt .bill-cnt .txr-btn:disabled {
  background: linear-gradient(to right, #1948608e, #0f273993);
}


#bill-cnt .bill-cnt .monthly-btn {
  max-width: 140px;
  width: 100%;
  height: 45px;
  background: linear-gradient(to right, rgb(31, 70, 94) 1%, rgb(20, 56, 77) 51%, rgb(15, 39, 58) 100%);
  border-radius: 10px;
  font-size: 19px;
  color: #fff;
  border: none;
  box-shadow: none;
  outline: 0;
  padding: 0;
  margin-left: 20px;
}

#bill-cnt .bill-cnt .monthly-btn:disabled {
  background: linear-gradient(to right, #1948608e, #0f273993);
}

#bill-cnt .bill-cnt .btm-cnt {
  padding-top: 30px;
}

#bill-cnt .bill-cnt .btm-cnt label {
  font-size: 28px;
  color: #081722;
  font-weight: 400;
  line-height: 50px;
  margin: 0;
}

#bill-cnt .bill-cnt .btm-cnt input {
  width: 100%;
  height: 50px;
  border: 1px solid #484847;
  color: #868686;
  font-size: 18px;
  padding-left: 20px;
  box-shadow: none;
  outline: 0;
}

#bill-cnt .bill-cnt .btm-btn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 10px;
}

#bill-cnt .bill-cnt .btm-btn .post-btn {
  max-width: 135px;
  width: 100%;
  height: 35px;
  background: linear-gradient(to right, #fefefe 10%, #d9d9d8 50%, #c7c7c6 100%);
  border-radius: 10px;
  font-size: 18px;
  color: #3c3c3b;
  border: none;
  box-shadow: none;
  outline: 0;
  padding: 0;
  margin: 0;
}

#bill-cnt .bill-right {
  max-width: 300px;
  height: 350px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border: 1px solid #666666;
}

#bill-cnt .form_label {
  display: grid;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #44444F;
  text-align: center;
  margin: 60px 0;
}

#uploadFile {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: -999px;
  width: 1px;
  height: 1px;
}

#budget {
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
  /* padding: 50px 0; */
}

#budget .budget-left h2 {
  font-size: 35px;
  color: #081722;
  font-weight: 400;
  line-height: 50px;
  padding-top: 20px;
  /* padding-bottom: 20px; */
  margin: 0;
}

#budget .budget-left label {
  font-size: 26px;
  color: #081722;
  font-weight: 400;
  line-height: 50px;
  margin: 0;
}

#budget .budget-left input {
  width: 100%;
  height: 50px;
  border: 1px solid #484847;
  color: #868686;
  font-size: 18px;
  padding-left: 20px;
  box-shadow: none;
  outline: 0;
}

#budget .budget-left .budget-cnt {
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: -2px 23px 35px -11px rgba(0, 0, 0, 0.26);
  padding: 20px 30px;
}

#budget .budget-left .budget-cnt select {
  /* appearance: none; */
  background: url("../src/images/drop-icon.png");
  background-repeat: no-repeat;
  background-size: 15px 18px;
  background-position: right;
  background-origin: content-box;
}

#budget .budget-left .budget-cnt .drop {
  background-color: #858585;
  width: 100%;
  height: 50px;
  border: none;
  color: #fff;
  font-size: 18px;
  padding-left: 20px;
  box-shadow: none;
  outline: 0;
}

#budget .budget-left .budget-cnt option {
  background-color: #fff;
  color: #000;
}

#budget .budget-left .cnt-btn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

#budget .budget-left .post-btn {
  max-width: 135px;
  width: 100%;
  height: 35px;
  background: linear-gradient(to right, #fefefe 10%, #d9d9d8 50%, #c7c7c6 100%);
  border-radius: 10px;
  font-size: 18px;
  color: #3c3c3b;
  border: none;
  box-shadow: none;
  outline: 0;
  padding: 0;
  margin: 0;
}

#budget .bill-right {
  max-width: 300px;
  height: 350px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border: 1px solid #666666;
}

#budget .bill-right .form_label {
  display: grid;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #44444F;
  text-align: center;
  margin: 60px 0;
}

#uploadPhoto {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: -999px;
  width: 1px;
  height: 1px;
}

#all-news {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}

#all-news .news-box {
  width: 100%;
  height: auto;
  /* margin-top: 10px; */
  background-color: #fff;
  padding: 30px 50px 20px 50px;
  -webkit-box-shadow: 0px 17px 37px -18px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 17px 37px -18px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 17px 37px -18px rgba(0, 0, 0, 0.36);
}

#all-news .news-box ul {
  margin-bottom: 0;
  background-color: #ebeff3;
  padding: 10px 50px;
}

#all-news .news-box a {
  text-decoration: none;
}

#all-news .news-box .cnt-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#all-news .news-box .cnt-wrap h4 {
  font-size: 24px;
  color: #081722;
  font-weight: 400;
  line-height: 50px;
  margin: 0;
}

#all-news .news-box .cnt-wrap i {
  color: #081722;
  font-size: 24px;
}

#user-header {
  width: 100%;
  /* height: 100%; */
  height: 80px;
  background-color: #d8e4ec;
  padding: 10px 0;
  margin-bottom: 18px;
}

#user-header h2 {
  font-size: 32px;
  color: #081722;
  font-weight: 400;
  line-height: 40px;
  margin: 0;
  padding: 0;
}

#user-header .right-box {
  width: 100%;
  height: 100%;
}

#user-header .right-box .top-search {
  position: relative;
  max-width: 350px;
  width: 100%;
  margin-left: auto;
}

#user-header .right-box .top-search input {
  width: 100%;
  height: 40px;
  background-color: #fff;
  border: none;
  box-shadow: none;
  padding-left: 50px;
  outline: 0;
}

#user-header .right-box .top-search i {
  position: absolute;
  top: 18px;
  left: 15px;
  font-size: 23px;
  color: #3c3c3b;
}

#userlist {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

#userlist .list-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 15px 20px;
  background-color: #ebeff3;
  margin-bottom: 20px;
}

#userlist .list-box .list-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#userlist .list-box .green-btn {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #8cc63f;
  margin-right: 10px;
}

#userlist .list-box .red-btn {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #bc0000;
  margin-right: 10px;
}

#userlist .list-box .list-left h4 {
  color: #0e2537;
  font-weight: 600;
  font-size: 18px;
  padding: 0;
  margin: 0;
}

#userlist .list-box .edit-btn {
  text-decoration: underline;
  color: #0e2537;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}

#user-form {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 120px 0;
}

#user-form .inner-container {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

#user-form .inner-container .user-name h4 {
  font-size: 23px;
  color: #081722;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  margin: 0;
  padding: 0;
}

#user-form .inner-container .user-pro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 20px;
}

#user-form .inner-container .user-pro .user-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#user-form .inner-container .user-pro .green-btn {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #8cc63f;
  margin-right: 10px;
}

#user-form .inner-container .user-pro .red-btn {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ee2020;
  margin-right: 10px;
}

#user-form .inner-container .user-pro h5 {
  font-size: 18px;
  color: #081722;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  margin: 0;
  padding: 0;
}

#user-form .inner-container .user-pro .user-right .disable-btn {
  text-decoration: underline;
  color: #0e2537;
  font-weight: 600;
  font-size: 16px;
}

#user-form .inner-container .user-form input {
  width: 100%;
  height: 50px;
  border: 1px solid #3c3c3b;
  color: #0d2537;
  font-size: 18px;
  padding-left: 20px;
  line-height: 20px;
  border-radius: 3px;
  margin: 0;
  box-shadow: none;
  outline: 0;
}

#user-form .inner-container .user-form select {
  width: 100%;
  height: 50px;
  border: 1px solid #3c3c3b;
  color: #0d2537;
  font-size: 22px;
  padding-left: 20px;
  line-height: 20px;
  border-radius: 3px;
  margin: 0;
  box-shadow: none;
  outline: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background: url("./images/dropdown-icon.png");
  background-repeat: no-repeat;
  background-size: 15px 8px;
  background-position: right;
  background-origin: content-box;
}

#user-form .inner-container .form-btm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

#user-form .inner-container .form-btm p {
  color: #868686;
  font-weight: 400;
  font-size: 13px;
  margin: 0;
}

#user-form .inner-container .form-btm i {
  color: #0d2537;
  font-size: 13px;
  margin-right: 5px;
}

#user-form .inner-container .form-btm .delete-btn {
  text-decoration: underline;
  color: #0e2537;
  font-weight: 400;
  font-size: 13px;
}

#user-form .inner-container .user-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

#user-form .inner-container .user-btn .save-btn {
  width: 100%;
  max-width: 190px;
  height: 50px;
  padding: 5px 10px;
  /* margin: 0 20px 0 0; */
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background: linear-gradient(to right, #18465e 1%, #14384d 51%, #0f273a 100%);
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  transition: 0.8s;
}

#help-form {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 50px 0;
}

#help-form h1 {
  font-size: 60px;
  font-weight: 600;
  line-height: 30px;
  color: #0d2537;
  padding-bottom: 22px;
  margin: 0;
}

#help-form h2 {
  font-size: 35px;
  font-weight: 400;
  line-height: 20px;
  color: #3b3b3a;
  padding-bottom: 45px;
  margin: 0;
}

#help-form .form-inner {
  max-width: 360px;
  width: 100%;
}

#help-form .form-inner input {
  width: 100%;
  height: 50px;
  border: 1px solid #979797;
  color: #0d2537;
  font-size: 19px;
  padding-left: 20px;
  line-height: 20px;
  border-radius: 3px;
  margin: 0;
  box-shadow: none;
  outline: 0;
}

#help-form .form-inner textarea {
  width: 100%;
  height: 130px;
  background-color: #ebeff3;
  border: 1px solid #979797;
  color: #868686;
  font-size: 19px;
  padding-top: 15px;
  padding-left: 20px;
  line-height: 20px;
  border-radius: 8px;
  resize: none;
  margin: 0;
  box-shadow: none;
  outline: 0;
}

#help-form .form-inner .help-btn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 10px;
}

#help-form .form-inner .send-btn {
  width: 100%;
  max-width: 150px;
  height: 40px;
  padding: 5px 10px;
  /* margin: 0 20px 0 0; */
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background: linear-gradient(to right, #18465e 1%, #14384d 51%, #0f273a 100%);
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  transition: 0.8s;
}

#help-form .help-image {
  width: 100%;
  height: 430px;
  display: flex;
  /* margin: 0 auto; */
  justify-content: center;
  overflow: hidden;
  align-items: center;
}

#help-form .help-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}


#invite {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 50px 0;
}

#invite h1 {
  font-size: 65px;
  font-weight: 600;
  line-height: 50px;
  color: #0d2537;
  padding-bottom: 22px;
  margin: 0;
}

#invite h2 {
  font-size: 40px;
  font-weight: 400;
  line-height: 20px;
  color: #3b3b3a;
  padding-bottom: 28px;
  margin: 0;
}

#invite p {
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
  color: #858585;
  padding: 0;
  margin: 0;
}

#invite .invite-social {
  padding-top: 50px;
}

#invite .invite-social ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#invite .invite-social .invite-icon {
  text-decoration: none;
  display: inline-block;
  width: 65px;
  height: 65px;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 67px;
  font-size: 40px;
  margin-right: 25px;
  transition: 0.8s;
  background: linear-gradient(to right, #18465e 1%, #14384d 51%, #0f273a 100%);
}

#invite .social-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 90px;
}

#invite .social-info .info-btn {
  text-decoration: underline;
  color: #0e2537;
  font-weight: 600;
  font-size: 18px;
  position: relative;
  bottom: 91px;
  left: 144px;
}

#invite .invite-image {
  width: 100%;
  height: auto;
  overflow: hidden;
}

#invite .invite-image img {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
}


#config-head {
  width: 100%;
  height: auto;
  margin: 0 auto;
  background-color: #d8e4ec;
  padding: 10px 0;
  margin-bottom: 25px;
}

#config-head h4 {
  font-size: 27px;
  color: #081722;
  font-weight: 600;
  margin: 0;
}

#config-form {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

#config-form .inner-container {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

#config-form .inner-container input {
  width: 100%;
  height: 50px;
  border: 1px solid #5e5e5e;
  color: #0d2537;
  font-size: 17px;
  padding-left: 10px;
  line-height: 20px;
  border-radius: 3px;
  margin: 0;
  box-shadow: none;
  outline: 0;
}

.drpbox {
  border: 2px solid #bfbfbf;
  padding: 7px;
  border-radius: 5px;
}

.drpdwnlst {
  padding: 7px;
  height: 42px
}

.drpdwnlstown ul {
  padding: 0px;
  margin: 0px;
}

.drpboxflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drpbox input {
  border: 0px;
  outline: 0px;
}

.fltrsall {
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  border: none;
  outline: 0px;
  padding-top: 15px;
  text-decoration: underline;
}

#config-form .config-btn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 20px;
  margin-bottom: 30px;
}

#config-form .config-btn .eliminate-btn {
  text-decoration: underline;
  color: #0e2537;
  font-weight: 600;
  font-size: 16px;
}

#retos {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

#retos .retos-right {
  width: 100%;
  height: auto;
  /* background-color: #f2e9b8; */
  position: relative;
}

.retos-right .retos-left {
  background-color: #f2e9b8;
}

.retos-right .retos-left-platinam {
  background-color: #dce2e9;
}

.retos-right .retos-left-diamond {
  background-color: #87b7db;
}


#retos .retos-right .retos-bg {
  width: 100%;
  height: auto;
  overflow: hidden;
}

#retos .retos-right .retos-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

#retos .retos-right .result {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* z-index: 99; */
  padding: 25px 50px;
}

#retos .retos-right .result {
  /* color: #8c5711; */
  font-size: 24px;
  font-weight: 400;
  text-align: left;
}

#retos .retos-left .result {
  color: #8c5711;
}

#retos .retos-left-platinam .result {
  color: #868686;
}

#retos .retos-left-diamond .result {
  color: #0D2537;
}


#retos .retos-right .result .result-cnt {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

#retos .retos-right .result .result-cnt h3 {
  /* color: #8c5711; */
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 0;
}

#retos .retos-right .result .result-cnt h2 {
  /* color: #8c5711; */
  font-size: 70px;
  font-weight: 800;
  text-align: center;
  margin: 0;
  padding: 0;
}

#retos .retos-right .result .result-bar {
  padding-top: 44px;
}

#retos .retos-right .result .result-bar .progress {
  background-color: rgba(255, 255, 255, 0.4);
  width: 100%;
}

#retos .retos-left .result .result-bar .progress-bar {
  background-color: #8c5711 !important;
}

#retos .retos-left-platinam .result .result-bar .progress-bar {
  background-color: #343a40 !important;
}

#retos .retos-left-diamond .result .result-bar .progress-bar {
  background-color: #0d2537 !important;
}


#retos .retos-right .result .rating-number {
  /* color: #8c5711; */
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
  margin-left: 10px;
}

#retos .retos-right .result .outstanding {
  /* color: #8c5711; */
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 3px;
  padding-top: 5px;
  margin: 0;
}

#retos .retos-right .review-images {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#retos .retos-right .review-images .badge-image {
  width: 150px;
  height: 160px;
  overflow: hidden;
  position: relative;
  /* z-index: 99; */
}

#retos .retos-right .review-images .badge-image:focus {
  -webkit-box-shadow: 0px 0px 15px -3px rgba(140, 87, 17, 1);
  -moz-box-shadow: 0px 0px 15px -3px rgba(140, 87, 17, 1);
  box-shadow: 0px 0px 15px -3px rgba(140, 87, 17, 1);
}

#retos .retos-right .review-images .badge-image2 {
  width: 150px;
  height: 160px;
  overflow: hidden;
  position: relative;
  /* z-index: 99; */
}

#retos .retos-right .review-images .badge-image3 {
  width: 150px;
  height: 160px;
  overflow: hidden;
  position: relative;
  /* z-index: 99; */
}

#retos .retos-right .review-images .badge-image4 {
  width: 150px;
  height: 160px;
  overflow: hidden;
  position: relative;
  /* z-index: 99; */
}


#retos .retos-right .review-images .badge-image4 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#retos .retos-right .review-images .badge-image3 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#retos .retos-right .review-images .badge-image2 img {
  width: 100%;
  height: 100%;
}

#retos .retos-right .review-images .badge-image img {
  width: 100%;
  height: 100%;
}

/* #retos .retos-right .review-images .badge-focus{
  display: none;
} */

/* #retos .retos-right .review-images .badge-focus:hover{
background-image: url("images/oro-icon.png");
background-repeat: no-repeat;
background-position: center center;
height: 500px;
} */

#retos .retos-btm {
  max-width: 610px;
  width: 100%;
  margin: 0 auto;
  padding: 30px 0;
  padding: 2%;
}

#retos .historial {
  max-width: 100%;
  width: 100%;
}

/* edit */
.non-bg-text,
.bg-text {
  padding: 2px 35px;
}

#retos .historial h2 {
  color: #333333;
  font-size: 30px;
  font-weight: 800;
  text-align: left;
  margin: 0;
  padding: 15px 50px;
}

#retos .historial p {
  color: #666666;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  padding-left: 50px;
  padding-bottom: 5px;
  margin: 0;
}

#retos .historial .historical-btm {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  padding-top: 20px;
  padding-right: 32px
}

#retos .reset-btn {
  width: 100%;
  max-width: 130px;
  height: 35px;
  padding: 5px 10px;
  /* margin: 0 20px 0 0; */
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background: #808080;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  -webkit-box-shadow: 0px 3px 15px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 15px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 15px -6px rgba(0, 0, 0, 0.75);
}

#retos .historial .historical-btm p {
  color: #868686;
  font-weight: 400;
  font-size: 13px;
  margin: 0;
  padding-top: 3px;
}

/* retos-left gold*/
#retos .retos-left {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  -webkit-box-shadow: 0px 0px 15px -3px rgba(140, 87, 17, 1);
  -moz-box-shadow: 0px 0px 15px -3px rgba(140, 87, 17, 1);
  box-shadow: 0px 0px 15px -3px rgba(140, 87, 17, 1);
  position: relative;
  /* z-index: 99; */
}

#retos .retos-left .evenbg {
  background-color: #f2e9b8;
}


#retos .retos-left h2 {
  color: #8c5711;
  font-size: 30px;
  font-weight: 800;
  text-align: left;
  margin: 0;
  padding: 15px 40px 20px 40px;
}

#retos .retos-left .evenbg p {
  color: #8c5711;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding: 2px 0;
  margin: 0;
}

#retos .retos-left .evenbg h6 {
  color: #8c5711;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  margin: 0;
}

#retos .retos-left .oddbg p {
  color: #8c5711;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding: 2px 0;
  margin: 0;
}

#retos .retos-left .oddbg h6 {
  color: #8c5711;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  margin: 0;
}

/* reot platinum */
#retos .retos-left-platinam {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  -webkit-box-shadow: 0px 0px 15px -3px #868686;
  -moz-box-shadow: 0px 0px 15px -3px #868686;
  box-shadow: 0px 0px 15px -3px #868686;
  position: relative;
  /* z-index: 99; */
}

#retos .retos-left-platinam .evenbg {
  background-color: #bdc7d3;
}


#retos .retos-left-platinam h2 {
  color: #343a40;
  font-size: 30px;
  font-weight: 800;
  text-align: left;
  margin: 0;
  padding: 15px 40px 20px 40px;
}

#retos .retos-left-platinam .evenbg p {
  color: #343a40;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding: 2px 0;
  margin: 0;
}

#retos .retos-left-platinam .evenbg h6 {
  color: #343a40;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  margin: 0;
}

#retos .retos-left-platinam .oddbg p {
  color: #343a40;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding: 2px 0;
  margin: 0;
}

#retos .retos-left-platinam .oddbg h6 {
  color: #343a40;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  margin: 0;
}


/* reot diamond */
#retos .retos-left-diamond {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  -webkit-box-shadow: 0px 0px 15px -3px #75a5c9;
  -moz-box-shadow: 0px 0px 15px -3px #75a5c9;
  box-shadow: 0px 0px 15px -3px #75a5c9;
  position: relative;
  /* z-index: 99; */
}

#retos .retos-left-diamond h2 {
  color: #0D2537;
  font-size: 30px;
  font-weight: 800;
  text-align: left;
  margin: 0;
  padding: 15px 40px 20px 40px;
}

#retos .retos-left-diamond .evenbg {
  background-color: #87b7db;
}

#retos .retos-left-diamond .evenbg p {
  color: #0D2537;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding: 2px 0;
  margin: 0;
}

#retos .retos-left-diamond .evenbg h6 {
  color: #0D2537;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  margin: 0;
}

#retos .retos-left-diamond.oddbg p {
  color: #0D2537;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding: 2px 0;
  margin: 0;
}

#retos .retos-left-diamond .oddbg h6 {
  color: #0D2537;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  margin: 0;
}


.eliminate-modal {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  align-items: center;
  align-content: center;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 2%;
}

.eliminate-modal .eliminate-body {
  width: 400px;
  height: 200px;
  background-color: #fff;
  border-radius: 25px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.58);
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.58);
  margin-top: -35px;
}

.eliminate-cnt {
  text-align: center;
}

.eliminate-cnt p {
  color: #0e2537;
  font-weight: 400;
  font-size: 20px;
}

.eliminate-cnt .choice-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.eliminate-cnt .eliminate-btn {
  width: 100%;
  max-width: 140px;
  height: 35px;
  padding: 5px 10px;
  /* margin: 0 20px 0 0; */
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background: linear-gradient(to right, #18465e 1%, #14384d 51%, #0f273a 100%);
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  transition: 0.8s;
  -webkit-box-shadow: 0px 3px 15px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 15px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 15px -6px rgba(0, 0, 0, 0.75);
}

.eliminate-cnt .cancle-btn {
  width: 100%;
  max-width: 140px;
  height: 35px;
  /* padding: 5px 10px; */
  /* margin: 0 20px 0 0; */
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background: #868686;
  border: none;
  border-radius: 8px;
  margin-left: 20px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  transition: 0.8s;
  -webkit-box-shadow: 0px 3px 15px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 15px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 15px -6px rgba(0, 0, 0, 0.75);
}

.eliminate-modal .eliminate-img {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 300px;
  height: 250px;
  margin: 0 auto;
  display: grid;
  align-items: center;
  align-content: center;
  position: relative;
}


.circum-section {
  border: none;
  width: 100%;
  display: flex;
}


.circum-section .month1-btn {
  width: 200px;
  height: 40px;
  background-color: transparent;
  border: 1px solid #4d4d4d;
}

.circum-section .month2-btn {
  width: 200px;
  height: 40px;
  background-color: transparent;
  border: 1px solid #4d4d4d;
}

.circum-section .month3-btn {
  width: 200px;
  height: 40px;
  background-color: transparent;
  border: 1px solid #4d4d4d;
}

.cnt-wrap2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-heading2 {
  width: 100%;
}


/*******/

/* .dark_blue {
  filter: color(blue);
} */



/****payment****/

#Payment_confirmation {
  width: 100%;
  height: 100vh;
  padding: 40px 0;
  background-color: #e3f5f9;
}

#Payment_confirmation .head h5 {
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  color: #0d2537;
  padding: 0;
  margin-bottom: 40px;
  text-transform: uppercase;
}

#Payment_confirmation .head h5 span {
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  color: #0d2537;
  padding: 0;
  text-transform: uppercase;
}

.subscription_card {
  width: 100%;
  height: auto;
  max-width: 18rem;
  background-color: #ffff;
  box-shadow: 0px 0px 6px 5px #0717214b;
  border-radius: 24px;
  overflow: hidden;
  padding: 20px;
  margin: 0 auto 20px;
}

.subscription_card .card {
  position: relative;
  border: none;
  width: 100%;
  height: auto;
}

.subscription_card .card .card-body {
  width: 100%;
  height: 100%;
  padding: 0;
}

.subscription_card .card .card-title {
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  color: #000000;
  padding: 0 0 10px 0;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}

.subscription_card .card .card-title::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 30px;
  height: 4px;
  background-color: #071721;
  border-radius: 12px;
}

.subscription_card .amOunt h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  color: #000000;
  padding: 0 0 10px 0;
  margin-bottom: 30px;
  text-align: center;
}

.subscription_card .text {
  width: 100%;
  height: 180px;
}

.subscription_card .pay_btn {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.subscription_card .pay_btn .StripeCheckout {
  width: 90%;
  height: 40px;
}

.subscription_card .pay_btn .StripeCheckout span {
  background-image: none !important;
  box-shadow: none !important;
}

/******agriculture_sec***********/

#agriculture_sec {
  width: 100%;
  height: auto;
  padding: 50px 0;
}

.agriculture_head {
  width: 100%;
  height: auto;
  margin-bottom: 75px;
}

.agriculture_head h3 {
  font-size: 62px;
  line-height: normal;
  font-weight: 700;
  padding: 0;
  text-align: left;
}

.agriculture_head p {
  font-size: 28px;
  line-height: normal;
  text-align: left;
  font-weight: 500;
  color: #3c3c3b;
  padding: 0;
  margin: 0;
  max-width: 400px;
}

.agriculture_list ul li {
  list-style: auto;
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 15px;
  color: #0e2537;
}

.siGnup_btn {
  width: 100%;
  max-width: 200px;
  height: 45px;
  box-shadow: none;
  outline: 0;
  background-color: transparent;
  border: 1px solid #0e2537;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  color: #0e2537;
  transition: 0.6s;
}

.siGnup_btn:hover {
  background-color: #0e2537;
  color: #fff;
}

.agriculture_headtext {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}

.agriculture_headtext h4 {
  font-size: 48px;
  line-height: normal;
  font-weight: 600;
  padding: 0;
  margin-bottom: 15px;
}

.agriculture_headtext p {
  font-size: 28px;
  line-height: normal;
  text-align: justify;
  font-weight: 500;
  color: #3c3c3b;
  padding: 0;
  margin-bottom: 20px;

}

.agriculture_headtext ul li {
  font-size: 20px;
  line-height: normal;
  text-align: left;
  color: #2c7300;
  font-weight: 600;
  padding: 0;
  margin: 0;
  position: relative;
}

.agriculture_headtext ul li::before {
  content: "\f00c";
  font-family: FontAwesome;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translate(0%, -50%);
}

.agriculture_img {
  width: 100%;
  height: auto;
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.agriculture_img img {
  width: 100%;
  height: 100%;
}

.registrarAnddescargar {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.registrar_Btn {
  width: 100%;
  max-width: 150px;
  height: 40px;
  padding: 10px 20px;
  margin: 0;
  box-shadow: none;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #112e41;
  border-radius: 30px;
  border: 1px solid #112e41;
  transition: 0.6s;
  margin-right: 15px;
  text-transform: uppercase;
}

/* .registrar_Btn:hover {
  color: #112e41;
  background-color: transparent;
} */

.descargar_Btn {
  width: 100%;
  max-width: 150px;
  height: 40px;
  padding: 10px 20px;
  margin: 0;
  box-shadow: none;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #112e41;
  background-color: transparent;
  border-radius: 30px;
  border: 1px solid #112e41;
  transition: 0.6s;
  text-transform: uppercase;
}

/* .descargar_Btn:hover {
  color: #fff;
  background-color: #112e41;
} */

.agriculturetext_img {
  width: 100%;
  max-width: 60%;
  height: 250px;
  text-align: center;
}

.agriculturetext_img img {
  width: 100%;
  height: 100%;
}

.wrapBg_Img {
  width: 100%;
  height: auto;
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
}

/****banking****/
.banking {
  background-position: top, right;
  background-repeat: no-repeat;
  background-size: cover;

}

.banking h5 {
  color: #3c3c3b;
  font-size: 44px;
  font-weight: 400;
  margin-bottom: 70px;
}

.tickpara {
  display: flex;

}

.tick img {
  width: 30px;
  height: 23px;
  margin-top: 17px;
  margin-right: 10px;

}

.rightsideGap {
  max-width: 80%;
  margin-left: auto;
}

/***price****/
.PriceGetNow .corner {
  height: 31px;
  width: 42px;
  display: inline-block;
  background-color: rgb(13, 40, 61);
  position: absolute;
  left: -4%;
  top: 10%;
  z-index: -1;
  transform: rotate(58deg);
}

.PriceGetNow .Personal.corner {
  left: -4%;
  top: 20%;
  background: linear-gradient(to right, rgb(46 114 110) 51%, rgb(51, 124, 115) 100%);
}

.PriceGetNow {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 70px 0;
  z-index: 0;
}

.PriceGetNow .main {
  padding: 50px 40px;
}

.PriceGetNow .topHeading {
  font-size: 24px;
  color: #fff;
  background: linear-gradient(to right, rgb(46, 92, 115) 0%, rgb(13, 40, 61) 49%);
  font-weight: 600;
  text-align: center;
  max-width: 45%;
  padding: 5px 0;
  margin-left: -51px;
  margin-top: -38px;
  position: relative;
  z-index: 2;
}

.PriceGetNow h2 {
  color: #0E2537;
}

.price-details {
  padding: 50px 0 150px 0;
}

.bottomPart button {
  color: #297f74;
  background-color: #fff;
  padding: 10px 20px;
  border: 0;
  margin-left: auto;
  display: table;
  font-weight: 600;
  border-radius: 5px;
  z-index: 2;
  position: relative;

}

.bottomPart {
  background: linear-gradient(to right, #3b8e89 51%, #337c73 100%);
  padding: 15px;
  border-radius: 0px 0px 17px 17px;
}

.bottomPart ul {
  padding-left: 0;
  margin-bottom: 0;
}

.bottomPart ul li a {
  color: #fff;
  font-size: 13px;
}

.bottomPart ul li a i {
  padding-right: 4px;
}

.price-details h2 {
  color: #000;
  font-size: 53px;
  font-weight: bold;
}

.price-details h2 span {
  color: #297f74;
}

.price-details p {
  margin-top: 10px;
  color: #297f74;
}

.price-details .personal {
  margin-top: 50px;
  box-shadow: -2px 3px 8px -2px rgba(0, 0, 0, .5);
  background: #fff;
  position: relative;
  max-width: 100%;
  position: relative;
  /* border-radius: 0px 0px 17px 17px; */
}

.price-details .topHeading {
  font-size: 24px;
  color: #fff;
  background: linear-gradient(to right, #3b8e89 51%, #337c73 100%);
  font-weight: 600;
  text-align: center;
  max-width: 84%;
  padding: 5px 0;
  margin-left: -25px;
  margin-top: -10px;
  ;
}

.starter a,
.starter span {
  color: #297f74;
  font-size: 11px;
}

.line {
  width: 2px;
  height: 31px;
  background-color: #297f74;
  display: inline-block;
  margin: 0 12px;
}

.line2 {
  width: 100%;
  height: 4px;
  background-color: #297f74;
  display: inline-block;

}

.starter h4,
.starter h5 {
  color: #297f74;
  font-size: 17px;
  font-weight: 500;
  text-align: left;
}

.corner {
  height: 31px;
  width: 42px;
  display: inline-block;
  background-color: #297f74;
  position: absolute;
  left: -7%;
  top: 9%;
  z-index: -1;
  transform: rotate(51deg);
}

.corner.perMemo {
  top: 24%;
}

.starter h5 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.starter {
  display: block;
  justify-content: center;
  padding: 8px 15px;
  margin: 0 auto;
  display: table;

}

.lady {
  position: absolute;
  bottom: 0%;
  left: 62%;

}

.lady img {
  height: 500px;
  /* position: relative;
  bottom: 42px; */
}

.dog {
  position: absolute;
  left: 43px;
  bottom: -2%;
}

.dog img {
  width: 125px;
  position: relative;
  bottom: 153px;
}

.personalBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notiDrop {
  position: absolute;
  background-color: #fff;
  padding: 36px 10px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .5);
  z-index: 2;
  width: 340px;
  top: 18%;
  right: 13%;
  height: 412px;
  overflow-y: auto;

}

.miniDrop {
  position: absolute;
  background-color: #fff;
  padding: 10px 10px;
  box-shadow: 0px 0px 9px 1px rgb(0 0 0 / 50%);
  z-index: 2;
  width: 200px;
  top: 110%;
  left: -100%;
  height: 100px;
  overflow-y: auto;
}

.miniDrop p {
  margin: 2px;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  color: #0E2537;
  text-align: start;
}


.miniDrop p:hover {
  color: #7b91a1;
}

.notiDrop .delnoti {
  margin-left: 20px;
  font-size: 25px;
  cursor: pointer;
  color: #0E2537;
}

.notiDrop::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.notiDrop::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.notiDrop::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #375361;
}

.notiDrop .close {
  position: absolute;
  right: 14px;
  top: 8px;
}

.notiDrop ul {
  padding-left: 0;
}

.notiDrop ul li {

  padding: 0 20px;
}

.notiDrop ul li span {

  text-align: right;
  display: block;
  color: #333;
  font-size: 14px;

}

.notiDrop ul li h6 {
  font-weight: 400;
  font-size: 18px;
}

.notiDrop ul li h5 {
  font-size: 20px;
}

/****registerInvestmentModal*****/
.p-05,
.pl-05 {
  padding: 0.5em !important;
  margin-left: auto;
}

.w-fc {
  background-color: #f1f1f1 !important;
  width: fit-content;
  border-radius: 0.5rem !important;


}

.g-2-1fr {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 1em;
}


.registerButton {
  align-items: center;
  border-radius: 55px;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  height: auto;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
}

.registerButton h2 {
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 23px;
}

.registerButton img {
  margin-bottom: 1rem;
  width: 75%;
  box-shadow: 0 0 6px rgba(0, 0, 0, .5);
  border-radius: 5px;

}

.RegisterInvest {
  background-color: rgba(0, 0, 0, .5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 3;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 2%;
  width: 100%;
  height: 100%;
}

.Regiter.InvestREgi {
  width: 1107px;
  height: 90vh;
  padding: 2%;
  overflow-y: auto;
}

.Regiter.InvestREgi::-webkit-scrollbar {
  display: none;
}

.Regiter {
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  z-index: 9;
  padding: 5%;
}

.Regiter.SearchInvest {
  width: 650px;
  height: 600px;
  overflow-y: auto;
}

.Regiter.SearchInvest::-webkit-scrollbar {
  display: none;
}

.closeIcon {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 20px;
  color: #333;
}

.Regiter input,
select,
textarea {
  background: transparent;
  border: 2px solid hsla(0, 0%, 50%, .5) !important;
  border-radius: 0.25rem;
  padding: 0.5em;
  transition: .5s;
}

.Regiter .heading {
  text-align: center;
  margin-bottom: 16px;
  text-align: left;
  color: #0E2437;
  border-bottom: 2px solid #0E2437;
}

.datos {
  color: gray;
  font-size: 25px;
}

.Regiter .company {
  margin-bottom: 10px;
}

.Regiter .company .row {
  align-items: center;
}

.Regiter .mail {
  padding: 19px 10px;
}

.form-control:focus {
  box-shadow: none !important;
}

.Regiter .register .return {
  border-radius: 999px;
  font-size: 18px;
  line-height: 1.5;
  padding: 3px 1rem;
  outline: none;
  border: 0;
  background-color: gray;
  color: #fff;
}

.Regiter .register .confirm button {
  display: block;
  margin-left: auto;
  background-color: #0E2437;
  border: none;
  padding: 3px 1rem;
  font-size: 18px;
  color: white;
  border-radius: 999px;
  white-space: nowrap;
}

.Regiter label {
  line-height: normal;
  color: #013460;
  font-size: 15px;
  font-weight: 600;
  text-align: right;
}

/***personalModal***/
.personalModalwrap {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 2%;
  z-index: 9;

}

.personalModal {
  width: 448px;
  height: auto;
  background: linear-gradient(to right, rgb(46, 92, 115) 0%, rgb(13, 40, 61) 49%);
  /* background: linear-gradient(to right, #3b8e89 51%, #337c73 100%); */
  margin: 0 auto;
  position: relative;
  padding: 30px;
  box-shadow: -5px 4px 8px -6px rgb(1 1 1 / 45%);
  border-radius: 15px;

}

.PriceGetNow .lady {
  position: absolute;
  bottom: -17%;
  left: 58%;
}

.closeicon {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  position: absolute;
  right: 18px;
  top: 10px;
  text-decoration: none;
  border: 0;
  background-color: transparent;
}

.closeicon:hover {
  color: #fff;
  text-decoration: none;
}

.personalModal h4,
.personalModal h5 {
  margin-bottom: 0;
  font-weight: bold;
}

.personalModal h2 {
  color: #fff;
  font-size: 31px;
  font-weight: 600;
  margin-bottom: 3px;
}

.personalModal p {
  color: #b1eff5;
  font-size: 16px;
}

.personalModal .starter.bgcolor {
  background-color: #fff;
  padding: 7px 20px;
  border-radius: 36px;
  align-items: center;
  margin: 8px 12px 5px 12px;
  width: 87%;

}

/****blogBox****/
#newBogBoxWrap a {
  color: #000;
  text-decoration: none;
}

#newBogBoxWrap {
  padding: 50px 0;
}

#newBogBoxWrap .BlogBox {
  border: solid #00000020;
  margin-bottom: 30px;
  height: 428px;

}

#newBogBoxWrap .BlogBox .cntBox {
  padding: 15px 20px;
}

.BlogBox .cntBox h3 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.BlogBox img {
  height: 250px;
  width: 100%;
}

.BlogBox .cntBox p {
  color: #091722;
  font-size: 17px;
  line-height: 22px;
}

/****Aeps***/
.Aepstop {
  background: #012b52;
  /* margin-top: 10px; */
}

.Aepstop .container-fluid {
  padding: 0px !important;
  box-shadow: 0 0 4px grey;
}

.Aepstop .nav {
  height: 15vh;
  background: #012b52;
  display: flex;
  justify-content: end;
  align-items: center;
}

.Aepstop .nav a {
  padding: 10px 45px;
  font-size: 40px;
  color: white;
  text-decoration: none;

}

.Aepstop .white {
  height: 15vh;
  background: white;
}

.Aepstop .apis {
  background: #012b52;
}

.Aepstop .apis .text {
  color: white;
  padding: 10px 45px;
  line-height: .3;
}

.Aepstop .apis .text h2 {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 2px;
}

.Aepstop .apis .text p {
  font-size: 20px;
  letter-spacing: 2px;
}

.Aepstop .brown {
  height: 10vh;
  background: grey;
}

.Aepstop .support {
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 35px;

}

.Aepstop .support a {
  color: #012b52;
  font-weight: 400;
  text-decoration: none;
}

.Aepstop .support a:hover {
  color: #012b52;
  font-weight: 400;
  text-decoration: none;
}

/*****intellegence-platform***/

.index2 .container-fluid {
  padding: 0px !important;
  box-shadow: 0 0 4px grey;
}

.index2 .nav {
  height: 15vh;
  background: #012b52;
  display: flex;
  justify-content: end;
  align-items: center;
}

.index2 .nav a {
  padding: 10px 45px;
  font-size: 40px;
  color: white;
  text-decoration: none;
}

.index2 {
  margin-top: 10px;
  background: #012b52;
  text-align: justify;
}

.index2 .value {
  background: white;
  padding: 90px;
}

.index2 .value h1 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
}

.index2 .value h4 {
  font-size: 16px;
  color: #012b52;
  font-weight: bold;
}

.index2 .value p {
  font-size: 14px;
}

.index2 .image img {
  height: 333px;
  width: 100%;
}

/****digitalAudits****/
.index3 .container-fluid {
  padding: 0px !important;
  box-shadow: 0 0 4px grey;
}

.index3 .logo {
  height: 15vh;
  background: #012b52;
  display: flex;
  justify-content: end;
  align-items: center;
}

.index3 .logo a {
  padding: 10px 45px;
  font-size: 40px;
  color: white;
  text-decoration: none;
}

.index3 .heading {
  padding: 70px;
}

.index3 .digital {
  margin-top: 20px;
  margin-bottom: 25px;
}

.digital .Digital {
  color: #012b52;
  letter-spacing: 1px;
}

.digital .Digital span {
  font-weight: bold;
}

.index3 .why {
  margin-bottom: 25px;
  position: relative;
  margin-left: 10px;

}

.index3 .why span {
  writing-mode: vertical-lr;
  transform: rotate(90deg);
  position: absolute;
  left: -20px;
  top: 10px;
}

.index3 .para {
  text-align: justify;
}

.index3 .paragraph {
  margin-top: 20px;
  margin-bottom: 20px;
}

.index3 .paragraph span {
  color: #024481;
  font-weight: bold;
}

.index3 .footer {
  margin-top: 100px;
  color: grey;
  font-size: 14px;
  letter-spacing: 1.2px;
  text-align: justify;
  font-weight: 400;
}

.index3 .footer span {
  font-weight: bold;
}

.index3 .user-pic img {
  display: block;
  margin-left: auto;
  width: 450px;

}

.index3 .user-info {
  text-align: justify;
}

.index3 .user-info p {
  font-weight: 500;
}

.index3 .footer-Text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.index3 .footer-Text a {
  width: 140px;
  padding: 10px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 30px;
  box-shadow: -2px -2px 2px rgb(223, 223, 223);
  background: rgb(244, 247, 248);
  color: black;
  text-align: center;
  margin: auto;
  text-decoration: none;
  color: #012b52;
  letter-spacing: 0px;
}

.index3 .footer-Text h4 {
  font-size: 16px;
  font-weight: bold;
  color: #012b52;
}

.index3 .footer-Text p {
  color: grey;
}

.index3 .footer-Text span {
  font-weight: bold;
}

.index3 .footer-end {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #012b52;

}

.index3 .footer-end h5 {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
}

.index3 .footer-end h3 {
  font-size: 16px;
  font-weight: bold;

}

/****businessIntellegence****/
.Businesstop {
  padding: 20px 80px;
  background: #082e4a;
  display: flex;
  justify-content: end;
  align-items: center;
}

.Businesstop a {
  padding: 10px 45px;
  font-size: 30px;
  color: white;
  text-decoration: none;
}

/* hero section */

.hero {
  margin-top: 90px;
}

.hero .heroHeadBg {
  background-color: #082e4a;
  height: 100px;
  padding: 15px 0;
}

.hero .heroHeadBg h1 {
  color: #fff;
  padding-left: 160px;
}

.hero .heroHeadBg h1 {
  color: #fff;
  font-size: 30px;
  letter-spacing: 3px;
}

.hero .heroHeadBg h4 {
  color: #fff;
  letter-spacing: 2px;
  font-weight: 300;
  font-size: 20px;
}

.heroBg {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.heroBg img {
  height: 100%;
  width: 100%;
}

.greybg {
  height: 80px;
  background-color: #868986;
}

.Businessbottom {
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Businessbottom p {
  color: #082e4a;
}

/****Cip****/
.mainBody {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.mainBody .main h5 {
  color: #000;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 2px;
}

.mainBody .main h3 {
  color: #082f4b;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;
}

.tool .toolBody {
  background-color: #f4f4f4;
  height: 70px;
  width: 80%;
  position: relative;
}

.tool .toolOdd {
  margin-left: 85px;
  color: #082f4b;
  display: flex;
  align-items: center;
}

.tool .toolEven {
  margin-left: 140px;
  color: #464343;
  display: flex;
  align-items: center;
}

.tool .toolBody .dot {
  background-color: #082e4a;
  text-align: center;
  color: #fff;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: -28px;
  top: 7px;
  padding: 12px 0;
  font-size: 20px;
  z-index: 1;
}

.tool .toolBody .grey {
  background-color: #868986;
  text-align: center;
  color: #fff;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: -28px;
  top: 7px;
  padding: 12px 0;
  font-size: 20px;
  z-index: 1;
}

.tool .toolBody p {
  font-size: 15px;
  font-weight: 700;
  margin-left: 8%;
  margin-right: 5%;
  margin-bottom: 0 !important;
}

/* .toolEnd p {
  padding-top: 15px;
  font-size: 13px;
  font-weight: 500;
  color: #868987;
  letter-spacing: 1px;
  color:#212529;
} */

.mainBody .toolRegister {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mainBody .toolRegister button {
  margin: 15px 0;
  color: #082f4b;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #ecf0f3;
  background-image: radial-gradient(93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%),
    radial-gradient(66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  font-weight: 800;
  font-size: 12px;
  border: 1px solid #ecf0f3;
  cursor: pointer;
}

.mainBody .toolRegister button:hover {
  color: #ecf0f3;
  background-color: #082f4b;
  transition: 0.5s ease-in-out;
}

.mainBody .toolRegister a {
  text-decoration: none;
  color: #082f4b;
  font-weight: 800;
  letter-spacing: 1px;
}

.mainBody .toolRegister h6 {
  color: #868987;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0;
  letter-spacing: 1px;
}

.toolRegisterEnd {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toolRegisterEnd p {
  color: #082e4a;
}

/*****consolidatedAnalyticsBusiness****/
.consolidatedTop {
  padding: 20px 80px;
  background: #082e4a;
  display: flex;
  justify-content: end;
  align-items: center;
}

.consolidatedTop a {
  padding: 10px 45px;
  font-size: 30px;
  color: white;
  text-decoration: none;
}

.hero {
  margin-top: 90px;
}

.hero .heroHeadBg {
  background-color: #082e4a;
  height: 100px;
  padding: 15px 0;
}

.hero .heroHeadBg h1 {
  color: #fff;
  padding-left: 160px;
}

.hero .heroHeadBg h1 {
  color: #fff;
  font-size: 30px;
  letter-spacing: 3px;
}

.hero .heroHeadBg h4 {
  color: #fff;
  letter-spacing: 2px;
  font-weight: 300;
  font-size: 20px;
}

.heroBg {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.heroBg img {
  height: 100%;
  width: 100%;
}

.greybg {
  height: 80px;
  background-color: #868986;
}



.consolidatedDetails h3 {
  padding-top: 30px;
  color: #000;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
}

.consolidatedDetails h5 {
  color: #35556c;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
}

.consolidatedDetails ul li {
  list-style: disc;
}

.consolidatedDetails ul {
  padding: 0 25px;
  margin-bottom: 0 !important;
}

.consolidatedDetails p {
  color: #000;
  font-size: 15px;
  font-weight: 400;
}

.consolidatedAnalyticsBg {
  height: auto;
  width: 100%;
}

.consolidatedAnalyticsBg img {
  height: 100%;
  width: 100%;
}


.consolidatedAnalyticsEnd p {
  color: #949795;
  font-size: 10px;
  font-weight: 500;
  margin: 30px 0;
  letter-spacing: 1px;
}



.consolidatedEnd {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
}

.consolidatedEnd p {
  color: #456277;
  background-color: #fff;
  font-size: 16px;
}

.consolidatedAnalyticsRegister {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.consolidatedAnalyticsRegister .toolRegister {
  display: grid;
  justify-content: center;
}

.consolidatedAnalyticsRegister .toolRegister button {
  margin: 15px 0;
  color: #082f4b;
  padding: 10px 15px;
  border-radius: 20px;
  background-color: #ecf0f3;
  background-image: radial-gradient(93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%),
    radial-gradient(66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  font-weight: 800;
  font-size: 12px;
  border: 1px solid #ecf0f3;
  cursor: pointer;
}

.consolidatedAnalyticsRegister .toolRegister button:hover {
  color: #ecf0f3;
  background-color: #082f4b;
  transition: 0.5s ease-in-out;
}

.consolidatedAnalyticsRegister .toolRegister a {
  text-decoration: none;
  text-align: center;
  color: #082f4b;
  font-weight: 800;
  letter-spacing: 1px;
}

.consolidatedAnalyticsRegister .toolRegister h6 {
  color: #868987;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0;
  letter-spacing: 1px;
}



.consolidatedBottom {
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.consolidatedBottom p {
  color: #082e4a;
}

.normalTabPanelDiv {
  border: 1px solid #0009;
  width: 300px;
  position: absolute;
  top: 47px;
  left: 275px;
  padding: 14px 7px;
}

/****** Responsive *******/
@media (max-width:1300px) {
  .InvestREgi {
    height: 500px;
    overflow-y: auto;
    max-width: 100%;
    width: 100%;
  }

  .Regiter.SearchInvest {
    height: 500px;
  }

}

@media (max-width:1199px) {
  #ConfirmMessage .wraper h4 {
    font-size: 20px;
  }

  #ConfirmMessage .wraper h5 {
    font-size: 17px;
  }

  #ConfirmMessage .wraper input {
    padding: 8px 20px;
  }

  #header-section .navbar-nav .nav-link {
    padding: 0 16px;
  }


  #header-section .navbar-nav .dropdown-menu.mtAccountPop {
    right: 6%;
    left: auto;
  }
}

@media(max-width:1024px) {
  #read-section .blog-slider .slick-prev:before {
    font-size: 35px;
  }

  #read-section .blog-slider .slick-next:before {
    font-size: 35px;
  }

  #header-section .navbar-nav .nav-link {
    font-size: 18px;
  }
}

@media(max-width:1199px) {
  .Regiter.InvestREgi {
    width: 100%;

  }

  .RegisterInvest {
    justify-content: inherit;
  }

  .BlogBox .cntBox h3 {
    font-size: 17px;
  }

  .custominp {
    width: 100%;
  }

  .td-details {
    width: 100% !important;
  }
}

@media (max-width:1005px) {
  .Regiter label {
    line-height: 2em;
    font-size: 15px;
  }
}

@media(max-width:991px) {
  .personalModal {
    margin-bottom: 30px;
  }

  .corner {
    height: 31px;
    width: 42px;
    display: inline-block;
    background-color: #297f74;
    position: absolute;
    left: -3%;
    top: 11%;
    z-index: -1;
    transform: rotate(46deg);
  }

  .rightsideGap {
    max-width: 100%;
  }

  .lady {
    display: none;
  }

  .dog {
    display: none;
  }

  .boy {
    display: none;
  }

  .registrar_Btn {
    width: 100%;
    max-width: 111px;
    height: 34px;
    padding: 8px 20px;
    /* margin: 0; */
    font-size: 13px;

  }

  .descargar_Btn {
    width: 100%;
    max-width: 111px;
    height: 34px;
    padding: 8px 20px;
    margin: 0;
    font-size: 13px;
  }

  #header-section .navbar-collapse {
    text-align: center;
    padding: 20px 0;
    background-color: #fff;
  }

  #header-section .navbar-light .navbar-nav .nav-link {
    margin-bottom: 10px;
  }

  #header-section .login-btn {
    margin-left: 0;
  }

  #header-section .login-btn {
    max-width: 100%;
  }

  #banner-heading .heading-name {
    width: 100%;
  }

  #banner-heading .heading-name h2 {
    font-size: 30px;
    text-align: center;
    line-height: 40px;
  }

  #blog-banner .banner-cnt {
    max-width: 100%;
  }

  /* #header-section .navbar-toggler-icon{
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "\f0c9";
    font-family: FontAwesome;
    background: 50%/100% 100% no-repeat;
  } */

  #about-us .about-image {
    margin-top: 0;
    height: auto;
  }

  #fisibility-lite {
    padding: 20px 0;
  }

  #fisibility-lite .row {
    flex-direction: column-reverse;
  }

  #fisibility-lite .right-image {
    margin-bottom: 20px;
  }

  #conversation .convo-bg {
    background-size: contain;
  }

  #conversation .convo-left h2 {
    font-size: 40px;
  }

  #conversation .convo-left .left-content {
    width: 100%;
  }

  #conversation .convo-right h2 {
    font-size: 40px;
  }

  #conversation .convo-right {
    width: 100%;
  }

  #conversation .convo-bg {
    margin-top: -50px;
  }

  #financial {
    padding: 0;
  }

  #financial .financial-heading h3 {
    font-size: 22px;
  }

  .mainheader .navbar-toggler {
    display: none;
  }

  #mng-top {
    height: auto;
  }

  #mng-top h2 {
    text-align: center;
    margin-bottom: 10px;
  }

  #mng-top .right-box .top-search {
    max-width: 100%;
  }

  #creation .inner-container .cnt-wrap {
    flex-wrap: wrap;
  }

  #creation .inner-container .cnt-wrap .left-heading {
    width: 100%;
    margin-bottom: 10px;
  }

  #creation .inner-container h2 {
    text-align: center;
  }

  #creation .inner-container .cnt-wrap .creation-btns {
    padding-left: 0;
  }

  #managebtn .btn-wrap .bill-btn {
    max-width: 160px !important;
    width: 100%;
    height: 40px;
    font-size: 18px;
  }

  #user-header {
    height: auto;
  }

  #user-header .row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #user-header h2 {
    text-align: center;
  }

  #user-header .right-box .top-search {
    margin: 0 auto;
  }

  #help-form .row {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  #help-form h2 {
    font-size: 24px !important;
    padding-bottom: 25px;
  }

  #invite .row {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  #invite .invite-image {
    padding-bottom: 30px;
  }

  #retos .retos-right .result .result-cnt h3 {
    font-size: 30px;
  }

  #retos .retos-right .result .result-cnt h2 {
    font-size: 60px;
  }

  #retos .retos-right .result .result-bar {
    padding-top: 17px;
  }

  #retos .historial {
    max-width: 100%;
    padding: 0 10px;
  }

  #retos .row {
    flex-direction: column-reverse;
  }

  .cnt-wrap2 {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .left-heading2 {
    text-align: center;
    padding-bottom: 10px;
  }

}

@media (max-width: 767px) {

  .RegisterInvest .form-group {
    margin-bottom: 0;
  }

  .RegisterInvest input {
    margin: 0;
  }

  .RegisterInvest {
    justify-content: inherit;
  }

  .Regiter.SearchInvest {
    max-width: 100%;
    width: 100%;
    overflow-x: hidden;
  }

  .mainBody {

    padding: 22px;
  }

  .mainBody .main h5 {
    font-size: 17px;
    letter-spacing: 0px;
  }

  .mainBody .toolBody p {
    font-size: 12px;
  }

  .hero .heroHeadBg h1 {
    padding-left: 120px;
  }

  #newBogBoxWrap .BlogBox {
    height: auto;
  }

  .notiDrop {
    width: auto;
  }

  .personalModal .emem {
    margin: 0;
  }

  .personalModal {
    width: 100%;
  }

  .agriculture_img {
    height: auto;
  }

  .agriculture_head {
    margin-bottom: 30px;
  }

  .subscription_card .text {
    height: 100px;
  }

  .agriculture_head h3 {
    font-size: 40px;
  }

  .agriculture_headtext h4 {
    font-size: 30px;
    margin-bottom: 10px;
    text-align: left !important;
  }

  .agriculture_headtext p {
    font-size: 16px;
  }

  .agriculture_headtext ul li::before {
    left: -25px;
  }

  .agriculture_headtext ul li {
    font-size: 15px;
  }

  .row_reverse {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 636px) {
  .hero .heroHeadBg h1 {
    font-size: 23px;
    letter-spacing: 2px;
  }

  .hero .heroHeadBg h1 {
    padding-left: 100px;
  }

  .tool .toolBody {
    height: 60px;
    width: 75%;
    position: relative;
  }

  .tool .toolOdd {
    margin-left: 60px;
  }

  .tool .toolEven {
    margin-left: 110px;
  }

  .tool .toolBody p {
    font-size: 10px;
    margin-left: 12%
  }

  .mainBody .main h5 {
    font-size: 15px;
    letter-spacing: 0px;
  }

  .mainBody .toolEnd p {
    font-size: 10px;
  }

  .tool .toolBody .dot {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: absolute;
    left: -25px;
    top: 5px;
    padding: 10px 0;
    font-size: 20px;
  }

  .tool .toolBody .grey {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: absolute;
    left: -25px;
    top: 5px;
    padding: 10px 0;
    font-size: 20px;
  }

  .PriceGetNow .main {
    padding: 50px 0;
  }
}

@media (max-width:575px) {

  .starter h4,
  .starter h5 {
    font-size: 16px;
  }

  .PriceGetNow .corner {
    height: 31px;
    width: 42px;
    display: inline-block;
    background-color: rgb(13, 40, 61);
    position: absolute;
    left: -16px;
    top: 27px;
    z-index: -1;
    transform: rotate(45deg);
  }
}

@media (max-width: 567px) {
  .personalModal {
    padding: 19px;
  }

  .PriceGetNow .corner {
    height: 31px;
    width: 42px;
    display: inline-block;
    background-color: rgb(13, 40, 61);
    position: absolute;
    left: -26px;
    top: 16px;
    z-index: -1;
    transform: rotate(45deg);
  }

  .subscription_card .text {
    height: 40px;
  }
}

@media(max-width:560px) {
  .fisibility .total .text {
    margin: 5px 0;
    box-shadow: none;

  }

  .fisibility .list button {
    margin: 5px 0px;
  }

  .fisibility .form-input input {
    margin-bottom: 10px;
  }
}

@media(max-width:575px) {
  #banner-heading .heading-name h2 {
    font-size: 25px;
    text-align: center;
    line-height: 35px;
  }

  #about-us .right-content p {
    font-size: 17px;
    line-height: 27px;
  }

  #about-us .power-by p {
    font-size: 18px;
  }

  #conversation .convo-left .left-content p {
    font-size: 17px;
    line-height: 26px;
  }

  #conversation .convo-right .right-content p {
    font-size: 17px;
    line-height: 26px;
  }

  #conversation .vission {
    padding-top: 20px;
  }

  #restore h3 {
    font-size: 28px;
  }

  #restore .heading-description p {
    font-size: 22px;
    line-height: 30px;
  }

  #restore .inner-container input {
    font-size: 20px;
  }

  #restore .inner-container p {
    font-size: 18px;
    line-height: 25px;
  }

  #create-account .inner-container .form-checkbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
  }

  #create-account .inner-container .check-field {
    margin-right: 0;
    margin-bottom: 10px;
  }

  #create-account .inner-container input {
    font-size: 20px;
  }

  #create-account .inner-container .lock-field input {
    font-size: 20px;
  }

  #create-account .heading h4 {
    font-size: 26px;
  }

  #create-account .inner-container .terms-condition {
    display: flex;
    justify-content: center;
    align-items: center;
  }


}

@media(max-width:480px) {
  #banner-heading .heading-name h2 {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
  }

  #create-account .inner-container .check-field {
    font-size: 18px;
  }

  #otp-box .otpContainer input {
    width: 50px;
    height: 49px;
    margin-right: 10px;
    padding-left: 16px;
  }

  #otp-box .inner-container .link-text p {
    font-size: 14px;
  }

  #paper-top .main-heading h2 {
    font-size: 45px;
  }

  #mng-top h2 {
    font-size: 28px;
  }

  #cipbit-header .header-wrap .header-logo {
    width: 150px;
  }

  #cipbit-header .header-wrap .header-right .calender-logo {

    width: 100px;
  }

  #cipbit-header .header-wrap .header-right .bell-icon {
    position: relative;
    font-size: 25px;
  }

  #retos .retos-right .review-images {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
  }

  #retos .retos-right .review-images .badge-image {
    height: auto;
  }

  #retos .retos-right .review-images .badge-image img {
    height: auto;
  }

  #retos .retos-right .review-images .badge-image2 {
    height: auto;
  }

  #retos .retos-right .review-images .badge-image2 img {
    height: auto;
  }

  #retos .retos-right .review-images .badge-image3 {
    height: auto;
  }

  #retos .retos-right .review-images .badge-image3 img {
    height: auto;
  }

  #retos .retos-right .result {
    padding: 15px 20px;
  }

  #retos .retos-right .result h4 {
    font-size: 20px;
    margin: 0;
  }

  #retos .retos-right .result .result-cnt h3 {
    font-size: 22px !important;
  }

  #retos .retos-right .result .result-cnt h2 {
    font-size: 40px !important;
  }

  #retos .retos-right .result .outstanding {
    font-size: 13px !important;
  }

  #retos .historial p {
    font-size: 16px;
  }

  .managerpagebtn {
    font-size: 17px !important;
  }

  .registrarAnddescargar {
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 468px) {
  .Businesstop {
    padding: 12px 80px;
  }

  .Businesstop a {
    padding: 10px 0px;
  }

  .hero {
    margin-top: 80px;
  }

  .hero .heroHeadBg {
    padding: 20px 0;
  }

  .hero .heroHeadBg h1 {
    padding-left: 60px;
  }

  .hero .heroHeadBg h1 {
    font-size: 20px;
    letter-spacing: 1px;
  }

  .hero .heroHeadBg h4 {
    letter-spacing: 2px;
    font-size: 18px;
  }

  .mainBody .main h5 {
    font-size: 11px;
    letter-spacing: 0px;
  }

  .mainBody .main h3 {
    font-size: 20px;
    letter-spacing: 1px;
  }

  .tool .toolOdd {
    margin-left: 45px;
  }

  .tool .toolEven {
    margin-left: 80px;
  }

  .tool .toolBody p {
    font-size: 9px;
    margin-right: 0;
  }

  .tool .toolBody {
    height: 50px;
    width: 70%;
    position: relative;
  }

  .tool .toolBody .dot {
    height: 40px;
    width: 40px;
    display: inline-block;
    position: absolute;
    left: -20px;
    top: 5px;
    padding: 5px 0;
    font-size: 20px;
  }

  .tool .toolBody .grey {
    height: 40px;
    width: 40px;
    display: inline-block;
    position: absolute;
    left: -20px;
    top: 5px;
    padding: 5px 0;
    font-size: 20px;
  }
}

@media(max-width:375px) {
  #banner-heading .heading-name h2 {
    font-size: 16px;
    text-align: center;
    line-height: 25px;
  }
}

@media(max-width:420px) {
  #cipbit-content .slide-item .slide-content .content-right .cash-btn {
    max-width: 115px;
    font-size: 14px;
  }

  #cipbit-content .slide-item .slide-content .content-right .credit-btn {
    max-width: 115px;
    font-size: 14px;
  }

  #clafication .table-top {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
  }

  #clafication .table-top .top-select {
    max-width: 100%;
  }

  #clafication .table-top .top-right {
    margin-bottom: 10px;
  }

  #clafication .table-top .todo-btn:hover {
    cursor: pointer !important;
  }

  #budget .budget-left label {
    font-size: 22px;
    line-height: 45px;
  }

  #invite h1 {
    font-size: 50px;
  }

  #invite h2 {
    font-size: 30px;
    padding-bottom: 17px;
  }

  @page {
    size: A4 landscape;
  }

  #invite p {
    font-size: 20px;
    line-height: 30px;
  }

  .clear-filter:hover {
    cursor: pointer;
  }

  #invite .invite-social .invite-icon {
    width: 50px;
    height: 50px;
    line-height: 53px;
    font-size: 26px;
    margin-right: 15px;
  }

  .langchange:hover {
    cursor: pointer !important;
  }

  .eliminate-modal .eliminate-body {
    width: 100%;
    padding: 20px;
  }

  .eliminate-cnt p {
    font-size: 17px;
  }

  .ReactModal__Content {
    width: 100% !important;
    /* padding: 2%; */
  }
}

@media(max-width:768px) {

  #cipbit-header .header-wrap .header-logo {
    width: 127px;
  }

  .dropbtn {
    font-size: 19px;
  }

  #cipbit-header .header-wrap .header-right .calender-logo {
    width: 84px;
  }

  .Regiter .heading h1 {
    font-size: 27px;
    margin-bottom: 0;
  }

  #footer-section .footer-content .footer-top {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #footer-section .footer-content .social-links {
    margin-top: 20px;
  }

  #footer-section .footer-content .footer-mid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 30px;
    flex-direction: column;
  }

  #footer-section .footer-content .footer-link ul {
    padding-left: 30px;
  }

  #footer-section .footer-content .footer-login {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
  }

  #footer-section .footer-content .footer-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  #login_banner_area .banner-second-heading h2 {
    font-size: 35px;
    padding-top: 40px;
  }

  #login_banner_area .banner-top-heading h4 {
    font-size: 20px;
  }

  #login_banner_area .banner-btn {
    padding-top: 38px;
  }

  #new-pass .pass-form .new-pass-btn .pass-btn {
    margin: 0;
  }

  #read-section .blog-slider .slick-prev:before {
    font-size: 50px !important;
  }

  #read-section .blog-slider .slick-next:before {
    font-size: 50px !important;
  }

  #cipbit-content .slick-prev:before {
    font-size: 50px !important;
  }

  #cipbit-content .slick-next:before {
    font-size: 50px !important;
  }

  #blog-image .inner-container .mid-content {
    margin-top: 20px;
  }

  #blog-image .inner-container .inner-content .mid-logo {
    width: 100%;
    margin-bottom: 20px;
  }

  #otp-box .otp-heading h4 {
    font-size: 24px;
    padding-bottom: 14px;
  }

  #otp-box .otp-description p {
    font-size: 18px;
    line-height: 26px;
  }

  #otp-box .otp-description {
    width: 100%;
  }

  #about-us .top-heading {
    height: auto;
  }

  .circum-section {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .circum-section .month1-btn {

    margin-bottom: 10px;
  }

  .circum-section .month2-btn {
    width: 100%;
    margin-bottom: 10px;
  }

  .circum-section .month3-btn {
    width: 100%;
    margin-bottom: 10px;
  }

}

@media(max-width:520px) {
  #blog-image .inner-container .inner-content .play-store {
    width: 100px;
  }

  #blog-image .inner-container .inner-content .play-store img {
    width: 100px;
  }

  #blog-image .inner-container .inner-content .ios {
    width: 100px;
  }

  #blog-image .inner-container .inner-content .ios img {
    width: 100px;
  }

  /* #managebtn .btn-wrap .bill-btn {
    max-width: 110px!important;
    width: 100%;
    height: 30px;
    font-size: 14px;
} */

  #managebtn .btn-wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #managebtn .btn-wrap .bill-btn {
    max-width: 100% !important;
    margin-bottom: 10px;
  }

  #budget .budget-left h2 {
    font-size: 26px;
    line-height: 40px;
  }

  #retos .retos-right .result .result-cnt h3 {
    font-size: 26px;
  }

  #retos .retos-right .result .result-cnt h2 {
    font-size: 50px;
  }

  #retos .retos-right .result .result-bar {
    padding-top: 5px;
  }

  #retos .retos-right .result .outstanding {
    font-size: 16px;
  }

  #retos .historial .historical-btm {
    padding: 20px;
  }

}

@media(max-width:650px) {
  #read-section .blog-slider .slick-prev:before {
    display: none;
  }

  #read-section .blog-slider .slick-next:before {
    display: none;
  }

  #cipbit-content .slick-prev:before {
    display: none;
  }

  #cipbit-content .slick-next:before {
    display: none;
  }
}

@media(max-width:540px) {
  #login_banner_area .banner-second-heading h2 {
    font-size: 25px;
    padding-top: 25px;
  }

  #login_banner_area .banner-top-heading h4 {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .agriculture_head {
    margin-bottom: 20px;
  }
}

@media(max-width:395px) {
  #login_banner_area .banner-second-heading h2 {
    font-size: 20px;
    padding-top: 20px;
  }

  #login_banner_area .banner-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
  }

  #login_banner_area .banner-btn .negocius-btn {
    margin-bottom: 10px;
    margin-right: 0;
  }

  #login_banner_area .banner-btn .personal-btn {
    margin: 0;
  }

  #bill-cnt .bill-cnt .top-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #bill-cnt .bill-cnt .monthly-btn {
    margin-left: 0;
    margin-top: 10px;
  }

  #budget .budget-left h2 {
    font-size: 22px;
    line-height: 20px;
  }

  #cipbit-header .header-wrap .header-logo {
    width: 125px;
  }

  #cipbit-header .header-wrap .header-right .calender-logo {
    width: 85px;
  }

  #cipbit-header .header-wrap .header-right .bell-icon {
    font-size: 22px;
    padding-left: 14px;
  }
}

@media(max-width:360px) {
  #resgister-section .register_content h3 {
    font-size: 25px;
  }

  #social-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

}



@media(max-width:580px) {
  .td-details {
    justify-content: center !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    flex-direction: column !important;
  }

  .td-details label {
    width: 100% !important;
    text-align: center !important;
  }

  .custom-wrap {
    justify-content: center !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    flex-direction: column !important;
  }

  .custom-wrap input {
    width: 100% !important;
  }

  .td-buttons {
    margin-left: 0;
    margin-top: 10px;
  }

  .td-top label {
    width: 100% !important;
    text-align: center !important;
  }

  .td-top input {
    width: 100%;
  }

  .custom-wrap {
    width: 100% !important;
  }
}


.removemenu {
  display: none !important;
}

input[type=text],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #130404;
  border-radius: 4px;
  box-sizing: border-box;
}

/* .customBtn {
  max-width: 100px;
  height: 30px;
  padding: 7px 12px;
  margin: 0 20px 0 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background: linear-gradient(to right, #18465e 1%, #14384d 51%, #0f273a 100%);
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  color: #fcf6f6;
  transition: 0.8s;
} */
.customBtn2 {
  width: 100%;
  max-width: 100px;
  height: 30px;
  margin: 0px;
  padding: 7px 12px;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  transition: 0.8s;
  background: #8e9eab !important;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #eef2f3, #8e9eab) !important;
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #eef2f3, #8e9eab) !important;
  color: black !important;
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}



.icon:hover {
  cursor: pointer;
}


.customBtn3 {
  width: 120px;
  height: auto;
  padding: 8px 16px;
  display: flex;
  margin-right: 20px;
  border-radius: 10px;
  justify-content: center;
  border: none;
  color: #f7f1f1;
  background: #4b6cb7;
  background: -webkit-linear-gradient(to right, #194860, #0f2739);
  background: linear-gradient(to right, #194860, #0f2739);
  margin-top: 25px;
  margin-bottom: 15px;
}

.customBtn10 {
  /* height: auto; */
  height: 45px;
  padding: 8px 16px;
  display: flex;
  border-radius: 10px;
  line-height: 20px;
  justify-content: center;
  border: none;
  color: #f7f1f1;
  background: #4b6cb7;
  background: -webkit-linear-gradient(to right, #194860, #0f2739);
  background: linear-gradient(to right, #194860, #0f2739);
  /* width: auto; */
  width: 58px;
}

.td-buttons .customBtn10:focus {
  width: 58px !important;
  height: 40px !important;
  line-height: 13px !important;
}

.customBtn10:disabled {
  background: #4b6bb77a;
  background: -webkit-linear-gradient(to right, #1948608c, #0f273985);
  background: linear-gradient(to right, #1948608e, #0f273993);
}

.customBtn3:disabled {
  background: #4b6bb77a;
  background: -webkit-linear-gradient(to right, #1948608c, #0f273985);
  background: linear-gradient(to right, #1948608e, #0f273993);
}



input[type=text],
input[type=number],
input[type=password],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #130404;
  border-radius: 4px;
  box-sizing: border-box;
}

.customBtn {
  width: 100%;
  max-width: 300px;
  height: auto;
  padding: 7px 12px;
  margin: 20px 0 0 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background: linear-gradient(to right, #18465e 1%, #14384d 51%, #0f273a 100%);
  border: none;
  /* border-radius: 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  color: #fcf6f6;
  transition: 0.8s;
}

.customBtn2 {
  width: 100%;
  max-width: 100px;
  height: 30px;
  margin: 0px;
  padding: 7px 12px;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  transition: 0.8s;
  background: #8e9eab !important;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #eef2f3, #8e9eab) !important;
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #eef2f3, #8e9eab) !important;
  color: black !important;
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.income-confirm-data {
  margin-top: 10px;
}

.income-confirm-data span {
  padding: 10px;
  background: lightgray;
  margin-bottom: 5px;
}

.customBtn4 {
  background: linear-gradient(to right, #18465e 1%, #14384d 51%, #0f273a 100%);
  border: none;
  color: #fff;
  padding: 5px 15% 5px 15%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
  width: "100px",
}

.customBtn5 {
  background: #585c58;
  border: none;
  color: #fff;
  padding: 5px 15% 5px 15%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
  width: "100px",
}

svg:hover {
  cursor: pointer;
}

input[type="date"] {
  background-color: #ffffff;
  padding: 12px;
  font-family: "Roboto Mono", monospace;
  color: #0f0505;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 5px;
  border: 2px solid #bfbfbf
}

::-webkit-calendar-picker-indicator {
  background-color: #ffffff;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
}

.td-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
}

.offcanvas-body::-webkit-scrollbar {
  display: none;
}

.form-switch .form-check-input {
  width: 3em !important;
  height: 10px !important;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position .15s ease-in-out;
}

.form-switch .form-check-input:checked {
  background-color: #666262;
  border: none;
  box-shadow: none;
}

.ReactModal__Content::-webkit-scrollbar {
  display: none;
}

.chart-slide {
  position: relative;
}

.chart-slide .slick-prev,
.chart-slide .slick-next {
  z-index: 2;
}

.chart-slide .slick-next:before {
  content: "\f054";
  font-family: FontAwesome;
  font-size: 35px;
  line-height: 1;
  opacity: .75;
  color: #000;
  -webkit-font-smoothing: antialiased;
}

.chart-slide .slick-prev:before {
  content: "\f053";
  font-family: FontAwesome;
  font-size: 35px;
  line-height: 1;
  opacity: .75;
  color: #000;
  -webkit-font-smoothing: antialiased;
}

.chart-slide .slick-disabled {
  display: none !important;
}

/* .chart-slide .slick-next{
  width: 80px !important;
  height: 60px !important;
} */


/***ConfirmMessag****/
#ConfirmMessage {
  padding: 70px 0;
}

#ConfirmMessage .wraper {
  background-color: #0E2437;
  padding: 2%;
  color: #fff;
  border-radius: 5px;
}

#ConfirmMessage .wraper h3 {
  font-size: 2.5rem;
  font-weight: 400;
  border-bottom: 2px solid #fff;
  padding-bottom: 6px;
}

#ConfirmMessage .wraper input {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  padding: 5px 10px;
}

#ConfirmMessage .wraper h4 {
  line-height: normal;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-align: right;
  padding: 0;
  margin: 0;
}

#ConfirmMessage .wraper h5 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  /* border: 1px solid #fff; */
  padding: 5px 10px;
}

#ConfirmMessage .wraper.wraper2 h5 {
  margin-bottom: 0;
  font-size: 16px;
}

#ConfirmMessage .wraper .rgtBox h4 {
  margin-bottom: 36px;
}

#ConfirmMessage .wraper .rigtBox h5 {
  margin-bottom: 40px;
}

#ConfirmMessage .wraper .btnPart {
  display: flex;
  justify-content: end;
}

#ConfirmMessage .wraper .btnPart a {
  padding: 10px 20px;
  color: #212529;
  font-size: 18px;
  border-radius: 5px;
  text-align: center;
  background-color: #ffff;
  margin-right: 20px;
  font-weight: 600;
}

@media(max-width:767px) {
  .Regiter label {
    padding-top: 0;
    padding-bottom: 0;
  }

  .index3 .heading {
    padding: 30px;
  }

  .index2 .value {
    padding: 30px;
  }

  .index2 .image img {
    height: 187px;
  }

}

.ReactModal__Content span {
  font-size: 1.1rem;
  /* text-decoration: underline; */
  /* text-underline-offset: 8px; */
  margin-top: 10px;
  padding: 10px;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

/* * {
  cursor:default
} */

.customer_name_button_revenew {
  height: 42px !important;
  position: relative;
  top: 6px;
  width: 59px;
  margin-left: 2px !important;

  border-radius: 5px !important;
}

.search_name {
  background-color: #d9dcdf;
  position: relative;
  bottom: 8px;
  padding: 5px 8px;
  width: 80%;
}

#filterbtn {
  cursor: pointer;
}


.modalImageContainer {
  position: relative;
  max-width: 220px;
}

.blueText {
  font-weight: 500;
  color: #0E2437;
}

.bigText {
  font-weight: 700;
  font-size: 20px;
}

@media (max-width:1199px) {
  #ConfirmMessage .wraper .rgtBox h4 {
    margin-bottom: 34px;
  }

  #ConfirmMessage .wraper .rigtBox h5 {
    margin-bottom: 37px;
  }
}

@media (max-width: 768px) {
  .Regiter .register .return {
    border-radius: 999px;
    font-size: 14px;
    line-height: 1.5;
    padding: 3px 1rem;
    outline: none;
    border: 0;
    background-color: gray;
    color: #fff;
  }

  .Regiter .register .confirm button {
    display: block;
    margin-left: auto;
    background-color: #0E2437;
    border: none;
    padding: 3px 1rem;
    font-size: 14px;
    color: white;
    border-radius: 999px;
    white-space: nowrap;
  }
}

@media (max-width: 375px) {
  .dropbtn {
    font-size: 12px;
  }

  #cipbit-header .header-wrap .header-logo img {
    max-width: 80px;
  }


  #cipbit-header .header-wrap .header-right .calender-logo {
    width: 50px;
  }

  #cipbit-header .header-wrap .header-right .setting-icon {

    font-size: 20px;
    padding-left: 15px;

  }

  .registerButton img {
    width: 50%;
  }
}


@media (max-width: 425px) {
  .dropbtn {
    font-size: 12px;
  }

  #cipbit-header .header-wrap .header-logo img {
    max-width: 80px;
  }


  #cipbit-header .header-wrap .header-right .calender-logo {
    width: 50px;
  }

  #cipbit-header .header-wrap .header-right .setting-icon {

    font-size: 20px;
    padding-left: 15px;

  }

  .modalImageContainer {
    max-width: 170px;
  }

  .registerButton {
    width: 200px;
  }

  .registerButton h2 {
    font-size: 16px;
  }

  .bigText {
    font-size: 12px;
  }

  .blueText {
    font-size: 10px;
  }

}

@media (max-width:480px) {
  #ConfirmMessage .wraper h5 {
    font-size: 12px;
  }

  #ConfirmMessage .wraper h4 {
    font-size: 13px;
  }

  #ConfirmMessage .wraper .rigtBox h5 {
    margin-bottom: 35px;
  }

  #ConfirmMessage .wraper input {
    margin: 5px 0;
    padding: 6px 20px;
  }

  #ConfirmMessage .wraper .btnPart a {
    padding: 7px 10px;
    margin-right: 8px;
    font-weight: 600;
  }

}

#cipbit-header .header-wrap .header-right .bell-icon {
  background-color: transparent;
  border: none;
  font-size: 30px;
  color: #0e2537;
  padding-left: 0;
  margin-left: 10px !important;
  outline: 0;
  box-shadow: none;
  margin: 0;
}



.sidebar-icon {
  color: #0E2537
}

.view-report h1 {
  display: flex;
  justify-content: left;
  margin-left: 5px;
}

.report-radio {
  display: flex;
  justify-content: space-evenly;
  margin: 38px 0;
  background: #bbbfcd59;
}

.report-radio label {
  margin-left: 8px;
  margin-bottom: 2px;
  color: #979b9f;
  font-size: 20px;
  font-weight: 500;
}

/* .report-radio input[type='radio'] {
  width: 20px;
  height: 20px;
  visibility: hidden;
  position: absolute;
} */
/* .report-radio .check{
  display: block;
  position: absolute;
  border: 4px solid #AAAAAA;
  border-radius: 100%;
  height: 27px;
  width: 27px;
  top: 0;
  left: -5px;
	z-index: 5;
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
}
.report-radio .check::before {
  display: block;
  position: absolute;
	content: '';
  border-radius: 100%;
  height: 12px;
  width: 12px;
  top: 3px;
	left: 3px;
  margin: auto;
	
}
input[type=radio]:checked ~ .check {
  border: 5px solid #0DFF92;
}

input[type=radio]:checked ~ .check::before{
  background: #0DFF92;
}
input[type=radio]:checked ~ label{
  color: #0d2537;
} */


.report-radio .btnn {
  color: #AAAAAA;
  display: block;
  position: relative;
  float: left;
  cursor: pointer;
}



.report-radio input[type=radio] {
  position: absolute;
  visibility: hidden;
}

.report-radio label {
  display: block;
  position: relative;
  margin-left: 10px;
  height: 30px;
  z-index: 1;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  line-height: 38px;
  font-weight: 600;
}



.check {
  display: block;
  position: absolute;
  border: 2px solid #AAAAAA;
  /* border-radius: 100%; */
  height: 18px;
  width: 18px;
  top: 7px;
  left: 0;
  z-index: 5;
  transition: border .25s linear;
  -webkit-transition: border .25s linear;
}



.check::before {
  /* display: block;
  position: absolute;
	content: '';
  border-radius: 100%;
  height: 15px;
  width: 15px;
  top: 5px;
	left: 5px;
  margin: auto; */


}

.report-radio input[type=radio]:checked~.check {
  border: 1px solid #0d2537;
  background-color: #0d2537;
}

/* .report-radio input[type=radio]:checked ~ .check::before{
  background: #0DFF92;
} */

.report-radio input[type=radio]:checked~label {
  color: #0d2537;
}

/* .add-user-btn {
  margin: 4px 0;
} */

.add-user-btn button {
  background-color: #0E2537;
  color: #fff;
  margin-bottom: 18px;
}

.add-user-btn button:hover {
  background-color: #61798b;
  color: #fff;
}

.add-user-btn button:focus {
  background-color: #0E2537;
  color: #fff;
}

.report-selector {
  margin: 5px 0;
}

.report-radio-label {
  position: relative;
  bottom: 5px;
}

.report-td p {
  margin: 5px 0;
  padding: 0;
  text-align: end;
  font-size: 13px;
  font-weight: 300;
}

.gen-fin-table {
  text-align: center;
}

.report-date-input {
  width: 100%;
  height: 38px;
  position: relative;
  bottom: 7px;
}

.reprtBtnGrp {
  background: linear-gradient(to right, #194860, #0f2739);
  color: #fff !important;
  border: 0;
  text-align: center;
  background-clip: inherit !important;
  appearance: auto !important;
  padding: 5px 4px !important;
}

.report-date-input input {
  background: linear-gradient(to right, #194860, #0f2739);
  color: #fff !important;
  border: 0;
  text-align: center;
}

.rprtQury h4 {
  background: #bbbfcd59;
  padding: 9px 30px;
  font-size: 18px;
  margin-bottom: 3px;
  color: #0d2537;
}

.rprtQury h4 span {
  color: #979b9f;
  font-weight: 600;
}

.report-selector .dropdown-menu.show {
  top: 8px !important;
}

.report-selector .dropdown-menu.show a {
  color: #0d2537;
  font-size: 14px;
  cursor: pointer;
}

.report-selector .dropdown-menu.show a:hover {
  font-size: 15px;
  font-weight: bold;
}

.gen-fin-table thead th,
.gen-fin-table tbody td {
  background: #bbbfcd59;
  border-right: 2px solid #fff;
  border-bottom: 0;
  --bs-table-accent-bg: #bbbfcd59 !important;
  border-top: 0;
  white-space: nowrap;

}

.gen-fin-table {
  overflow-x: auto;
  margin-bottom: 5px !important;
}

.gen-fin-table tbody td {
  padding: 4px 10px;
  text-align: center;
}

.gen-fin-table tbody tr {
  border-bottom: 2px solid #fff;
}

.gen-fin-table thead th:nth-child(1) {
  border-right: 0;
  background-color: transparent;
  --bs-table-accent-bg: transparent !important;
}

.gen-fin-table th,
.gen-fin-table td {
  color: #0d2537;
  font-weight: 400;
  font-size: 14px;
}

.report-select select {
  width: 50%;
  margin: 100px auto;
  background: linear-gradient(to right, #cfd3d5, #abc0cf);
  height: 55px;
  font-size: 20px;
  font-weight: 400;
}

.report-first-column {
  width: 18%;
  text-align: end !important;
}

/* report pagination */
.report-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
}

.report-pagination input {
  width: 42px;
  border-radius: 11px;
  margin: 0 2px;
  text-align: center;
}

.report-pagination button {
  padding: 3px 10px;
  /* background-color: lightblue; */
  border-radius: 7px;
  background: linear-gradient(to right, #cfd3d5, #abc0cf);
  margin: 0 3px;
}

.page-no-report {
  background: linear-gradient(to right, #cfd3d5, #abc0cf);
  line-height: 21px;
  height: 23px;
  padding: 2px 8px;
}

.weekly-sched-box {
  box-shadow: 0px 0px 4px 0px;
  padding: 7px;
  margin-top: 31px;
  text-align: center;
  position: absolute;
  background: #fff;
  max-width: 190px;
  width: 100%;
}

.weekly-sched-box p {
  cursor: pointer;
  margin: 1px;
}

.weekly-sched-box p:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
  font-weight: 500
}

.monthly-sched-box {
  box-shadow: 0px 0px 4px 0px;
  padding: 7px;
  margin-top: 31px;
  text-align: center;
  position: absolute;
  background: #fff;
  max-width: 190px;
  width: 100%;
  overflow-y: scroll;
  height: 181px;
}

.monthly-sched-box p {
  cursor: pointer;
  margin: 1px;
}

.monthly-sched-box p:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
  font-weight: 500
}

/* .monthly-sched-box::-webkit-scrollbar {
  display: none;
}

.monthly-sched-box {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

.search-result-arrow {
  display: flex;
  justify-content: end;
  margin: 30px 0;
  font-size: 30px;
}

.gen-fin-table_tran thead th,
.gen-fin-table_tran tbody td {
  background: #bbbfcd59;
  border-right: 2px solid #fff;
  border-bottom: 0;
  --bs-table-accent-bg: #bbbfcd59 !important;
  border-top: 0;
  white-space: nowrap;
  text-align: center;
}

.loader-container {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* height: 100vh;  */
  /* Adjust the height according to your requirements */
}

.loader_fis {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 10;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.myModal_fis {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 5;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: #f7f1f1;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.myModal_fis {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.container_strpe {
  background-color: #0E2537;
  padding: 20px 20px 15px 20px;
  /* border: 1px solid lightgrey; */
  border-radius: 3px;
}

.container_strpe input[type=text] {
  width: 100%;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.container_strpe label {
  margin-bottom: 0px;
  display: block;
  color: #fff;
  font-size: 14px;
}

.btn_stripe {
  /* background-color: #fff; */
  color: white;
  padding: 8px;
  margin: 10px 0;
  border: none;
  width: 65px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 17px;
  text-align: center;
}

.btn_stripe:hover {
  background-color: #173b57;
}

.btn_stripe:disabled {
  background-color: #173b57;
}

.Stripe_card_Time {
  display: flex;
  justify-content: space-between;
}

.Stripe_card_Time div {
  margin: 2px;
  /* width: 100px; */
}

.main-noti-dot {
  position: absolute;
  right: -5px;
  bottom: 4px;
  color: red;
  font-size: 35px;
}

.daily-red-dot {
  color: red;
  position: absolute;
  right: 57px;
  top: 0px;
  font-size: 25px;
}

.budget-red-dot {
  color: red;
  position: absolute;
  right: 78px;
  top: 24px;
  font-size: 25px;
}

.payment-red-dot {
  color: red;
  position: absolute;
  right: 30px;
  top: 48px;
  font-size: 25px;
}

.back-arrow-left {
  font-size: 44px;
}

#add-user-modal {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

#add-user-modal .modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  /* height: 783px; */
}

#add-user-modal .modal-content .close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  display: flex;
  justify-content: end;
  cursor: pointer
}

/* Invoice2 Form */
.creNewHead {
  font-size: 25px;
  color: #0e2537;
  font-weight: 600;
  margin: 0 0 15px 0;
  text-align: center;
}

.myFisiTab {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
}

.myDivFisiTab {
  width: 250px;
  border: 1px solid #00000029;
  padding: 5px 0;
  border-radius: 3px;
  background-color: #fff;
  position: absolute;
  top: 47px;
  left: 12px;
}

.myFisiTab {
  margin-bottom: 0;
}

.myFisiTab .react-tabs__tab {
  position: static;
  padding: 3px 10px;
  border: 0;
  font-size: 13px;
  font-weight: 700;
  color: #58422f;
}

.myFisiTab .react-tabs__tab--selected {
  background-color: #e0e0e6;
  border: 0;
  border-radius: 0;
}

.myOwnFisiContainer {
  position: relative;
}

.tabFisiContainer {
  margin-top: 155px;
}

.invoiceFormTwoSec {
  margin-bottom: 25px;
}

.sendInfoFirstFormDiv {
  display: flex;
  justify-content: space-between;
}

.sendInfoSubDiv {
  width: 49%;
}

.sendInnerSubDiv {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.sendFisiInp {
  width: 75% !important;
  padding: 5px 15px !important;
  border: 1px solid #012b52 !important;
  border-radius: 4px;
  outline: none;
  margin: 0 !important;
}

.sendFisiLabel {
  display: inline-block;
  width: 25%;
  color: #000;
  font-weight: 700;
  font-size: 15px;
}

.sendInfoHead {
  font-size: 20px;
  color: #000000;
  font-weight: bolder;
  margin: 0;
}

.sendInfoSecondFormDiv {
  margin-top: 20px;
}

.secInfoSubDiv {
  display: flex;
  align-items: center;
}

.inoDetPara {
  font-size: 20px;
  font-weight: 700;
  margin: 0 25px 0 0;
}

.infoDetSubLabel {
  font-size: 17px;
  font-weight: 600;
  margin-left: 5px;
  margin-bottom: 0;
}

.infoDetSubRadio {
  accent-color: #000;
}

.sendInfoThirdFirstFormDiv {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.sendInfoThirdFormDiv {
  margin-top: 15px;
}

.thrFirstDiv {
  width: 72%;
}

.thrSecondDiv {
  width: 26%;
}

.invoTableTD {
  padding: 0 3px;
}

.cnrSecondDiv .invoTableTD {
  width: 14.28%;
}

.invoTableTH {
  text-align: center;
  position: relative;
}

.invoTableTH>span {
  font-size: 13px;
  font-weight: bolder;
  color: #000000;
}

.invoTHbtn {
  background-color: #012b52;
  color: #fff;
  padding: 0 10px;
  border: 0;
  border-radius: 6px;
  font-size: 14px;
  position: absolute;
  top: 3px;
  left: 7px;
}

.invoTableTDinp {
  border-radius: 0 !important;
  padding: 6px 5px !important;
  border: 2px solid #a4a6a6 !important;
  box-shadow: 0 0 5px 0 #615c5c3b;
  margin: 8px 0 0 0 !important;
}

.invoTableTDinp::-webkit-outer-spin-button,
.invoTableTDinp::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.thrSecSubDiv {
  display: flex;
  align-items: center;
}

.thrSecSubLabel {
  display: inline-block;
  width: 40%;
  font-size: 15px;
  font-weight: 700;
}

.thrSecSubInp {
  width: 60% !important;
  padding: 5px 7px !important;
  margin: 6px 0 !important;
  border-radius: 4px !important;
  border: 2px solid #a4a6a6 !important;
}

.thrSecSubInp::-webkit-outer-spin-button,
.thrSecSubInp::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.sendBtnDiv {
  text-align: center;
  margin-top: 10px;
}

.sendFisiBtn {
  background-color: #012b52;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  padding: 5px 25px;
  border: 0;
  border-radius: 7px;
}

.plusConDiv {
  width: 15%;
}

.entInpDiv {
  width: 85%;
}

.entInpDiv input {
  width: 100%;
  padding: 5px 15px !important;
  border: 1px solid #012b52 !important;
  border-radius: 4px;
  outline: none;
}

.concepBtn {
  background-color: #012b52;
  color: #fff;
  padding: 0 10px;
  border: 0;
  border-radius: 6px;
  font-size: 14px;
  margin-right: 10px;
}

.addConcepBtnTxt {
  font-size: 16px;
  color: rgb(13, 38, 55);
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
}

.addConcepBtnDiv {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.concepLabel {
  color: #000;
  font-weight: 700;
  font-size: 15px;
}

.cnrFirstDiv {
  width: 100%;
  overflow-x: auto;
}

.cnrFirstDiv::-webkit-scrollbar {
  display: none;
}

.cnrSecondDiv {
  width: 100%;
  overflow-x: auto;
}

.cnrSecondDiv>table {
  width: 100%;
}

.cnrSecondDiv::-webkit-scrollbar {
  display: none;
}

.cnrFirstDiv>table {
  width: 100%;
}

.tableAfterDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.tableInnerAfterDiv {
  width: 100%;
}

.subTabDiv {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.subLabelTab {
  display: inline-block;
  width: 28%;
  border: 2px solid blue;
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  border: 0;
}

.subInSeDiv {
  width: 72%;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.subInputTab {
  border: 2px solid #a4a6a6 !important;
  padding: 5px 7px !important;
  max-width: 170px;
  width: 100%;
  margin: 0 !important;
}

.ivSpan {
  width: 70px;
  font-size: 15px;
  font-weight: 700;
  margin-right: 15px;
}

.invoTableTDSecInp {
  border-radius: 0 !important;
  padding: 6px 5px !important;
  border: 2px solid #a4a6a6 !important;
  box-shadow: 0 0 5px 0 #615c5c3b;
  margin: 8px 0 0 0 !important;
}


#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

.button-text {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.payment-form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  margin: 50px auto;

}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

.payment-success {
  text-align: center;
  background-color: #f5f5f5;
  padding: 20px;
}

.gif-container {
  margin-top: 20px;
}

.requHead {
  font-size: 14px;
  text-align: center;
  color: rgb(12, 37, 55);
  font-weight: bold;
  margin: 0 0 5px 0;
}

.requFileText {
  font-size: 13px;
  color: #000;
  font-weight: 500;
  margin: 0 0 4px 0;
}

.requTextDiv {
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.requTextMainDiv {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.requInnerDiv {
  width: 100%;
  position: relative;
}

.reqUplBtn {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgb(23, 65, 88);
  border: 0;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
  padding: 3px 0;
  cursor: pointer;
}

.requInnerInp {
  width: 100%;
  position: relative;
  z-index: 2;
  background-color: transparent;
  opacity: 0;
}

.keyInpDiv {
  width: 100%;
}

.keyInpDiv input {
  width: 100%;
  font-size: 12px;
  border: 1px solid rgb(153, 153, 153);
  border-radius: 4px;
  padding: 2px 5px !important;
  text-align: center;
  margin: 0 0 2px 0;
}

.requSaveBtn {
  width: 100%;
  background-color: rgb(23, 65, 88);
  font-size: 13px;
  text-transform: uppercase;
  border: 0;
  color: #fff;
  padding: 3px 0;
  cursor: pointer;
  border-radius: 4px;
}

.requSaveBtnDiv {
  width: 100%;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}

/* payment end */


/* Demo text color */
.demoTextClr {
  color: #ffffff;
}

@media (max-width: 1399px) {
  .invoTHbtn {
    font-size: 13px;
  }
}

@media (max-width: 1199px) {
  .invoTHbtn {
    padding: 0 8px;
    font-size: 12px;
    left: 3px;
  }

  .invoTableTH>span {
    font-size: 12px;
  }
}

@media (max-width: 1023px) {
  .sendInfoFirstFormDiv {
    display: block;
  }

  .sendInfoSubDiv {
    width: 100%;
    margin-bottom: 25px;
  }

  .sendFisiInp {
    width: 100% !important;
  }

  .sendInfoThirdFirstFormDiv {
    display: block;
  }

  .thrFirstDiv {
    width: 100%;
    margin-bottom: 18px;
  }

  .thrSecondDiv {
    width: 100%;
  }

  .thrSecSubLabel {
    width: 18%;
  }

  .thrSecSubInp {
    width: 100% !important;
  }

  .myDivFisiTab {
    width: 220px;
  }
}

@media (max-width: 991px) {
  .invoTableTDSecInp {
    min-width: 132px;
  }

  .sendFisiLabel {
    width: 27%;
  }
}

@media (max-width: 767px) {
  .thrFirstDiv {
    overflow-x: auto;
  }

  .thrFirstDiv::-webkit-scrollbar {
    display: none;
  }

  .invoTableTDinp {
    min-width: 132px;
  }

  .sendInnerSubDiv {
    display: block;
    margin-bottom: 8px;
  }

  .sendFisiLabel {
    width: 100%;
    margin-bottom: 5px;
  }

  .sendInfoHead {
    margin-bottom: 8px;
  }

  .thrSecSubDiv {
    display: block;
    margin-bottom: 15px;
  }

  .thrSecSubLabel {
    width: 100%;
    margin: 0;
  }

  .descSpan {
    margin-left: 10px;
  }

  .myDivFisiTab {
    position: static;
    width: 100%;
    margin-bottom: 15px;
  }

  .tabFisiContainer {
    margin: 0;
  }

  .tableAfterDiv {
    display: block;
  }

  .subTabDiv {
    display: block;
  }

  .subInSeDiv {
    padding: 0;
    width: 100%;
  }

  .subInputTab {
    max-width: 100%;
  }

  .subLabelTab {
    width: 100%;
    margin-bottom: 5px;
  }

  .ivSpan {
    width: 90px;
    margin: 0;
    text-align: center;
  }

  .secInfoSubDiv {
    display: block;
  }

  .plusConDiv {
    width: 100%;
  }

  .entInpDiv {
    width: 100%;
  }

  .ibAfterSpanDiv {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .creNewHead {
    font-size: 22px;
  }

  .inoDetPara {
    font-size: 17px;
    margin: 0px 12px 0 0;
  }

  .infoDetSubLabel {
    font-size: 16px;
  }
}

/* Integration manager part */
.bankSelect {
  max-width: 500px;
  width: 100%;
  padding: 7px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #b9c7d1;
  border: 0;
  color: #212529;
  font-weight: 500;
}

.bankSelectDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.bankSelOption {
  font-weight: 500;
}

.pleAccDet {
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  color: #0e2537;
  text-align: center;
  margin: 0 0 10px 0;
}

.fisiFormDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.inteFormLabel {
  display: inline-block;
  width: 25%;
  color: #0e2537;
  font-size: 16px;
  font-weight: 500;
}

.inteFormInput {
  display: inline-block;
  width: 100%;
  padding: 7px 12px !important;
  background-color: #fff !important;
  border: 1px solid #3a393987 !important;
  outline: none !important;
}

.inteFormInput[type="number"]::-webkit-outer-spin-button,
.inteFormInput[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.bankStatusDiv {
  width: 100%;
  max-height: 85%;
  height: 100%;
  border: 1px solid #3a393987;
  padding: 10px;
  overflow-y: auto;
}

.bankStatusDiv::-webkit-scrollbar {
  display: none;
}

.bankStatusUl {
  padding: 0;
}

.bankStatusLi {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.bankStatusSpan {
  font-size: 17px;
  color: #7e8485;
  font-weight: 500;
}

.bankStatusBtn {
  background-color: #fff;
  border: 0;
  font-size: 17px;
  font-weight: 500;
  text-decoration: underline;
  color: #2a6099;
}

.integrationSection {
  margin-bottom: 40px;
}

.inteSaveConBtn {
  display: inline-block;
  background-color: #0e2537;
  border: 0;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  padding: 5px 14px;
  border-radius: 8px;
}

.inteSaveBtnDiv {
  text-align: center;
  margin-bottom: 7px;
}

.inreCheckDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chekLabel {
  margin: 0 0 0 7px;
  font-size: 15px;
  font-style: italic;
  font-weight: 600;
  color: #999999;
}

.clearFillDiv {
  position: relative;
  display: flex;
  gap: 10px;
  justify-content: end;
}

.barScreenShotBtn {
  background-color: transparent;
  border: 0;
  /* position: absolute;
  right: 0; */
  font-size: 22px;
  color: #112f43;
}

.clearFilterBtn {
  background-color: transparent;
  border: 0;
  font-size: 16px;
  color: #220000;
  font-weight: 700;
  /* position: absolute;
  right: 250px; */
}

.dateSearDiv {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashSixDateInp {
  padding: 3px 8px !important;
  margin-right: 15px;
  font-size: 16px;
  border: 1px solid #6d6c6c94 !important;
}

.dashSixSearchInp {
  padding: 5px 8px !important;
  font-size: 16px;
  border: 1px solid #6d6c6c94 !important;
  border-radius: 5px;
  width: 400px;
}

.totalCalInp {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.totalAvaText {
  font-size: 21px;
  color: #0e2537;
  font-weight: bolder;
  margin: 0;
}

.totalDashSixInp {
  max-width: 550px;
  width: 100%;
  padding: 5px 12px !important;
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  color: #0e2537;
  border: 1px solid #302e2eb8 !important;
}

.dashSixTable {
  width: 100%;
}

.dashSixTableHead {
  text-align: center;
  color: #0e2537;
  font-size: 17px;
}

.dashSixTableData {
  text-align: center;
  font-size: 17px;
  color: #0e2537;
  font-weight: 500;
  padding: 8px 0;
}

.dashSixTableRow {
  background-color: #e9ecef;
  border-top: 5px solid #fff;
}

.dashSixTableDiv {
  margin-top: 20px;
}

.dashSixTableDiv::-webkit-scrollbar {
  display: none;
}

.dashSixTableConcludeData {
  color: #0e2537;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding-top: 12px;
}

.dashSixTableFoot {
  border-top: 2px solid #0e2537;
}

@media (max-width: 1399px) {
  .inteFormLabel {
    width: 30%;
  }
}

@media (max-width: 1199px) {
  .bankStatusSpan {
    font-size: 15px;
  }

  .bankStatusBtn {
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  .bankStatusDiv {
    height: auto !important;
  }

  .dashSixTableDiv {
    overflow-x: auto;
  }

  .dashSixTable {
    min-width: 992px;
  }
}

@media (max-width: 767px) {
  .fisiFormDiv {
    display: block;
    margin-bottom: 15px;
  }

  .inteFormLabel {
    width: 100%;
    margin-bottom: 5px !important;
  }

  .inteFormInput {
    margin: 0 !important;
  }
}

@media (max-width: 575px) {
  .dateSearDiv {
    display: block;
  }

  .dashSixDateInp {
    width: 100%;
    margin-bottom: 15px;
  }

  .dashSixSearchInp {
    width: 100%;
  }

  .clearFilterBtn {
    right: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .totalAvaText {
    font-size: 17px;
  }

  .dashSixTableHead {
    font-size: 15px;
  }

  .dashSixTableData {
    font-size: 15px;
  }

  .dashSixTableConcludeData {
    font-size: 16px;
  }

  .barScreenShotBtn {
    font-size: 20px;
  }
}

@media (max-width: 479px) {
  .pleAccDet {
    font-size: 15px;
  }

  .inteFormLabel {
    font-size: 13px;
  }

  .inteFormInput {
    font-size: 13px;
  }

  .bankStatusSpan {
    font-size: 13px;
  }

  .bankStatusBtn {
    font-size: 13px;
  }

  .bankSelect {
    font-size: 13px;
  }

  .inteSaveConBtn {
    font-size: 12px;
  }

  .chekLabel {
    font-size: 13px;
  }
}

/* Bookkeper Manager */
/* Basic Plan Part */
.bookeperPlanTab .react-tabs__tab-list {
  border: 0;
  display: flex;
  justify-content: space-around;
  background-color: #e7ebf1;
  margin-bottom: 22px;
}

.bookeperPlanTab .react-tabs__tab {
  cursor: pointer;
  padding: 6px 0;
}

.bookeperPlanTab .react-tabs__tab--selected {
  border: 0;
  background-color: transparent;
}

.bookkPlan {
  color: rgb(128, 128, 128);
  font-size: 17px;
  font-weight: 600;
  margin: 0 !important;
  padding-left: 25px;
  cursor: pointer;
}

.bookkPlan::before {
  content: "";
  display: block;
  width: 18px;
  height: 15px;
  background-color: #fff;
  border: 2px solid rgb(128, 128, 128);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.bookeperPlanTab .react-tabs__tab--selected .bookkPlan::before {
  background-color: #14273a;
}

.bookkeTabDiv {
  position: relative;
  cursor: pointer;
}

.bookkeBasicHead {
  color: #0c2636;
  font-size: 21px;
  font-weight: bolder;
  margin: 0 0 20px 0;
}

.bookBasicUl {
  display: inline-block;
  padding: 0;
}

.basicBookkeDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bookBasicLi {
  margin-bottom: 5px;
  list-style-image: url('./images/listImg.png');
  list-style-position: inside;
}

.bookBasicLiSpn {
  font-size: 17px;
  font-weight: 600;
  color: #000;
}

.bookkeTotDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.totBookHead {
  margin: 0 10px 0 0;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  width: 170px;
  text-wrap: nowrap;
}

.totBookInp {
  width: 220px !important;
  padding: 7px 10px !important;
  text-align: center;
  margin: 0 12px 0 0 !important;
  border-radius: 0 !important;
  background-color: rgb(238, 238, 238);
  font-size: 18px;
  font-weight: 600;
  color: rgb(13, 38, 55);
}

.totBookSpan {
  font-size: 18px;
  font-weight: 600;
}

.linkBookkDiv {
  text-align: center;
  margin-bottom: 15px;
}

.needMoreLinkBkk {
  font-size: 17px;
  color: rgb(42, 96, 153) !important;
  text-decoration: underline !important;
  font-weight: 600;
}

.needMoreLinkBkk:hover {
  color: rgb(42, 96, 153);
}

.bookkeperSection {
  margin-bottom: 30px;
}

.bookkPayNowBtn {
  background-color: rgb(53, 82, 105);
  color: #fff;
  border: 0;
  padding: 3px 20px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

/* Custom Plan Part */
.custBookkHead {
  color: rgb(13, 38, 55);
  font-size: 21px;
  font-weight: bolder;
  margin: 0 0 20px 0;
  text-align: center;
}

.custBookkHead span {
  font-size: 18px;
}

.custTypeDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
}

.custinnerTypeDiv {
  display: flex;
  align-items: center;
}

.custIndDiv {
  margin-right: 17px;
  display: flex;
  align-items: center;
}

.custInpLabel {
  color: rgb(13, 38, 55);
  font-size: 15px;
  font-weight: 500;
  margin: 0 0 0 5px;
}

.custCorDiv {
  display: flex;
  align-items: center;
}

.custTyInp {
  width: 14px;
  height: 14px;
  accent-color: rgb(13, 38, 55);
}

.custInpDateLabel {
  color: rgb(13, 38, 55);
  font-size: 15px;
  font-weight: 500;
  margin: 0 8px 0 0;
}

.custDateInp {
  padding: 2px 8px !important;
  border-radius: 0 !important;
}

.custSelecInp {
  width: 32% !important;
  padding: 6px 12px !important;
  border-radius: 0 !important;
  text-align: center;
  height: 37px;
  margin-top: 7px !important;
}

.custSelecInp.border {
  border: 2px solid hsla(0, 0%, 50%, .5) !important;
}


.custSelectHead {
  color: rgb(13, 38, 55);
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  text-align: center;
}

.selecAllServDiv {
  margin-top: 10px;
}

.selectAllOpDiv {
  width: 520px;
  margin: 7px auto 0 auto;
}

.seleAllBtnDiv {
  text-align: right;
  margin-bottom: 5px;
}

.selecAllBtnBkk {
  background-color: transparent;
  border: 0;
  font-size: 15px;
  text-decoration: underline;
  font-weight: 500;
  color: rgb(42, 96, 153);
}

.custBkkLabel {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 0 6px;
}

.custBkkLabDiv {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.allPlnsDiv {
  width: 550px;
  margin: 15px auto 0 auto;
}

.allPlnsInnerDiv {
  display: flex;
  align-items: center;
}

.allPlnLable {
  display: inline-block;
  margin: 0 8px 0 0;
  font-size: 16px;
  font-weight: 500;
  width: 30%;
}

.allPlnInp {
  display: inline-block;
  padding: 5px 12px !important;
  border-radius: 0 !important;
  border: 1px solid #012b52 !important;
  background-color: rgb(238, 238, 238);
  font-size: 16px;
  font-weight: bolder;
  color: #012b52;
  text-align: center;
  width: 88% !important;
}

.allInpSpanMainDiv {
  width: 100%;
}

.allPlnsSpan {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
}

.linkBookGDiv {
  text-align: center;
  margin-top: 20px;
}

/* Bookkeper Docs Part */
.bookDocHeadDiv {
  width: 100%;
  background-color: rgb(231, 233, 238);
  padding: 6px 0;
  text-align: center;
}

.bokkHead {
  font-size: 17px;
  font-weight: bolder;
  color: rgb(128, 128, 128);
}

.bookkDocUpSec {
  margin-bottom: 35px;
}

.bookTabData {
  width: 16%;
  text-align: center;
  /* display: inline-block; */
  padding-top: 10px;
}

.bookTabData-cap {
  width: 14%;
  text-align: center;
  display: inline-block;
  padding-top: 10px;
}

.bookHeadSpan {
  font-size: 17px;
  color: rgb(13, 38, 55);
}

.bokkAcc {
  font-size: 18px;
  font-weight: 500;
  color: rgb(13, 38, 55);
}

.bookkDocsTable {
  width: 100%;
}

.bookkBrowBtn {
  background-color: rgb(56, 61, 60);
  color: #fff;
  border: 0;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 3px 10px;
  border-radius: 5px;
}

.bookAttSpan {
  font-size: 17px;
  font-weight: 500;
  color: rgb(13, 38, 55);
}

.bookAttBtn {
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  color: rgb(42, 96, 153);
}

.bookAttInnerSpan {
  color: rgb(255, 0, 0);
}

.bookkDate {
  font-size: 17px;
  font-weight: 500;
  color: rgb(13, 38, 55);
}

.bookkClrDate {
  color: rgb(255, 0, 0);
}

.bookFileBtn {
  font-size: 17px;
  font-weight: 500;
  color: rgb(42, 96, 153);
  background-color: transparent;
  border: 0;
  text-decoration: underline;
}

.dshSevSeInp {
  width: 185px;
  padding: 5px 10px;
  text-align: center;
  font-size: 16px;
  margin-right: 15px;
  border-radius: 0 !important;
  outline: none;
  border: 1px solid #0000006e;
}

.dshSecDateInp {
  padding: 5px 10px !important;
  border-radius: 0 !important;
  border: 1px solid #0000006e !important;
  font-size: 16px !important;
}

.dshSevToExpDiv {
  max-width: 500px;
  width: 100%;
  margin: 20px auto 0 auto;
  display: flex;
  align-items: center;
}

.dshTotHead {
  color: rgb(13, 38, 55);
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  width: 30%;
}

.dshTotInp {
  width: 70% !important;
  padding: 6px 10px !important;
  border-radius: 0 !important;
  border: 1px solid #00000080 !important;
  text-align: center;
  font-weight: bolder;
  font-size: 17px;
  color: rgb(13, 38, 55);
}

.bookkeMainHead {
  font-size: 30px;
  color: rgb(13, 38, 55);
  text-transform: capitalize;
  font-weight: 600;
}

.bookkeMainHeadDiv {
  background-color: rgb(216, 228, 236);
  padding: 10px 15px;
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .bookkDocsTable {
    min-width: 992px;
  }

  .bookkTableDiv {
    overflow-x: auto;
  }

  .bookkTableDiv::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 767px) {

  /* Custom plan part */
  .custTypeDiv {
    flex-direction: column;
    margin-bottom: 10px;
  }

  .custinnerTypeDiv {
    margin-bottom: 8px;
  }

  .allPlnLable {
    font-size: 16px;
    width: 100% !important;
  }

  .allPlnsDiv {
    width: 100%;
  }

  .allPlnsInnerDiv {
    display: block;
  }
}

@media (max-width: 575px) {
  .allPlnsInnerDiv {
    display: block;
    margin-top: 10px;
  }

  .allPlnLable {
    display: block;
  }

  .allPlnLable {
    font-size: 16px;
    width: 100% !important;
  }

  .selectAllOpDiv {
    width: 100%;
  }

  .custBkkLabel {
    font-size: 15px;
  }

  .dshSevToExpDiv {
    display: block;
    text-align: center;
  }

  .dshTotHead {
    width: 100%;
  }

  .dshTotInp {
    width: 100%;
  }
}

@media (max-width: 479px) {

  /* Basic Plan Part */
  .bookkeBasicHead {
    font-size: 16px;
  }

  .bookBasicLiSpn {
    font-size: 15px;
  }

  .bookkeTotDiv {
    display: block;
    text-align: center;
  }

  .totBookHead {
    margin-bottom: 7px;
    font-size: 15px;
  }

  .totBookInp {
    width: 205px !important;
    padding: 4px 10px !important;
    font-size: 16px;
  }

  .totBookSpan {
    font-size: 15px;
  }

  /* Custom Plan part */
  .custSelecInp {
    width: 100% !important;
    font-size: 14px !important;
  }

  .custBookkHead {
    font-size: 17px;
  }

  .custBookkHead span {
    font-size: 15px;
  }

  .custBkkDateLabDiv {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin-top: 10px;
  }

  .custInpDateLabel {
    margin-bottom: 8px;
  }

  .custDateInp {
    font-size: 15px !important;
  }

  .custSelectHead {
    font-size: 16px;
  }

  .custBkkLabDiv {
    width: 100%;
  }

  .custBkkLabel {
    font-size: 16px;
    margin: 0 0 0 10px;
  }

  .allPlnInp {
    width: 83% !important;
  }

  .bokkHead {
    font-size: 16px;
  }

  .bokkAcc {
    font-size: 15px;
  }

  .bookHeadSpan {
    font-size: 16px;
  }

  .bookkBrowBtn {
    font-size: 13px;
  }

  .bookAttSpan {
    font-size: 15px;
  }

  .bookkDate {
    font-size: 15px;
  }
}

/* Bookkeper Section */
.bookkeLinkSection {
  margin-bottom: 50px;
}

.bookkLinkTable {
  width: 100%;
}

.bookkLinkTableHead {
  width: 20%;
  text-align: center;
  position: relative;
  padding-top: 20px;
  font-size: 21px;
  color: rgb(13, 38, 55);
}

.bookkLinkTableData {
  width: 20%;
  text-align: center;
  padding-top: 4px;
}

.bkkLinInp {
  text-align: center;
  padding: 5px 10px !important;
  border: 1px solid rgb(255, 255, 255);
  border: 1px solid #13040494 !important;
}

.bkkLinInp::-webkit-outer-spin-button,
.bkkLinInp::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.bookLiBtn {
  background-color: rgb(56, 61, 60);
  color: #fff;
  text-transform: uppercase;
  border: 0;
  font-size: 15px;
  font-weight: 600;
  padding: 3px 25px;
  margin-right: 8px;
  border-radius: 6px;
}

.bookRmBtn {
  background-color: transparent;
  border: 0;
  text-transform: capitalize;
  font-size: 18px;
  text-decoration: underline;
  font-weight: 400;
  color: rgb(42, 96, 153);
}

.bookkLiCmnyName {
  font-size: 20px;
  font-weight: 400;
  color: rgb(13, 38, 55);
}

.bookkLinkTableDiv {
  position: relative;
}

.bookkManaGrandDiv1 {
  overflow-x: auto;
}

.bookLinkNextBtn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  background-color: transparent;
  border: 0;
  font-size: 40px;
}

.bookLinkPrevBtn {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  background-color: transparent;
  border: 0;
  font-size: 40px;
  display: none;
}

.disTabDiv {
  display: none;
}

.bookkManaMain {
  display: flex;
}

.bookkManaGrandMain {
  margin-top: 20px;
}

.bookkManaLeft {
  width: 50%;
}

.bookkManaRight {
  width: 50%;
}

.bookkManaRight>div {
  margin-bottom: 10px;
}

.bookkManaLabel {
  font-size: 16px;
  font-weight: 500;
  margin: 0 10px 0 0;
  color: rgb(13, 38, 55);
  width: 32%;
  text-align: right;
}

.bookkManaInp {
  width: 65%;
  text-align: center;
  padding: 4px 10px;
  font-size: 16px;
  border: 1px solid #000000bf;
}

.bookkMainInpLabel {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.bookkManaInpDiv {
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bookkManaInpDiv input {
  width: 49% !important;
  font-size: 16px !important;
  text-align: center !important;
  padding: 3px 8px !important;
  border-radius: 0 !important;
  border: 1px solid #000000bf !important;
}

.bookkManaSpan {
  font-size: 16px;
  font-weight: bolder;
  color: rgb(13, 38, 55);
}

.bookkManaBtnDiv {
  margin-top: 15px;
  text-align: center;
}

.bookkManaBtn {
  background-color: transparent;
  border: 0;
  font-size: 15px;
  color: rgb(42, 96, 153);
  text-decoration: underline;
}

.bookkLinkTableDiv2 {
  margin-top: 20px;
  overflow-x: auto;
}

.bookkLinkTable2 {
  width: 100%;
}

.bookkLinkTableHead2 {
  width: 16.66%;
  text-align: center;
  font-size: 17px;
  color: rgb(13, 38, 55);
}

.bookkLinkTableData2 {
  width: 16.66%;
  text-align: center;
}

.bookkCustName {
  background-color: transparent;
  font-size: 18px;
  text-decoration: underline;
  color: rgb(42, 96, 153);
  font-weight: 500;
  border: 0;
}

.bookkCustNumber {
  font-size: 18px;
  text-decoration: underline;
  color: rgb(42, 96, 153);
  font-weight: 500;
  background-color: transparent;
  border: 0;
}

.bookkCustSendBtn {
  background-color: rgb(56, 61, 60);
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  border: 0;
  padding: 3px 20px;
  border-radius: 5px;
}

.bookkTableCustInp {
  text-align: center;
  width: 90% !important;
  display: inline-block;
  margin: 0 auto;
  padding: 5px 8px !important;
  border: 1px solid #3c3737;
}

.bookkTableCustInp::-webkit-outer-spin-button,
.bookkTableCustInp::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.bookkLinkCustAmnt {
  font-size: 17px;
  font-weight: 500;
  color: rgb(13, 38, 55);
}

.bookCustModalSection {
  width: 100%;
  height: 100vh;
  background-color: #00000094;
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.bookCustModalDiv {
  max-width: 800px;
  width: 100%;
  height: 450px;
  background-color: #fff;
  border-radius: 8px;
  padding: 25px 20px;
  position: relative;
}

.bookkCustModHead {
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
  margin: 0 0 10px 0;
  color: rgb(13, 38, 55);
}

.bookkCustModClsBtn {
  background-color: transparent;
  border: 0;
  font-size: 25px;
  position: absolute;
  top: 10px;
  right: 15px;
  color: rgb(13, 38, 55);
}

.bookCustModBtn {
  background-color: rgb(53, 82, 105);
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  border: 0;
  padding: 3px 20px;
  border-radius: 3px;
  font-weight: 600;
}

.bookkCustModTable {
  width: 100%;
}

.bookkCustModTableHead {
  width: 16.66%;
  text-align: center;
}

.bookkCustModTableData {
  width: 16..66%;
  text-align: center;
  padding-top: 5px;
}

.bookkCustModTableGrandDiv {
  margin-top: 20px;
  position: relative;
  max-height: 310px;
  height: 100%;
}

.bookkCustModTableDiv {
  overflow: auto;
  max-height: 100%;
  height: 100%;
}

.bookkCustModTableDiv::-webkit-scrollbar {
  display: none;
}

.bookkDocTypeInp {
  width: 90% !important;
  padding: 4px 6px !important;
  text-align: center !important;
  font-size: 15px !important;
  margin: 0 auto !important;
  border: 1px solid #0000009e !important;
}

.bookkDocDateInp {
  width: 90% !important;
  padding: 4px 6px !important;
  text-align: center !important;
  font-size: 15px !important;
  margin: 0 auto !important;
}

.bookkDocStatusSpan {
  font-size: 16px;
  font-weight: 500;
  color: rgb(102, 102, 102);
}

.bookkDocSendBtn {
  background-color: rgb(56, 61, 60);
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 15px;
  border: 0;
  padding: 3px 20px;
  border-radius: 6px;
}

.bookkDocViewBtn {
  background-color: transparent;
  border: 0;
  color: rgb(42, 96, 153);
  text-decoration: underline;
  font-size: 16px;
  font-weight: 600;
}

.bookkModalNextBtn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  background-color: transparent;
  border: 0;
  font-size: 17px;
  color: #666161b3;
}

.bookkModalPrevBtn {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  background-color: transparent;
  border: 0;
  font-size: 17px;
  color: #666161b3;
  display: none;
}

.bookkVenInp {
  width: 140px !important;
  text-align: center;
  padding: 2px 6px !important;
  border-radius: 0 !important;
  font-size: 15px !important;
  border: 1px solid #00000085 !important;
  outline: none;
  margin-right: 15px !important;
}

.bookkVenDtInp {
  width: 140px !important;
  text-align: center;
  padding: 2px 6px !important;
  border-radius: 0 !important;
  font-size: 15px !important;
  border: 1px solid #00000085 !important;
  outline: none;
}

.taxDetailBookkTable {
  width: 100%;
}

.taxDetailBookkTableDiv {
  max-height: 70%;
  height: 100%;
  overflow: auto;
  display: none;
  margin-bottom: 8px;
}

.taxDetailBookkTableDiv::-webkit-scrollbar {
  display: none;
}

.taxDetBkTabHead {
  font-size: 14px;
  color: #000000;
  width: 11.11%;
  text-align: center;
}

.taxDetBkTabData {
  width: 11.11%;
  text-align: center;
  padding-top: 9px;
}

.texDeFirstSpan {
  font-size: 14px;
  font-weight: 600;
}

.taxDebkDtInp {
  width: 90% !important;
  margin: 0 auto !important;
  padding: 2px 5px !important;
  font-size: 13px !important;
  text-align: center !important;
}

.texDebkBtn {
  font-size: 10px;
  background-color: transparent;
  border: 1px solid #484646c4;
  font-weight: 600;
  padding: 0 6px;
  border-radius: 5px;
  margin-left: 5px;
}

.texChkBtnDebDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.taxdeTotLabel {
  font-size: 15px;
  font-weight: bolder;
  margin: 0 6px 0 0;
}

.taxdeTotInp {
  width: 150px !important;
  font-size: 15px !important;
  text-align: center;
  padding: 3px 6px !important;
  font-weight: bolder !important;
  border: 1px solid #000000b8 !important;
}

.taxDetTotDucDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.taxDetEditBtn {
  background-color: #a39797;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 3px 30px;
  margin-right: 15px;
  border: 0;
}

.taxDetUpdateBtn {
  background-color: rgb(31, 70, 94);
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 3px 20px;
  border: 0;
}

.texDetBtnsDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
}

.texDetMainDiv {
  display: none;
}

.bookkLinkPlusBtn {
  background-color: #012b52;
  color: #fff;
  font-size: 13px;
  font-weight: bolder;
  border: 0;
  border-radius: 3px;
  padding: 0 5px;
  position: absolute;
  top: 29px;
  left: 0;
}

.bookkLinkMinusBtn {
  background-color: #012b52;
  color: #fff;
  font-size: 13px;
  font-weight: bolder;
  border: 0;
  border-radius: 3px;
  padding: 0 5px;
  position: absolute;
  top: 29px;
  right: 0;
}

@media (max-width: 1199px) {
  .bookkLinkTable {
    min-width: 1200px;
  }

  .bookkLinkTable2 {
    min-width: 1200px;
  }

  .bookkManaLabel {
    width: 37%;
  }

  .bookkManaInp {
    width: 60%;
  }

  .bookkManaInpDiv {
    width: 60%;
  }

  .bookkeMainHead {
    font-size: 22px;
  }
}

@media (max-width: 991px) {
  .bookkManaMain {
    display: block;
  }

  .bookkManaLeft {
    width: 100%;
  }

  .bookkManaRight {
    width: 100%;
  }

  .bookkManaLabel {
    width: 25%;
    text-align: left;
  }

  .bookkManaInp {
    width: 74%;
  }

  .bookkManaInpDiv {
    width: 74%;
  }

  .bookkManaGrandDiv1::-webkit-scrollbar {
    display: none;
  }

  .bookkLinkTableDiv2::-webkit-scrollbar {
    display: none;
  }

  .bookkeMainHead {
    font-size: 18px;
  }
}

@media (max-width: 849px) {
  .bookkCustModTable {
    min-width: 800px;
  }

  .taxDetailBookkTable {
    min-width: 800px;
  }
}

@media (max-width: 767px) {
  .bookkManaLabel {
    width: 32%;
  }

  .bookkCustModHead {
    font-size: 17px;
  }

  .bookkLinkTableHead {
    font-size: 18px;
  }

  .bookkLiCmnyName {
    font-size: 18px;
  }

  .bookLiBtn {
    font-size: 13px;
  }

  .bookRmBtn {
    font-size: 15px;
  }

  .bookLinkNextBtn {
    font-size: 33px;
  }

  .bookLinkPrevBtn {
    font-size: 33px;
  }

  .bookkLinkPlusBtn {
    top: 24px;
  }

  .bookkLinkMinusBtn {
    top: 24px;
  }
}

@media (max-width: 575px) {
  .bookkMainInpLabel {
    display: block;
  }

  .bookkManaInp {
    width: 100%;
    margin-top: 6px;
  }

  .bookkManaInpDiv {
    width: 100%;
    margin-top: 7px;
  }

  .bookkManaLabel {
    width: 37%;
  }

  .bookkCustModAddDiv {
    display: flex;
    justify-content: center;
  }

  .bookkeMainHead {
    font-size: 16px;
  }

  .chart-slide .slick-prev {
    left: -8px;
  }

  .chart-slide .slick-next {
    right: -8px;
  }
}

@media (max-width: 479px) {
  .bookkManaLabel {
    width: 56%;
  }

  .bookLinkPrevBtn {
    top: 55%;
  }

  .bookkCustModHead {
    font-size: 15px;
    margin-top: 10px;
  }

  .bookkCustModClsBtn {
    font-size: 20px;
  }

  .bookkVenInp {
    width: 110px !important;
    font-size: 12px !important;
    margin-right: 8px !important;
  }

  .bookkVenDtInp {
    width: 110px !important;
    font-size: 12px !important;
  }

  .texDetBtnsDiv {
    justify-content: center;
  }
}

/* Missing Docs Modal */
.bookkMissingDocTable {
  width: 100%;
}

.bookkMissingDocTable::-webkit-scrollbar {
  display: none;
}

.bookkMissTableHead {
  width: 25%;
  text-align: center;
  font-size: 15px;
}

.bookkMissTableData {
  width: 25%;
  text-align: center;
  padding-top: 8px;
}

.bookkMissDocType {
  width: 90% !important;
  margin: 0 auto !important;
  padding: 3px 6px !important;
  text-align: center;
  font-size: 15px !important;
  border: 1px solid #130404a6 !important;
}

.bookkMissDocSpan {
  font-size: 17px;
  font-weight: 600;
  color: rgb(102, 102, 102);
}

.bookkMissingDocTableDiv {
  max-height: 360px;
  height: 100%;
  overflow: auto;
  margin-top: 10px;
}

.bookkMissModalSection {
  width: 100%;
  height: 100vh;
  background-color: #00000094;
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.bookCustDisplayModalDiv {
  display: none;
}

.bookkCustModAddDisplayDiv {
  display: none;
}

@media (max-width: 767px) {
  .bookkMissingDocTable {
    min-width: 768px;
  }

  .chart-slide .slick-next:before,
  .chart-slide .slick-prev:before {
    font-size: 25px;
  }
}


/*****landing_modal***/
/***signup modal***/
.SignUpWrap {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  /* justify-content: center; */
  align-items: center;
  padding: 1% 6%;
  z-index: 9999999;
  transform: scale(0);
  transition: transform 1.5s ease;
}

.SignUp {
  width: 100%;
  height: 608px;
  overflow-y: auto;
  display: table;
  margin: 0 auto;
  background-color: #fff;
  text-align: center;
  position: relative;
  /* transform: scale(0);
transition: transform 1.5s ease; */
  box-shadow: 0 0 6px 0 rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

.SignUpWrap.activee {
  /* transform: translate(-50%, -50%) scale(1); */
  transform: scale(1);
}

.my_close {
  position: absolute;
  right: 34px;
  top: 10px;
  z-index: 2;
  font-size: 20px;
  color: #000;
}

.SignUp .inner {
  height: 570px;
  overflow-y: auto;
}

.big_img {
  width: 100%;
  height: 54vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.big_img img {
  width: auto;
  height: 100%;
  object-fit: cover;
  margin: 0 auto;
}

.bottom_cnt h4 {
  font-size: 32px;
  line-height: 1.2;
  color: black;
  font-weight: 900;
  margin-bottom: 12px;
}

.bottom_cnt {
  width: 100%;
  margin: 0 auto;
  display: table;
}

.bottom_cnt p {
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 10px;
}

.bottom_cnt h5 {
  color: #82bb30;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2;
}

.signupBtn {
  border: 2px solid;
  background-color: #154165;
  display: inline-block;
  font-family: sans-serif;
  transform: translateX(calc(-0.15em * 3 - 0.08em * 2));
  transition: 0.3s;
  text-decoration: none;
  color: whitesmoke;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  margin: 0 auto;
  padding: 10px 35px;
  cursor: pointer;
}

.signupBtn:hover {
  transform: translateX(0.15em);
  color: #82bb30;
  box-shadow: 0px 0px 4px 4px #82bb30;
  -webkit-box-shadow: 0px 0px 4px 4px #82bb30;
  text-decoration: none;
}

.fisibility-card__image img {
  height: 80%;
  width: auto;
  margin-top: 20px;
}

/***snd_slide***/
.snd_slide {
  margin-top: 4%;
}

.snd_slide .bottom_cnt h4 {
  font-size: 3rem;
}

.snd_slide .bottom_cnt h5 {
  color: #4facff;
}

/**** thrd_slide***/
.thrd_slide .bottom_cnt h4 {
  font-size: 2rem;
}

/*** frth_slide***/
.frth_slide span {
  margin-bottom: 13px;
  font-size: 18px;
  display: block;
}

.frth_slide .bottom_cnt p {
  font-size: 20px;
}

.frth_slide .bottom_cnt p:first-of-type {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2;
}

.bottom_cnt ul {
  margin-bottom: 30px;
  padding: 0;
}

.bottom_cnt ul li {
  list-style: none;
  font-size: 17px;
  margin-bottom: 5px;
}

.frth_slide .bottom_cnt ul li::before {
  content: "✓";
  color: green;
  font-size: 1.5em;
  margin-right: 7px;
}

/****SpreadSheet****/
.SpreadSheet {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.SpreadSheet li::before {
  content: "✗";
  color: red;
  font-size: 1.5em;
  margin-right: 0.5em;
}

.SpreadSheet .lftImg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 58%;
  height: 545px;
}

.SpreadSheet .lftImg img {
  width: 100%;
  height: 100%;
}

.SpreadSheet .rgtCnt {
  width: 40%;
  margin-right: 34px;
}

.read-more {
  color: #4facff;
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
}

.SpreadSheet .bottom_cnt {
  text-align: left;
  margin: 0;
  padding-top: 58px;
  padding-left: 0;
}

.SpreadSheet .bottom_cnt h4 {
  font-size: 38px;
}

.SpreadSheet .wrapCnt {
  height: 485px;
  overflow-y: auto;
}

.read-more:hover {
  text-decoration: none;
  color: #4facff;
}

.read-more:after {
  content: "➜";
  color: #4facff;
  margin-left: -10px;
  opacity: 0;
  vertical-align: middle;
  transition: margin 0.3s, opacity 0.3s;
}

.read-more:hover:after {
  margin-left: 5px;
  opacity: 1;
  text-decoration: none;
}

/***integration***/
.SpreadSheet.integration .bottom_cnt {
  padding: 40px 0 0 40px;
}

.bottom_cnt {
  padding: 10px 38px;
}

.bottom_cnt span {
  font-size: 18px;
}

/**Uncertain***/
.Uncertain .top h3 {
  font-size: 32px;
  line-height: 1.2;
  color: black;
  font-weight: 900;
  margin-top: 30px;
}

.Uncertain li::before {
  content: "-";
  color: #154165;
  font-size: 1.5em;
  margin-right: 0.5em;
}

.SpreadSheet.Uncertainimg {
  align-items: center;
}

.SpreadSheet.Uncertainimg .bottom_cnt ul li {
  margin-bottom: 0;
}

.SpreadSheet.Uncertainimg .lftImg {
  height: 300px;
}

.SpreadSheet.Uncertainimg .wrapCnt {
  height: auto;
}

.SpreadSheet.Uncertainimg .bottom_cnt {
  padding-top: 0;
}

.SpreadSheet.Uncertainimg .bottom_cnt ul {
  margin-bottom: 0;
}

.SpreadSheet.Uncertainimg .lftImg {
  width: 50%;
}

.SpreadSheet.Uncertainimg .rgtCnt {
  width: 50%;
}

.SpreadSheet.Uncertainimg .bottom_cnt h5 {
  font-size: 18px;
}

/**swiper***/
.swiper {
  width: 100%;
  height: 100%;
  position: absolute !important;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide .fst_slide img {
  width: auto !important;
}

.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--swiper-theme-color);
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--swiper-theme-color);
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

.taxDetailTableInput {
  width: 100px !important;
  padding: 5px 6px !important;

}

/* .taxDetailTableInput {
  -moz-appearance: textfield;
  appearance: textfield;
} */

.taxDetailTableInput::-webkit-outer-spin-button,
.taxDetailTableInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



@media (max-width: 1350px) {
  .SignUp {
    height: 510px;
  }

  .SignUp .inner {
    height: 475px;
    overflow-y: auto;
  }
}

@media (max-width: 991px) {
  .SpreadSheet.Uncertainimg .rgtCnt {
    width: 100%;
  }

  .SpreadSheet {
    flex-direction: column;
  }

  .SpreadSheet .lftImg {
    width: 100%;
    height: auto;
  }

  .SpreadSheet .rgtCnt {
    width: 100%;
  }

  .SpreadSheet .bottom_cnt {
    padding: 0 57px 0;
  }

  .SpreadSheet .wrapCnt {
    height: auto;
  }

  .snd_slide .bottom_cnt h4 {
    font-size: 2rem;
  }

  .SpreadSheet.integration .bottom_cnt {
    padding: 40px 40px 0 40px;
  }
}

@media (max-width: 768px) {
  .bottom_cnt span {
    font-size: 14px;
  }

  .bottom_cnt ul li {
    font-size: 14px;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    font-size: 24px !important;
  }

  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    font-size: 24px !important;
  }

  .SpreadSheet.Uncertainimg .rgtCnt {
    width: 100%;
  }

  .SpreadSheet.Uncertainimg .lftImg {
    width: 100%;
  }

  .fisibility-card__image img {
    width: 100%;
  }

  .Stress .bottom_cnt {
    padding-top: 40px;
  }


  .big_img img {
    width: 100%;
  }

  .SpreadSheet .bottom_cnt h4 {
    font-size: 21px;
  }

  .frth_slide .bottom_cnt h4 {
    font-size: 22px;
    margin-top: 30px;
  }

  .frth_slide .bottom_cnt p {
    font-size: 16px;
  }

  .thrd_slide .bottom_cnt h4 {
    margin-top: 30px;
    font-size: 20px;
  }

  .thrd_slide .bottom_cnt p {
    font-size: 16px;
  }

  .snd_slide .bottom_cnt h4 {
    margin-top: 30px;
  }

  .signupBtn {
    font-size: 15px;
    padding: 13px 13px;
  }

  .snd_slide .bottom_cnt h4 {
    font-size: 24px;
  }

  .snd_slide .bottom_cnt h5 {
    font-size: 16px;
  }

  .bottom_cnt h4 {
    font-size: 21px;
  }

  .bottom_cnt p {
    font-size: 14px;
  }

  .bottom_cnt h5 {
    font-size: 16px;
  }
}

@media (max-width: 420px) {
  .snd_slide .bottom_cnt h4 {
    font-size: 20px;
  }
}


/****deleteaccount_page****/

.deleteaccount_page {
  width: 100%;
  height: auto;
  padding: 40px 0;
}

.deleteaccount_page .heding {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  color: #082f4b;
  line-height: normal;
  padding: 0;
  margin-bottom: 20px;
}

.deleteaccount_page .desc {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #082f4b;
  line-height: normal;
  padding: 0;
  margin-bottom: 30px;
}

.deleteaccount_page form {
  width: 100%;
  height: auto;
}

.deleteaccount_page .form-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 20px;
}

.deleteaccount_page .form-group label {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

.deleteaccount_page .form-group input {
  width: 100%;
  max-width: 250px;
}

.Deletebutton_btn {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Deletebutton_btn .btn {
  width: 100%;
  max-width: 120px;
  height: 40px;
  text-decoration: none;
  box-shadow: none;
  outline: 0;
  background-color: #081722;
  border: 1px solid #081722;
  color: #fff;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  margin: 0 auto;
  transition: 0.6s;
}

.Deletebutton_btn .btn:hover {
  background-color: transparent;
  color: #081722;
  border: 1px solid #081722;
}


@media (max-width: 480px) {
  .deleteaccount_page {
    padding: 20px 0 30px;
  }

  .deleteaccount_page .heding {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .deleteaccount_page .desc {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  .deleteaccount_page .form-group label {
    font-size: 14px;
  }

  .deleteaccount_page .form-group {
    gap: 10px;
  }
  .deleteaccount_page .form-group input {
    padding: 5px 10px !important;
    margin: 0 !important;
    font-size: 14px;
  }
  .deleteaccount_page .form-group input::placeholder {
    font-size: 14px;
  }
}