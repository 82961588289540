h1,
h2 {
    margin: 40px 40px 0 40px;
}

h1 {
    font-size: 4em;
}

h2 {
    font-size: 2.5em;
}

.container-all {
    display: block;
    width: 100vw;
    height: auto;
    text-align: center;
}

.pricenewSection {
    width: 100vw;
    height: 100%;
    background-size: cover;
    background-position: center;
}


/* TITLE, IMAGES AND BUTTONS */
.contenedor {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.izquierda,
.centro,
.derecha {
    flex: 1;
    /* Mismo tamano para todos */
    /* Otros estilos según sea necesario */
}

.centro {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.botones-contenedor {
    display: flex;
    flex-direction: row;
}

.centro p {
    margin-top: 20px;
    font-size: 14px;
    color: #333;
    text-transform: uppercase;
    font-weight: 900;
}

/*Aqui comienza el diseño del checkbox*/
.boton {
    margin: 10px;
    align-items: center;
    justify-content: space-between;
    display: flex;
}

.boton p {
    flex: 1;
    margin: 0 15px;
}

.lbl-switch {
    display: inline-block;
    width: 111px;
    height: 30px;
    background: #aaa;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}

#btn-switch~.lbl-switch {
    background: white;
    border: 1px solid black;
}

#btn-switch:checked~.lbl-switch {
    background: white;
    border: 1px solid black;
}

#btn-switch:checked~.lbl-switch:after {
    left: calc(100% - 44px);
}

.lbl-switch:after {
    position: absolute;
    content: '';
    width: 40px;
    height: 24px;
    background: #297F74;
    border-radius: 4px;
    top: 2px;
    left: 4px;
    transition: 0.3s;
}

#btn-switch {
    display: none;
}

/*Aqui comenza el diseño de los botones circulares*/
.boton-circular {
    width: 166px;
    /*Ancho del boton*/
    height: auto;
    /*Altura del boton*/
    border-radius: 33px / 50%;
    /*Ajustar el radio para el ancho y el alto*/
    background-color: transparent;
    border: 2px solid black;
    color: black;
    font-size: 24px;
    font-weight: 400;
    cursor: pointer;
    margin-left: 10px;
    padding: 2% 0;
}

.boton-circular:hover {
    box-shadow: 0 0 10px 3px #297F74;
}

.boton-circular:active {
    transform: scale(0.95);
}

.boton-circular#business--button.seleccionado {
    /* background-color: #0D283D; */
    border: 2px solid #0D283D;
    /* color: white; */
}

.boton-circular#personal--button.seleccionado {
    /* background-color: #297F74; */
    border: 2px solid #297F74;
    /* color: white; */
}

/* THE END TITLE, IMAGES AND BUTTONS */


/*Aqui comienza el diseño de las cartas*/
.card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 40px;
    width: 100%;
    gap: 20px;
}

.My_card-container {
    width: 95%;
    margin: 20px auto;
}

.user {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
}

.user p {
    margin-bottom: 0;
}

.user svg {
    margin: 0 40px;
}

.checkPrice {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: flex-start;
}

.checkPrice svg {
    margin-right: 10px;
    margin-top: 4px;
    vertical-align: middle;
}

.checkPrice p {
    margin: 0;
    font-size: 16px;
}

.card {
    width: 325px;
    background-color: #f0f0f0;
    border: 2px solid #0D283D;
    /* Color del borde*/
    border-radius: 8px;
    /* Radio de borde para esquinas redondeadas */
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    margin: 20px;
    display: none;
}

.card.businessCard {
    border: 2px solid #0D283D;
    background-color: #f0f0f0;
    width: 27%;
    margin: 0;
    flex: inherit;

}

.card.personalCard {
    border: 2px solid #0D283D;
    background-color: #f0f0f0;
    width: 27%;
    margin: 0;
    flex: inherit;
}

.card-container .card:nth-child(4),
.card-container .card:nth-child(5) {
    /*Aplicar color #297F74 a los bordes de la tercera carta*/
    border: 2px solid #297F74;
    position: relative;
}



.card-content-price {
    /* padding: 16px; */
    color: #0D283D;
}

.card-content-price h3 {
    font-size: 28px;
    margin-bottom: 8px;
    text-align: left;
    /*color: #0D283D;*/
}

.card-content-price p {
    color: #0D283D;
    font-size: 16px;
    line-height: 1.3;
    text-align: left;
}

.card-content-price p:first-child {
    /*Permite que el primer parrafo de estos contenedores este siempre centrado*/
    text-align: center;
    color: #0D283D;
}

.card-content-price p .subrayar {
    text-decoration: underline;
    font-weight: bold;
    color: #0D283D;
    font-size: 17px;
}

.card-container .card:nth-child(4) .card-content-price,
.card-container .card:nth-child(5) .card-content-price {
    color: #297F74;
}

.card-container .card:nth-child(4) .card-content-price .parrafo-especial,
.card-container .card:nth-child(5) .card-content-price .parrafo-especial {
    color: #297F74;
}

.card-container .card:nth-child(4) .card-content-price .parrafo-centrar,
.card-container .card:nth-child(5) .card-content-price .parrafo-centrar {
    text-align: center;
}

.card-container .card:nth-child(4) .card-content-price .btn-tercera-carta,
.card-container .card:nth-child(5) .card-content-price .btn-tercera-carta {
    background-color: #297F74;
}

.card-container .card-content-price p#card1--p6,
.card-container .card-content-price p#cardP1--p6,
.card-container .card-content-price p#card2--p6,
.card-container .card-content-price p#card3--p6,
.card-container .card-content-price p#cardP2--p6,
.card-container .card-content-price p#card4--p6 {
    margin: 10px 0;
}

.card-content-price .btn {
    display: inline-block;
    text-align: center;
    padding: 8px 16px;
    background-color: #0D283D;
    text-decoration: none;
    border-radius: 0px;
    margin-top: 16px;
    color: #fff;
    width: 100%;
    /* Asegura que el texto ocupe el ancho completo */
}

.card-content-price .btn:hover {
    background-color: #0D283D;
    text-decoration: none;
    color: #fff;
}

.popular {
    position: absolute;
    background-color: #297F74;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    top: 0;
    right: 0;
    margin: 0px;
    z-index: 1;
    font-size: 14px;
    font-weight: bold;
}


/* RESPONSIVE WEB SITE */

@media (max-width: 1199px) {
    .card.businessCard {
        padding: 18px;
    }

    .card.personalCard {
        padding: 18px;
    }

    .user svg {
        margin: 0 20px;
        width: 25px;
    }

    .card-content-price h3 {
        font-size: 24px;
    }
}

@media (max-width:991px) {
    .card.businessCard {
        width: 30%;

    }

    .card.personalCard {
        width: 30%;
    }

    .card-content-price p {
        font-size: 13px;
    }
}

@media (max-width:767px) {
    .card.businessCard {
        width: 48%;

    }

    .card.personalCard {
        width: 48%;
    }

    .derecha {
        display: none;
    }
}

@media screen and (max-width:744px) {

    h1,
    h2 {
        margin: 40px;
    }

    h1 {
        font-size: 2em;
    }

    h2 {
        font-size: 1.5em;
    }

    .contenedor {
        flex-direction: column;
        /* Cambia la dirección del flex container a columna */
        align-items: flex-start;
        /* Alinea los elementos al inicio de la columna */
    }

    .izquierda,
    .centro,
    .derecha {
        flex: 1;
        width: 100%;
        margin-bottom: 10px;
    }
}

@media (max-width: 575px) {
    .boton-circular {
        border-radius: 13px;
        font-size: 21px;
        padding: 1% 0;
    }

    .card.businessCard {
        width: 100%;

    }

    .card.personalCard {
        width: 100%;
    }

    .card-content-price h3 {
        font-size: 22px;
    }

    .user {
        justify-content: flex-start;
    }
}

@media (max-width: 479px) {
    .botones-contenedor {
        flex-direction: column;
    }

    #business--button {
        margin-bottom: 10px;
    }

    #personal--button {
        margin-bottom: 10px;
    }

    .card-container .card:nth-child(4) .card-content-price .parrafo-centrar,
    .card-container .card:nth-child(5) .card-content-price .parrafo-centrar {
        text-align: left;
    }
}