#about-us .right-content .first-content {
    display: flex;
    justify-content: space-between;
}

#about-us .right-content .first-content h4 {
    width: 15%;
    font-weight: 700;
    margin-right: 65px;
}

#about-us .right-content .first-content p {
    width: 85%;
    text-align: justify;
}

.view-normal-table {
    width: 100%;
    border: 1px solid black;
    text-align: center;
}

.invoiceTrashBtn {
    background-color: transparent;
    border: 0;
    color: #C70039;
}

.invoiceTrashBtnTD {
    height: 49px;
    display: flex;
    align-items: center;
    padding: 0 5px;
}

.bar-hover-box {
    padding: 10px;
}

.integration-select {
    width: 100%;
}

.sat-normal-modal {
    position: absolute;
    top: 0;
    left: 291px;
    border: 2px solid #9f9d9d;
}

.sat-normal-modal-text {
    padding: 10px;
}

div.plaid-manager-dropdown {
    position: absolute;
    right: -11px;
    top: 28px;
    box-shadow: 0px 0px 3px 0px;
    padding: 7px;
    width: 286px;
    background-color: #f8f9fa;
}

div.plaid-manager-dropdown p {
    margin: 10px 0px;
    font-size: 16px;
    font-weight: 500;
    font-family: sans-serif;
    cursor: pointer;
}

div.plaid-manager-dropdown p:hover {
    font-weight: 600;
}

.btn-report-save {
    padding: 3px 7px;
    border: 1px solid black;
    border-radius: 5%;
    margin-right: 6px;
    font-size: 14px;
}

.btn-report-build {
    padding: 3px 7px;
    border: 1px solid black;
    border-radius: 5%;
    background-color: #0E2537;
    color: #fff;
    font-size: 14px;
}

.btn-report-save {
    padding: 3px 7px;
    border: 1px solid black;
    border-radius: 5px;
    color: #0E2537;
    font-weight: 500;
    font-size: 14px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-report-save:hover {
    background-color: #0E2537;
    color: #fff;
}

.viewInsighBtn {
    background-color: #0E2537;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    padding: 2px 15px;
    border-radius: 7px;
}

.viewInsighDiv {
    display: flex;
    justify-content: space-between;
}

.viewInsighInpDiv input {
    font-size: 17px;
    border-radius: 0;
    outline: none;
    border: 0;
    padding: 6px 10px 6px 35px;
}

.viewInsighInpDiv {
    position: relative;
    margin-left: 10px;
    max-width: 300px;
    width: 100%;
}

.viewInsignInpBtnDiv {
    display: flex;
    align-items: center;
    max-width: 485px;
    /* width: 100%; */
}

.viewInsighInpDiv i {
    position: absolute;
    top: 19px;
    left: 8px;
    font-size: 17px;
}

.rep-build-down-drop-menu {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 0px 3px 0px;
    width: 88%;
    z-index: 1;
    padding-left: 7px;
}

.rep-build-down-drop-item {
    cursor: pointer;
    margin: 3px 0px;
    font-size: 17px;
    font-weight: 500;
}

.searvice-key-dropdown {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 0 3px 0;
    padding: 5px 0;
    margin-top: 2px;
    z-index: 1;
    max-height: 250px;
    overflow-y: auto;
}

.searvice-key-dropdown p {
    margin: 1px 0;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    padding: 3px 8px;
    width: 208px
}

.searvice-key-dropdown p:hover {
    background-color: #e0e0e6;
    font-weight: 500;
}

.sat-right-icon {
    color: green;
    font-size: 29px;
    display: flex;
    justify-content: center;
}

.sendFisiInp-hidden {
    position: absolute;
    right: 11px;
    width: 36%;
    height: 38px;
    opacity: 0;
}

.sendFisiInp-dateicon {
    position: absolute;
    right: 19px;
    font-size: 27px;
    z-index: -1;
}

.transacBtnGroup {
    display: flex;
    justify-content: space-between;
}

.transacBtnGroup button {
    padding: 4px 8px;
    border: 1px solid #bdbdbd;
    background: #0E2537;
    color: #fff;
    border-radius: 5px
}

.transacBtnGroup button:hover {
    background: #637685;
    color: #fff;

}

@media(max-width:991px) {
    .transacBtnGroup {
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
        /* justify-content: space-between; */
    }

    .transacBtnGroup button {
        padding-bottom: 2px;
        width: 500px;
    }

    .LinkBookHead {
        text-align: start !important;
    }

}


.dashboard-zero-search {
    background: #fff;
    width: 171px;
    position: absolute;
    max-height: 170px;
    overflow: hidden;
    z-index: 1;
    box-shadow: 0 0 2px 0;
    margin-top: 2px;
    font-size: 13px
}

.dashboard-zero-search p {
    margin: 3px 0;
    cursor: pointer;
    padding: 0 5px;
}

.dashboard-zero-search p:hover {
    background-color: #d5dfe5;
}

@media(max-width: 991px) {
    .dashboard-zero-search {
        width: 323px;
    }
}


/* bookkeeper */
.BookLinkHading {
    background-color: #d8e4ec;
    padding: 11px 14px;
    margin: 15px 0px;
    display: flex;
    justify-content: start;
    font-size: 25px;
    color: #0E2537;
}

.BookLinkSubHeading {
    background-color: #e8ebed;
    color: #0E2537;
    padding: 3px;
    margin: 10px 0px;
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.bookManageAcctForm {
    position: relative;
    padding-bottom: 10px;
}

.clearAll {
    position: absolute;
    right: 10px;
    bottom: 0;
}

.clearAllLink {
    color: #2a6099 !important;
    text-decoration: underline !important;
    font-size: 14px;
}

.bookLinkFormDiv {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 4px;
}

.bookLinkFormDiv label {
    width: 25%;
    color: #0E2537;
    font-size: 15px;
    font-weight: 500;
}

.bookformInp {
    width: 73%;
    display: flex;
    gap: 8px;
}

.bookformInp input {
    width: 49%;
}

.bookLinkFormDiv input {
    font-size: 14px;
    /* width: 139px; */
    height: 32px;
    border: 1px solid #cbcbcb;
    box-shadow: 0 0 2px 0;
    /* margin-right: 9px; */
}

.acctDashUl {
    list-style-type: none;
    padding-left: 0 !important;
}

.acctDashUl li {
    color: #0E2537;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

.LinkBookHead {
    width: 40%;
    text-align: end;
}

.LinkBookNumber {
    font-weight: 700;
}

.bookLinkTableButton {
    background-color: #0E2537;
    color: #fff;
    width: 96px;
    height: 27px;
    padding: 0;
}

.bookLinkTableInput {
    width: 139px;
    height: 32px;
    border: none;
    box-shadow: 0 0 2px 0;
    margin-right: 9px;
}

.column-padding {
    padding: 0 15px;
}

/* missing doc modal */

.missingDocModal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.missingDocModInn {
    width: 49.5%;
    background-color: #fff;
    border-radius: 30px;
    padding: 15px 20px;
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.8);
}

.missingDocModInninvstmnt {
    width: 76%;
    background-color: #fff;
    border-radius: 30px;
    padding: 15px 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.8);
}


.missingDocModal.pagedesign {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 999;
}

.missingDocModInn.pagedesign {
    width: 80%;
    background-color: #fff;
    border-radius: 0;
    padding: 15px 20px;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    box-shadow: none;
    margin: 0 auto;
}





.missingDocClose {
    position: absolute;
    top: 5px;
    right: 20px;
    z-index: 2;
}

.bookSecModalHead {
    color: #0E2537;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
}

.missingDocTbl {
    margin-top: 30px;
    height: 345px;
    width: 100%;
    overflow: auto;
    margin-bottom: 10px;
}

.missingDocTbl::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.missingDocTbl::-webkit-scrollbar-track {
    background-color: #cbcbcb;
}

.missingDocTbl::-webkit-scrollbar-thumb {
    background-color: #4d4b4b;
}

.missingDocTbl th {
    width: auto;
    white-space: nowrap;
    padding: 4px 15px;
    font-size: 15px;
    color: #0E2537;
    font-weight: 500;
    text-align: center;
}

.missingDocTbl td {
    width: auto;
    white-space: nowrap;
    padding: 3px 5px;
    text-align: center;
    color: #4d4b4b;
    font-size: 14px;
    /* border: 1px solid grey; */
}

.bookModalBtn {
    width: 100% !important;
    background: none !important;
    /* border: 1px solid #4d4b4b !important; */
    border: none;
    color: #4d4b4b !important;
    font-size: 14px !important;
    padding: 2px !important;
    border-radius: 0px !important;
}

.missingDocSndBtn {
    background-color: #0E2537;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    border: none;
    outline: 0;
    padding: 3px 15px;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
}

.bookmodalAddBtn {
    background-color: #2a6099;
    color: #fff;
    border: none;
    outline: 0;
    border-radius: 0;
    padding: 5px 15px;
    font-size: 12px;
}

.bookSecPublish {
    background-color: green;
    color: #fff;
    border: none;
    outline: 0;
    padding: 5px 12px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
}

.missingDocModal .slick-next {
    right: 0 !important;
}

.missingDocModal .slick-prev {
    left: -10px !important;
}

.missingDocModal .slick-next:before,
.missingDocModal .slick-prev::before {
    font-size: 22px;
}

.bookSecTopDivBtn {
    background: none;
    margin-right: 10px;
    border: 1px solid #4d4b4b;
    color: #4d4b4b;
    font-size: 14px;
    border-radius: 0px;
    padding: 3px 8px;
    width: 129px;
}

.missingDocTbl input[type='checkbox'] {
    margin-right: 5px;
}

.bookSmallBtn {
    background: none;
    border: 1px solid #4d4b4b;
    color: #4d4b4b;
    padding: 0px 5px;
    font-size: 11px;
}

.totalAmtDiv {
    display: flex;
    justify-content: flex-end;
}

.totalAmtDiv p {
    color: #000;
    font-size: 14px;
    font-weight: 600;
}

.totalAmtBtn {
    background: none;
    border: 1px solid #4d4b4b;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    border-radius: 0px;
    padding: 3px 10px;
}

.taxManageBtnDiv {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.taxManageEditBtn {
    background-color: #4d4b4b;
    color: #fff;
    border: none;
    padding: 3px 10px;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.taxManageUpBtn {
    background-color: #2a6099;
    color: #fff;
    border: none;
    padding: 3px 10px;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.acceptCard {
    display: flex;
    justify-content: center;
}

.acceptCardBody {
    background: #cdcdcd;
    width: 50%;
    padding: 20px;
}

.LinkBookUserList {
    position: absolute;
    top: auto;
    right: 8px;
    background: #fff;
    box-shadow: 0px 0px 3px 0px;
    text-align: left;
    z-index: 99;
}

.LinkBookUserList p {
    padding: 2px 7px;
    margin: 0px;
    font-size: 18px;
    cursor: pointer;
}

.LinkBookUserList p:hover {
    background-color: #b1d5f1;
}

.bookTaxDetailManageStart {
    height: 38px;
    position: absolute !important;
    top: -9px;
    right: 9px;
    width: 131px
}

.bookTaxDetailManageStart input {
    background: linear-gradient(to right, #194860, #0f2739);
    color: #fff !important;
    border: 0;
    text-align: center;
    opacity: 0;
}

.bookTaxDetailManageEnd {
    height: 35px;
    position: absolute !important;
    width: 129px;
    right: 10px;
    top: -8px;
}

.bookTaxDetailManageEnd input {
    background: linear-gradient(to right, #194860, #0f2739);
    color: #fff !important;
    border: 0;
    text-align: center;
    opacity: 0;
}

.bookTaxDetClearButton {
    font-style: italic;
    font-size: 16px;
    margin-left: 6px;
    cursor: pointer;
    text-decoration: underline;
}

.plusBtnBookTaxDet {
    background: none;
    border: none;
    color: green;
    font-size: 23px;
    font-weight: 700;
    margin-right: 4px;
}

.bookDetailPdfInpt {
    width: 17px;
    height: 23px;
    position: absolute;
    top: 19px;
    right: 92px;
    opacity: 0;
}

.setupDocInp {
    width: 85%;
    padding: 4px !important;
    border-radius: 5px;
    border: 1px solid #6c6c6c;
}

.setupDocFileInp {
    position: absolute;
    top: 0;
    right: 14px;
    width: 97px;
    opacity: 0;
}

.setDocManageTrushBtn {
    color: #a90000;
    margin-right: 5px;
    font-size: 15px;
    cursor: pointer;
}

.docInpBookUser {
    position: absolute;
    width: 83px;
    top: 9px;
    right: 70px;
    opacity: 0;
}

.bookDetailPdfInptCheck {
    width: 19px;
    height: 23px;
    position: absolute;
    top: 19px;
    right: 109px;
    opacity: 0;
}

.pdfExist {
    right: 54px;
}

.pdfNoexist {
    right: 41px;
}

.subuserslide .slick-prev {
    /* top: auto !important; */
    line-height: 0;
    position: absolute;
    /* bottom: -36px !important; */
    padding-top: 11px !important;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 99;
}

.subuserslide .slick-next {
    /* top: auto !important; */
    line-height: 0;
    position: absolute;
    padding-top: 11px !important;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 99;
}

.subuserslide .slick-prev:before {
    content: '\f104' !important;
    font-family: FontAwesome !important;
    font-size: 27px !important;
    line-height: 1;
    opacity: 1 !important;
    color: rgb(0, 0, 0) !important;
    display: flex;
    background-color: #fff;
    border-radius: 30px;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15), 1px 2px 5px 0px rgba(0, 0, 0, 0.15) inset;
}

.subuserslide .slick-next:before {
    content: '\f105' !important;
    font-family: FontAwesome !important;
    font-size: 27px !important;
    line-height: 1;
    opacity: 1 !important;
    color: rgb(0, 0, 0) !important;
    display: flex;
    background-color: #fff;
    border-radius: 30px;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15), 1px 2px 5px 0px rgba(0, 0, 0, 0.15) inset;
}


.subuserslide .slick-prev {
    left: -5%;
}

.subuserslide .slick-next {
    right: -5%;
}

.subuserslide .slick-slider {
    position: relative;
    display: block;
    padding-top: 35px;
    padding-bottom: 10px;
}

.missingDocsuserModal.pagedesign {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 9;
}

.missingDocuserModInn.pagedesign {
    width: 80%;
    background-color: #fff;
    border-radius: 0;
    padding: 15px 20px;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    box-shadow: none;
    margin: 0 auto;
}

.modal-ivnProfile {
    display: block;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 34px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content */
.modal-content-ivnProfile {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

/* The Close Button */
.close-ivnProfile {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close-ivnProfile:hover,
.close-ivnProfile:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.invLinkSaveBtn {
    background: #0E2537;
    border: none;
    color: #fff;
    padding: 1px 6px;
    /* margin-right: -1px; */
    width: 100%;
    max-width: 46px;
    margin-left: 0px;
    border-radius: 14px;
    font-size: 14px;
    margin-left: 4px;
}

.invDashboardDrop {
    position: absolute;
    background-color: #fff;
    top: 38px;
    z-index: 99;
    box-shadow: 0 0 2px 0;
    width: 186px;
}

.invDashboardDrop p {
    margin: 1px 0px;
    padding: 0px 4px;
    cursor: pointer;
}

.invDashboardDrop p :hover {
    background-color: #2a6099;
}

/* responsive */

@media (max-width:1199px) {
    .docInpBookUser {
        right: 53px;
    }
}

@media (max-width: 991px) {
    .bookDetailPdfInptCheck {
        right: 91px;
    }

    .bookDetailPdfInpt {
        right: 74px;
    }

    .pdfExist {
        right: 45px
    }

    .pdfNoexist {
        right: 33px;
    }

    .bookSecModalHead {
        font-size: 16px;
    }

    .docInpBookUser {
        right: 57px;
    }

    .missingDocTbl th {
        width: auto;
        white-space: nowrap;
        padding: 4px 10px;
        font-size: 14px;
        color: #0E2537;
        font-weight: 500;
    }
}

@media (max-width:767px) {
    .gjhjhj {
        flex-wrap: wrap;
        gap: 10px;
    }

    .setupDocInp {
        width: 155px;
    }
}

@media (max-width: 575px) {

    .bookLinkFormDiv label {
        font-size: 14px;
    }

    .acctDashUl li {
        font-size: 14px;
    }

    /* .missingDocModInn {
        width: 300px;
    } */

    .missingDocTbl {
        height: 200px;
    }
}

@media (max-width: 480px) {

    .bookLinkFormDiv label {
        width: 30%;
    }

    .bookformInp {
        width: 68%;
    }

    .LinkBookHead {
        width: 45%;
    }

    .missingDocModInn {
        /* width: 260px; */
        padding: 15px 10px;
        border-radius: 10px;
    }

    .missingDocClose {
        right: 6px;
        top: 5px;
    }
}

@media (max-width: 350px) {
    /* .missingDocModInn {
        width: 230px;
    } */
}