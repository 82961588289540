.takEvntText {
    color: rgb(13, 40, 58);
    font-size: 25px;
    font-weight: 500;
}

.takEvntDiv {
    background-color: rgb(215, 228, 236);
    padding: 5px 10px;
}

.taskFigure {
    width: 65%;
    height: 115px;
    margin: 0 auto 10px auto;
}

.taskFigure img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.taskInps {
    width: 100%;
    padding: 4px 8px !important;
    margin: 0;
    display: inline-block;
    border: 1px solid rgb(153, 153, 153);
    border-radius: 4px;
    font-size: 18px !important;
    font-weight: 500;
    text-align: center;
}

.taskInpsDiv {
    margin-bottom: 18px;
}

.taskCnctBtn {
    font-size: 13px;
    color: #fff;
    text-transform: uppercase;
    background-color: rgb(23, 65, 88);
    border-radius: 7px;
    padding: 4px 20px;
    border: 0;
    font-weight: 600;
}

.taskCnctBtnDiv {
    text-align: center;
    margin-top: 18px;
}

.allTaskIconsFig {
    width: 34px;
    height: 34px;
    margin: 0;
}

.allTaskIconsFig img {
    width: 100%;
    height: 100%;
}

.allTaskIconsTextDiv {
    background-color: rgb(23, 65, 88);
    padding: 8px 0;
    width: 65%;
    margin: 15px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 #00000070;
    cursor: pointer;
}

.taskContText {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    margin: 0;
    padding-left: 8px;
}

.allTaskMainDiv {
    padding: 20px 15px 50px 15px;
    border: 1px solid rgb(102, 102, 102);
    height: 393px;
}

.conntCalenderSec {
    margin-bottom: 30px;
}

/* Events calender section */
.myTaskEvPrTabDiv {
    padding: 7px;
    background-color: rgb(238, 238, 238);
}

.myTaskEvPrTabDiv .react-tabs__tab-list {
    display: flex;
    justify-content: space-between;
    border: 0;
}

.myTaskEvPrTabDiv .react-tabs__tab {
    padding: 3px 20px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 6px;
    border: 1px solid rgb(86 81 81);
    background-color: #fff;
}

.myTaskEvPrTabDiv .react-tabs__tab--selected {
    color: #fff;
    border-radius: 5px;
}

.myTaskEvPrTabDiv .react-tabs__tab:focus:after {
    background-color: transparent;
}

.myTaskEvPrTabDiv .react-tabs__tab-list .react-tabs__tab--selected:first-child {
    background: #729FCF;
    border: 1px solid #729FCF;
}

.myTaskEvPrTabDiv .react-tabs__tab-list .react-tabs__tab--selected:nth-child(2) {
    background: #F6B4CA;
    border: 1px solid #F6B4CA;
}

.myTaskEvPrTabDiv .react-tabs__tab-list .react-tabs__tab--selected:last-child {
    background: #A1467E;
    border: 1px solid #A1467E;
}

.taskTabInps {
    width: 49%;
    position: relative;
}

.taskTabInps .taskSearInp {
    width: 100%;
    padding: 5px 8px 5px 30px;
    border: 1px solid rgb(102, 102, 102) !important;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin: 0;
    color: rgb(13, 40, 58);
    background-color: #fff !important;
}

.taskSearGlass {
    position: absolute;
    top: 10px;
    left: 6px;
    font-size: 17px;
}

.taskTabInps .taskDateTimeInp {
    width: 100%;
    padding: 4px 8px;
    border: 1px solid rgb(102, 102, 102);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.taskMainInpDiv {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.addNewTaskBtnDiv {
    width: 100%;
    margin-bottom: 8px;
}

.addNewTaskBtnDiv button {
    width: 100%;
    background-color: rgb(255, 147, 0);
    border: 0;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    padding: 3px 0;
    border-radius: 2px;
}

.postTaskBtnDiv {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.postTaskBtnDiv button {
    background-color: rgb(13, 40, 58);
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 3px 20px;
    text-transform: uppercase;
    border-radius: 5px;
}

.evntsCalSection {
    margin-bottom: 25px;
}

.orDrText {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    padding-right: 8px;
}

.fisiDrIconFig {
    width: 32px;
    height: 30px;
    margin: 0;
}

.fisiDrIconFig img {
    width: 100%;
    height: 100%;
}

.orDrFigDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5px;
}

.ouickViewHead {
    color: rgb(13, 40, 58);
    text-align: center;
    font-weight: 500;
    font-size: 17px;
    margin: 0 0 10px 0;
}

.quickViewUlDiv {
    border: 1px solid rgb(128, 128, 128);
    border-radius: 10px;
    padding: 5px 0;
    background-color: #fff;
}

.quickAnsText {
    font-size: 15px;
    font-weight: 700;
    color: rgb(13, 40, 58);
    margin: 0 0 5px 0;
}

.quickViewUl {
    padding: 0;
    margin: 0;
    max-height: 345px;
    overflow-y: auto;
}

.quickViewUl::-webkit-scrollbar {
    width: 3px;
    display: none;
}

.quickDoneText {
    color: rgb(102, 102, 102);
    font-size: 16px;
    margin: 0;
    font-weight: 500;
    display: inline-block;
    padding-left: 18px;
}

.quickGreenDoneDot::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: rgb(61, 241, 66);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}

.quickRedDoneDot::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: rgb(255, 0, 0);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}

.quickSkyDoneDot::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: rgb(46, 195, 247);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}

.quickYellowDoneDot::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: rgb(247, 200, 31);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}

.quickDoneTextDiv {
    position: relative;
}

.quickFirstIconFig {
    width: 30px;
    height: 28px;
    margin: 0;
}

.quickFirstIconFig img {
    width: 100%;
    height: 100%;
}

.quickViewLi {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(102, 102, 102);
    padding: 0 15px 5px 15px;
    margin-bottom: 5px;
    cursor: "pointer";
}

.quickViewUl .quickViewLi:last-child {
    border: 0 !important;
    margin: 0 !important;
}

.taskUpperInp {
    width: 100% !important;
    padding: 4px 8px !important;
    margin: 0 !important;
    border: 1px solid rgb(128, 128, 128) !important;
    border-radius: 4px;
    font-size: 15px !important;
    font-weight: 500;
    text-align: center;
    color: rgb(13, 40, 58);
    background-color: #fff;
}

.slctUser {
    background-color: rgb(13, 40, 58);
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    padding: 3px 16px;
    border-radius: 5px;
    border: 0;
}

.selectProBtnDiv {
    display: flex;
    /* justify-content: flex-end; */
    justify-content: space-between;
    margin-bottom: 15px;
}

.taskEvForm {
    width: 100% !important;
}

/* Colorful task, event, project buttons */
.clrTaskBtn {
    background-color: #729FCF;
}

.clrEventsBtn {
    background-color: #F6B4CA;
}

.clrProjectBtn {
    background-color: #A1467E;
}

.clrDefaultBtn {
    background-color: rgb(13, 40, 58);
}

/* ***** */
.addTaskTestDiv {
    border: 1px solid rgb(102, 102, 102);
    height: 74.5vh;
    background-color: rgb(238, 238, 238);
    display: flex;
    /* justify-content: space-between;
    justify-content: space-between; */
    padding: 5px;
    overflow-x: auto;
}

.addTaskTestDiv::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 3px;
}

.addTaskTestDiv::-webkit-scrollbar-thumb {
    background: rgb(13, 40, 58);
}

.addinnerTskDiv {
    min-width: 19.5%;
    width: 144px;
    height: 100%;
    margin-right: 2px;
}

.addInHeadDiv {
    padding: 6px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.addInClrHeadDiv {
    padding: 6px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(222, 230, 239);
    border: 1px solid rgb(102, 102, 102);
}

.addInHeadSpan {
    font-size: 19px;
    font-weight: 500;
    color: #fff;
}

.addInClrHeadSpan {
    font-size: 19px;
    font-weight: 500;
    color: rgb(13, 40, 58);
}

.addInNumSpan {
    font-size: 19px;
    font-weight: 500;
    color: #fff;
}

.addInClrNumSpan {
    font-size: 16px;
    font-weight: 500;
    color: rgb(13, 40, 58);
}

.addInTaskBtn {
    width: 100%;
    background-color: rgb(222, 230, 239);
    border: 1px solid rgb(222, 230, 239);
    color: rgb(13, 40, 58);
    font-size: 14px;
    font-weight: 500;
    padding: 5px 0;
}

.addMinTaskDiv {
    background-color: #fff;
    max-height: 86%;
    overflow-y: auto;
}

.addMinTaskDiv::-webkit-scrollbar {
    display: none;
}

.addMinPrepText {
    font-size: 13px;
    color: rgb(13, 40, 58);
    font-weight: 500;
    margin: 0 0 7px 0;
    line-height: normal;
}

.addInTextsDiv {
    padding: 5px 5px 8px 5px;
    border-bottom: 1px solid rgb(204, 204, 204);
    margin-bottom: 5px;
    cursor: pointer;
}

.addAssignText {
    font-size: 11px;
    color: rgb(102, 102, 102);
    font-weight: 500;
    margin: 0;
}

.smallFisiIconFig {
    width: 23px;
    height: 21px;
    margin: 0;
}

.smallFisiIconFig img {
    width: 100%;
    height: 100%;
}

.addAssiTextDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.addMinTaskDiv .addInTextsDiv:last-child {
    border: 0;
    margin: 0;
}

.addAssiCircleDiv {
    width: 9px;
    height: 9px;
    border-radius: 50%;
}

/* Task pop-up section */
.taskPopSection {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #00000079;
    display: flex;
    justify-content: center;
    align-items: center;
    /* filter: blur(10px); */
}

.taskInPopDiv {
    max-width: 500px;
    width: 100%;
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    position: relative;
}

.taskInScrollDiv {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 12px;
}

.taskInScrollDiv::-webkit-scrollbar {
    display: none;
}

.taskModHead {
    font-size: 30px;
    color: rgb(13, 40, 58);
    font-weight: 500;
    margin: 0 0 8px 0;
}

.dryClnDropText {
    font-size: 25px;
    color: rgb(102, 102, 102);
    margin: 0 0 5px 0;
}

.taskDtDetail {
    font-size: 17px;
    color: rgb(102, 102, 102);
    margin: 0 0 1px 0;
}

.taskTimeDetail {
    font-size: 17px;
    color: rgb(102, 102, 102);
    margin: 0 0 15px 0;
}

.eventTimeDetail {
    font-size: 17px;
    color: rgb(102, 102, 102);
    margin: 0;
}

.taskNoteSmallHead {
    font-size: 17px;
    color: rgb(102, 102, 102);
    margin: 0;
}

.taskNoteText {
    font-size: 17px;
    color: rgb(102, 102, 102);
    margin: 0;
}

.taskNoteTextDiv {
    margin-bottom: 14px;
}

.one1DryLink {
    font-size: 18px;
    color: rgb(42, 96, 153);
    text-decoration: underline;
}

.one1DryLink:hover {
    color: rgb(42, 96, 153);
}

.tskSaveAsBtn {
    background-color: rgb(61, 241, 66);
    font-size: 17px;
    font-weight: 500;
    color: #fff;
    border: 0;
    padding: 2px 20px;
    border-radius: 7px;
}

.tskSaveAsBtnDiv {
    text-align: center;
}

.one1DryLinkDiv {
    margin-bottom: 10px;
}

.closeTaskModBtn {
    position: absolute;
    top: 73px;
    right: 60px;
    background-color: transparent;
    border: 0;
    color: rgb(102, 102, 102);
    font-size: 18px;
}

.pyramidDiv {
    width: 11px;
    height: 11px;
    background-color: red;
    -webkit-clip-path: polygon(50% 0, 100% 100%, 0 100%);
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
}

.custEvntName {
    font-size: 17px;
    color: rgb(102, 102, 102);
    margin: 0;
}

.custEvntName span {
    font-weight: 600;
}

.pyramidTextDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dwmBtns {
    background-color: #fff;
    color: #504d4d;
    border: 1px solid #504d4d;
    padding: 2px 12px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 500;
    margin-right: 5px;
}

.dwmBtns:last-child {
    margin-right: 0;
}

.dwmBtnsActive {
    background-color: lightgray;
}

.taskUpperInpSearch {
    width: 100% !important;
    /* padding: 4px 8px !important; */
    margin: 0 !important;
    /* border: 1px solid rgb(128, 128, 128) !important; */
    border-radius: 4px;
    font-size: 15px !important;
    font-weight: 500;
    text-align: center;
    color: rgb(13, 40, 58);
    background-color: #fff;
}

.selectUserCelDrop {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 0 3px 0;
    width: 231px;
    margin-top: 2px;
    z-index: 10;
}

.selectUserCelDrop p {
    cursor: pointer;
    padding: 3px 10px;
    margin: 0;
    font-size: 18px;
}

.selectUserCelDrop p:hover {
    background-color: #bedcf3;
}

@media (max-width: 1399px) {
    .allTaskIconsTextDiv {
        width: 80%;
    }
}

@media (max-width: 1199px) {
    .taskContText {
        font-size: 18px;
    }

    .allTaskIconsTextDiv {
        width: 90%;
    }

    .taskFigure {
        width: 80%;
        height: 70px;
    }

    .taskBigCalDiv {
        overflow-x: auto;
    }

    .taskBigCalDiv .rbc-calendar {
        width: 768px;
    }

    .addinnerTskDiv {
        min-width: 24.5%;
        width: 24.5%;
    }
}

@media (max-width: 991px) {
    .taskContText {
        font-size: 12px;
    }

    .allTaskIconsFig {
        width: 23px;
        height: 23px;
    }

    .taskFigure {
        width: 90%;
        height: 50px;
    }

    .taskInps {
        font-size: 16px !important;
    }

    .taskCnctBtn {
        font-size: 12px;
    }

    .taskBigCalDiv::-webkit-scrollbar {
        display: none;
    }

    .taskBigCalDiv .rbc-calendar {
        width: 100%;
    }

    .addTaskTestDiv::-webkit-scrollbar {
        display: none;
    }
}

@media (max-width: 767px) {
    .allTaskMainDiv {
        padding: 20px 15px 35px 15px;
        height: auto;
        margin-bottom: 25px;
    }

    .taskFigure {
        width: 60%;
        height: 100px;
    }

    .taskInps {
        font-size: 18px !important;
    }

    .allTaskIconsTextDiv {
        width: 65%;
    }

    .allTaskIconsFig {
        width: 30px;
        height: 30px;
    }

    .taskContText {
        font-size: 18px;
    }

    .taskBigCalDiv .rbc-calendar {
        width: 768px;
    }

    .addinnerTskDiv {
        min-width: 32.5%;
        width: 32.5%;
    }
}

@media (max-width: 575px) {
    .taskInPopDiv {
        margin: 0 20px;
    }
}

@media (max-width: 479px) {
    .allTaskIconsTextDiv {
        width: 90%;
    }

    .takEvntText {
        font-size: 19px;
    }

    .addinnerTskDiv {
        min-width: 49.5%;
        width: 49.5%;
    }

    .taskInPopDiv {
        margin: 0 10px;
    }

    .taskModHead {
        font-size: 25px;
        margin: 0 0 3px 0;
    }

    .dryClnDropText {
        font-size: 19px;
    }

    .taskDtDetail {
        font-size: 15px;
        margin: 0;
    }

    .taskTimeDetail {
        font-size: 15px;
        margin: 0 0 12px 0;
    }

    .taskNoteSmallHead {
        font-size: 15px;
    }

    .taskNoteText {
        font-size: 15px;
    }

    .one1DryLink {
        font-size: 16px;
    }

    .taskNoteTextDiv {
        margin-bottom: 12px;
    }

    .closeTaskModBtn {
        top: 23px;
        right: 20px;
        font-size: 15px;
    }

    .one1DryLink {
        font-size: 15px;
    }

    .custEvntName {
        font-size: 15px;
    }

    .pyramidDiv {
        width: 9px;
        height: 9px;
    }
}