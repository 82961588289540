.iframedivimg{
    width: 100%;
    height: auto;
    padding: 50px 0 80px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    border: 1px solid #081722;
}
.producttourbtn {
    width: 100%;
    max-width: 200px;
    height: 39px;
    padding: 5px 10px;
    /* margin: 0 20px 0 0; */
    text-decoration: none;
    box-shadow: none;
    cursor: pointer;
    outline: 0;
    background-color: transparent;
    border: 2px solid #3c3c3b;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    color: #3c3c3b;
    transition: 0.8s;
    cursor: pointer;
}

.productourmain .banner-top-heading h4{
    color: #868686;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 5px;
    margin: 0;
    padding: 0;
    text-align: center;
}
.productourmain .banner-second-heading h2 {
    color: #081722;
    font-size: 40px;
    font-weight: 400;
    margin: 0;
    padding-top: 30px;
    text-align: center;
}
.banner-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    column-gap: 20px;
}
.startbtnproduct{
    color: #081722;
    border: 1px solid #081722;
    padding: 5px 10px;
    background-color: #afd095;
    border-radius: 20px;
    font-size: 21px;
    font-weight: 400;
}
.startbtnproduct i{
    margin-left: 10px;
}
.startbtnproductmrlt{
    position: absolute;
    top: 0;
    right: 14px;
}
.userproducttour{
    display: flex;
    justify-content: center;
    align-items: center;
}
.userproducttourtxtpp{
    font-size: 24px;
    font-weight: 600;
    color: #081722;
    margin-bottom: 10px;
}
.userproducttourtxtpp i{
    margin-right: 10px;
}
.mb-resp{
    margin-bottom: 40px;
}
@media(max-width:1199px){
    .producttourbtn {
        width: 100%;
        max-width: 200px;
        height: 33px;
        padding: 5px 10px;
        /* margin: 0 20px 0 0; */
        text-decoration: none;
        box-shadow: none;
        cursor: pointer;
        outline: 0;
        background-color: transparent;
        border: 2px solid #3c3c3b;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #3c3c3b;
        transition: 0.8s;
        cursor: pointer;
    }
    .banner-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 60px;
        column-gap: 14px;
        /* flex-wrap: wrap; */
    }
    .productourmain .banner-second-heading h2 {
        color: #081722;
        font-size: 30px;
        font-weight: 400;
        margin: 0;
        padding-top: 30px;
        text-align: center;
    }
    .startbtnproduct {
        color: #081722;
        border: 1px solid #081722;
        padding: 5px 10px;
        background-color: #afd095;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 400;
    }
    .productourmain .banner-top-heading h4 {
        color: #868686;
        font-size: 17px;
        font-weight: 400;
        letter-spacing: 5px;
        margin: 0;
        padding: 0;
        text-align: center;
    }
}

@media(max-width:576px){
    .mb-resp{
        margin-bottom: 20px;
    }
}
@media(max-width:480px){
    .startbtnproductmrlt {
        position: absolute;
        top: -33px;
        right: 14px;

    }
    .productourmain .banner-second-heading h2 {
        color: #081722;
        font-size: 26px;
        font-weight: 400;
        margin: 0;
        padding-top: 30px;
        text-align: center;
    }
    .banner-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        column-gap: 14px;
        flex-wrap: wrap;
        row-gap: 20px;
    }
    .producttourbtn {
        width: 100%;
        max-width: 179px;
        height: 33px;
        padding: 5px 10px;
       
    }
    .iframedivimg {
        width: 100%;
        height: auto;
        padding: 50px 0 50px;
    }
}
@media(max-width:420px){
    .producttourbtn {
        width: 100%;
        max-width: 145px;
        height: 33px;
        padding: 5px 10px;
    }
}