* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

li {
  margin: 0;
  padding: 0;
}

input:focus::placeholder {
  color: transparent;
}

body {
  overflow-x: hidden;
  max-width: 100%;
  /* background: #E5E5E5; */
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  -webkit-text-size-adjust: 100%;
}

html {
  overflow-x: hidden;
  max-width: 100%;
}

a {
  text-decoration: none;
  font-weight: inherit;
}

.container-fluid {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}


/*******Banner area*****/

#banner_area {
  width: 100%;
  height: auto;
  padding: 100px 0 250px;
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}

#banner_area .left_cnt {
  width: 100%;
  height: auto;
}

#banner_area .left_cnt .head {
  margin-bottom: 20px;
}

#banner_area .left_cnt .head h2 {
  font-size: 72px;
  font-weight: 600;
  line-height: normal;
  color: #0d2537;
  padding: 0;
  margin: 0 0 0px 0;
}

#banner_area .left_cnt .text p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #3c3c3b;
  padding: 0;
  margin-bottom: 30px;
  max-width: 430px;
}

#banner_area .left_cnt .bottom {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}

#banner_area .left_cnt .bottom .registrarse_btn {
  margin-right: 20px;
}

#banner_area .left_cnt .bottom .registrarse_btn .btn {
  width: 100%;
  max-width: 200px;
  height: 50px;
  padding: 5px 20px;
  margin: 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background-color: transparent;
  border: 2px solid #3c3c3b;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 500;
  color: #3c3c3b;
  transition: 0.8s;
}

#banner_area .left_cnt .bottom .registrarse_btn .btn:hover {
  background-color: #102a3d;
  color: #fff;
  border: 2px solid #102a3d;
}

#banner_area .left_cnt .bottom .descargar_btn .btn {
  width: 100%;
  max-width: 200px;
  height: 50px;
  padding: 5px 20px;
  margin: 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  background-color: #102a3d;
  border: 2px solid #102a3d;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 500;
  color: #fff;
  transition: 0.8s;
}

#banner_area .left_cnt .bottom .descargar_btn .btn:hover {
  background-color: transparent;
  color: #102a3d;
  border: 2px solid #102a3d;
}

#banner_area .right_cnt {
  width: 100%;
  height: 300px;
}




/******banner_bottom****/
#banner_bottom {
  width: 100%;
  height: 475px;
  background-color: transparent;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -258px;
  margin-bottom: 50px;
  position: relative;
}

#banner_bottom .main_cnt {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 200px 0 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#banner_bottom .main_cnt .box_card {
  width: 20%;
  height: auto;
  padding: 10px;
  background-color: transparent;
}

#banner_bottom .main_cnt .box_card .icon_area {
  width: 100%;
  height: 175px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  padding: 0 15px;
}


#banner_bottom .main_cnt .box_card .icon_area img {
  width: 65%;
  height: 65%;
}

#banner_bottom .main_cnt .box_card .description p {
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  color: #fff;
  text-align: center;
  padding: 0;
  margin: 0;
}

/***persons_area****/

#persons_area {
  width: 100%;
  height: auto;
  padding: 40px 0 75px;
  background-color: transparent;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#persons_area .left_area {
  width: 75%;
  height: 768px;
  margin: -80px auto 0;
}

#persons_area .left_area img {
  width: 100%;
  height: 110%;
}

#persons_area .right_area .head h2 {
  font-size: 38px;
  font-weight: 500;
  line-height: normal;
  color: #fff;
  padding: 0;
  margin-bottom: 15px;
  display: block;
}


#persons_area .right_area .head2 {
  margin-top: 83px;
  display: none;
}


#persons_area .right_area .head2 h2 {
  font-size: 30px;
  font-weight: 500;
  line-height: normal;
  color: #fff;
  padding: 0;
  margin-bottom: 15px;
  margin-top: 40px;
}

#persons_area .all_list ul li {
  font-size: 20px;
  line-height: normal;
  font-weight: 500;
  color: #33aaa4;
  padding: 0;
  margin: 0 0 10px 0px;
  display: block;
}

#persons_area .all_list ul li:before {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 28px;
  vertical-align: middle;
  margin-right: 10px;
}




#persons_bottom {
  width: 100%;
  height: auto;
  background-color: transparent;
  padding: 0 0 60px;
}

#persons_bottom .top .all_list {
  width: 100%;
  height: auto;
  padding: 40px 0;
}

#persons_bottom .top .all_list ul li {
  font-size: 20px;
  line-height: normal;
  font-weight: 500;
  color: #0d2537;
  padding: 0;
  margin: 0 0 10px 0px;
  display: none;
}

#persons_bottom .top .all_list ul li:before {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 28px;
  vertical-align: middle;
  margin-right: 10px;
  color: #0d2537;
}

#persons_bottom .top .img_area {
  width: 80%;
  height: 400px;
  margin-left: auto;
  margin-top: -120px;
}

#persons_bottom .top .img_area img {
  width: 100%;
  height: 100%;
}


#persons_bottom .description {
  width: 100%;
  max-width: 500px;
}


#persons_bottom .description .head h2 {
  font-size: 52px;
  font-weight: 500;
  line-height: normal;
  color: #0d2537;
  padding: 0;
  margin: 0;
}

#persons_bottom .description .text p {
  font-size: 32px;
  line-height: normal;
  font-weight: 500;
  color: #3b3b3a;
  padding: 0;
  margin-bottom: 40px;
}

#persons_bottom .description .googleapp_area {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}

#persons_bottom .description .googleapp_area .google_img {
  width: 100%;
  max-width: 170px;
  height: 80px;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 12px;
}

#persons_bottom .description .googleapp_area .google_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#persons_bottom .description .googleapp_area .app_store {
  width: 100%;
  max-width: 170px;
  height: 80px;
  overflow: hidden;
  border-radius: 12px;
}

#persons_bottom .description .googleapp_area .app_store img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/******recommend_area****/

#recommend_area {
  width: 100%;
  height: auto;
}

#recommend_area .top {
  width: 100%;
  height: auto;
  padding: 30px 0;
  background-color: #0d2537;
}

#recommend_area .top h2 {
  font-size: 46px;
  line-height: normal;
  font-weight: 600;
  color: #fff;
  padding: 0;
  margin: 0;
}

#recommend_area .bottom_area {
  width: 100%;
  height: auto;
  background-color: transparent;
}

#recommend_area .bottom_area .phone_img {
  width: 100%;
  height: 550px;
  margin: -75px auto 0;
  display: flex;
  justify-content: center;
}

#recommend_area .bottom_area .phone_img img {
  width: 100%;
  height: 100%;
}

#recommend_area .bottom_area .phone_img_en img {
  width: 100%;
  height: 100%;
}


#recommend_area .bottom_area .potencial {
  width: 100%;
  height: auto;
  padding: 40px 0;
}

#recommend_area .bottom_area .potencial .all_list ul li {
  font-size: 26px;
  line-height: normal;
  font-weight: 500;
  color: #0d2537;
  padding: 0;
  margin: 0 0 5px 0px;
  display: block;
}

#recommend_area .bottom_area .potencial .all_list ul li:before {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 28px;
  vertical-align: middle;
  margin-right: 10px;
}

#recommend_area .bottom_area .potencial .text p {
  font-size: 26px;
  line-height: normal;
  font-weight: 500;
  color: #0d2537;
  padding: 0;
  margin: 60px 0 40px 200px;
  max-width: 400px;
}

#recommend_area .bottom_area .text_responsive p {
  font-size: 24px;
  line-height: normal;
  font-weight: 600;
  color: #0d2537;
  padding: 0;
  margin: 60px 0 40px 200px;
  max-width: 400px;
  display: none;
}

#recommend_area .bottom_area .potencial .all_icon {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto 50px;
}

#recommend_area .bottom_area .potencial .all_icon .icon_area {
  width: 100%;
  max-width: 120px;
  height: 140px;
  overflow: hidden;
}

#recommend_area .bottom_area .potencial .all_icon .icon_area1 {
  width: 100%;
  max-width: 175px;
  height: 171px;
  overflow: hidden;
}

#recommend_area .bottom_area .potencial .all_icon .icon_area img {
  width: 100%;
  height: 100%;
}




#recommend_area .bottom_area .all_icon_responsive {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto 50px;
  display: none;
}

#recommend_area .bottom_area .all_icon_responsive .icon_area {
  width: 100%;
  max-width: 100px;
  height: 120px;
  overflow: hidden;
}

#recommend_area .bottom_area .all_icon_responsive .icon_area img {
  width: 100%;
  height: 100%;
}



#recommend_area .bottom_area .dinero_area {
  width: 100%;
  max-width: 600px;
  height: 350px;
  border: 15px solid #0d2537;
  margin: 0 auto 60px;
  position: relative;
  overflow: hidden;
}

#recommend_area .bottom_area .dinero_area video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}











/******Responsive*****/

@media (max-width: 1460px) {
  #persons_area .left_area img {
    width: 100%;
    height: 110%;
  }
}


@media (max-width: 991px) {
  #recommend_area .top h2 {
    font-size: 36px;
  }

  #persons_area {
    padding: 42px 0 200px;
  }

  #recommend_area .bottom_area .phone_img {
    width: 100%;
    margin: -100px auto 0;
  }
}

@media (max-width: 767px) {
  #banner_area {
    width: 100%;
    height: auto;
    padding: 40px 0 100px;
    background-color: #f3f5f7;
    background-position: bottom right;
    background-size: 100% 50% !important;
    background-repeat: no-repeat;
  }

  #banner_area .right_cnt {
    width: 100%;
    height: 340px;
  }

  #persons_area {
    padding: 42px 0 67px;
  }

  #banner_bottom .main_cnt {
    padding: 100px 0 60px;
  }

  #banner_area .left_cnt .text p {
    max-width: 100%;
  }

  #banner_bottom {
    width: 100%;
    height: auto;
    margin-top: -100px;
  }

  #banner_bottom .main_cnt .box_card {
    width: 25%;
    padding: 5px;
  }

  #banner_bottom .main_cnt .box_card .icon_area {
    height: 80px;
  }

  #banner_bottom .main_cnt .box_card .description p {
    font-size: 12px;
  }

  #persons_area .left_area {
    width: 100%;
    height: 400px;
  }

  #persons_area .right_area .head h2 {
    display: none;
  }

  #persons_area .right_area .head2 {
    display: block;
    margin-top: -50px;
  }

  #persons_area .all_list ul li {
    display: none;
  }

  #persons_bottom .top .all_list ul li {
    display: block;
  }

  #persons_bottom .top .img_area {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 50px;
  }

  #recommend_area .top h2 {
    font-size: 26px;
  }

  #recommend_area .bottom_area .phone_img {
    width: 100%;
    margin: -100px auto 0;
    height: 270px;
  }

  #recommend_area .bottom_area .potencial .all_list ul li {
    font-size: 16px;
  }

  #recommend_area .bottom_area .potencial .all_list ul li:before {
    font-size: 20px;
  }

  #recommend_area .bottom_area .potencial {
    padding: 20px 0;
  }

  #recommend_area .bottom_area .potencial .text {
    display: none;
  }

  #recommend_area .bottom_area .potencial .all_icon {
    display: none;
  }

  #recommend_area .bottom_area .text_responsive p {
    display: block;
    margin: 30px auto;
  }

  #recommend_area .bottom_area .all_icon_responsive {
    display: flex;
  }

  #recommend_area .bottom_area .dinero_area {
    width: 100%;
    height: 300px;
  }

  #recommend_area .bottom_area .phone_img img {
    width: 100% !important;
    height: 100% !important;
  }
}



@media (max-width: 520px) {
  #banner_bottom .main_cnt .box_card {
    width: 30%;
  }

  #persons_area .right_area .head2 h2 {
    font-size: 22px;
    margin-top: 0;
  }

  #persons_area .left_area {
    width: 100%;
    height: 306px;
  }

  #persons_area .right_area .head2 {
    margin-top: 0px;
  }

  #persons_bottom .top .img_area {
    width: 100%;
    height: 220px;
  }

  #persons_bottom .description .head h2 {
    font-size: 38px;
  }

  #persons_bottom .description .text p {
    font-size: 22px;
  }

  #recommend_area .top h2 {
    font-size: 20px;
  }

  #recommend_area .bottom_area .phone_img {
    height: 220px;
  }

  #recommend_area .bottom_area .dinero_area {
    width: 100%;
    height: 245px;
  }
}

@media (max-width: 480px) {
  #persons_area {
    padding: 42px 0 35px;
  }

  #banner_area .left_cnt .head h2 {
    font-size: 48px;
  }

  #banner_bottom .main_cnt .box_card .icon_area {
    height: 80px;
  }

  #banner_bottom .main_cnt .box_card .description p {
    font-size: 10px;
  }

  #banner_bottom .main_cnt {
    padding: 90px 0 30px;
  }

  #banner_area .right_cnt {
    height: 310px;
  }
  #banner_bottom {
    margin-top: -175px;
  }
}

@media (max-width: 420px) {
  #persons_bottom .top .all_list {
    padding: 20px 0;
  }

  #banner_area {
    padding: 60px 0 60px;
    margin-bottom: 85px;
  }

  #banner_area .left_cnt .head h2 {
    font-size: 42px;
  }

  #banner_area .left_cnt .bottom .registrarse_btn .btn {
    max-width: 150px;
    height: 40px;
    font-size: 14px;
  }

  #banner_area .left_cnt .bottom .descargar_btn .btn {
    max-width: 150px;
    height: 40px;
    font-size: 14px;
  }

  #persons_area .right_area .head2 h2 {
    font-size: 18px;
  }

  #persons_area .left_area {
    width: 100%;
    height: 281px;
  }

  #persons_bottom .top .all_list ul li {
    font-size: 16px;
  }

  #persons_bottom .top .all_list ul li:before {
    font-size: 20px;
  }

  #persons_bottom .top .img_area {
    width: 100%;
    height: 180px;
  }

  #persons_bottom .description .head h2 {
    font-size: 30px;
  }

  #persons_bottom .description .text p {
    font-size: 18px;
    margin-bottom: 25px;
  }

  #persons_bottom .description .googleapp_area .google_img {
    width: 100%;
    height: 60px;
  }

  #persons_bottom .description .googleapp_area .app_store {
    width: 100%;
    height: 42px;
  }

  #recommend_area .top h2 {
    font-size: 16px;
  }

  #recommend_area .bottom_area .phone_img {
    margin: -50px auto 0;
  }

  #recommend_area .bottom_area .phone_img {
    height: 145px;
  }

  #recommend_area .bottom_area .text_responsive p {
    font-size: 18px;
  }

  #recommend_area .bottom_area .all_icon_responsive .icon_area {
    width: 100%;
    max-width: 75px;
    height: 90px;
  }

  #recommend_area .bottom_area .dinero_area {
    width: 100%;
    height: 210px;
  }
  #banner_bottom .main_cnt .box_card .icon_area {
    height: 50px;
  }
}