.int-modal-heat {
    position: fixed;
    background: rgba(0, 0, 0, .5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 2%;
}

.int-modal-body {
    width: 900px;
    background: #fff;
    height: 600px;
    padding: 30px;
    overflow-y: auto;
    position: relative;
}

.intclose {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 30px;
    color: rgb(14, 37, 55);
}

.intclose:hover {
    color: rgb(14, 37, 55);
}

@media (max-width:991px) {
    .int-modal-body {
        width: 100%;

    }

    .int-modal-heat {
        justify-content: inherit;
    }
}